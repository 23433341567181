import { KTIcon } from "../../_metronic/helpers";

function OverlayModal({
  show,
  onClose,
  children,
  heading = 'Upload Document',
  width = '90%', // Default width
  height = '90%', // Default height
  fileLoc = '0',
}) {
  if (!show) return null;

  const newHeading = heading === 'Upload Document' && fileLoc !== '0' ? `Upload Document #${fileLoc}` : heading;

  return (
    <div
      className="overlay-modal"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1050,
      }}
    >
      <div
        className="modal-content"
        style={{
          position: 'relative',
          backgroundColor: '#fff',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          maxWidth: '90%', // Ensure responsiveness
          width, // Dynamically set width
          height, // Dynamically set height
          overflow: 'auto', // Allow scrolling for long content
        }}
      >
        {/* Header and Close Button */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%', // Make sure they take up the full width
          }}
        >
          <h2 className="fw-bolder">{newHeading}</h2>
          <div
            className="btn btn-icon btn-sm btn-active-icon-primary"
            data-kt-organizations-modal-action="close"
            onClick={onClose}
            style={{ cursor: 'pointer' }}
          >
            <KTIcon iconName="cross" className="fs-1" />
          </div>
        </div>

        {/* Children content goes here */}
        <div style={{ marginTop: '15px', overflow: 'auto' }}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default OverlayModal;
