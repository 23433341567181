import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios'; // Import axios
import { useAuth } from '../../modules/auth';
// import DocumentTable from './Components/DocumentTable';
import DocumentListWrapper from '../../components/DocumentListWrapper';
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import HourGlass from '../Loading/HourGlassSpinner/HourGlass';
import PaginationWrapper from '../../components/PaginationWrapper';
import ItemsPerPageWrapper from '../../components/ItemsPerPageWrapper';
import DocumentHeader from './Components/DocumentHeader';
import DocumentToolbar from './Components/DocumentToolbar';

const Document = ({ docType = '' }) => {

    const [documents, setDocuments] = useState(null);
    const { documentType } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const moment = require('moment-timezone');
    const location = useLocation();
    const stateData = location.state || { agency: '', period_code: 0 };
    const [filteredDocuments, setFilteredDocuments] = useState([]);
    const [filters, setFilters] = useState({
        document: '',
        program: '',
        startDate: stateData.period_code !== 0 ? moment().subtract(stateData.period_code, 'days').format('YYYY-MM-DD') : '',
        endDate: '',
        tracked: documentType ? true : false,
        genAI: false,
        agency: stateData.agency || '',
    });
    const [searchInput, setSearchInput] = useState(location.state ? location.state.searchInput : '');
    const { currentUser, logout } = useAuth();
    const [alert, setAlert] = useState({ message: '', type: '' });
    const [sortConfig, setSortConfig] = useState({ key: 'postedDate', direction: 'desc' });
    const [sortDirection, setSortDirection] = useState('desc');
    const [currentPage, setCurrentPage] = useState(location.state ? location.state.currentPage : 1);
    const [trackedDocuments, setTrackedDocuments] = useState([]);
    const interests = useSelector(state => state.account.interests);
    const limit = 18446744073709551615; // Set your desired limit here
    const navigate = useNavigate();
    // const DOCUMENTS_PER_PAGE = 10
    const [itemsPerPage, setItemsPerPage] = useState(Number(Cookies.get('itemsPerPage')) || 10);
    const [totalPages, setTotalPages] = useState(0);
    const [programs, setPrograms] = useState({});
    const colors = ['#0000CD', '#FF8C00', '#A9A9A9', '#FFD700', '#1E90FF'];
    const [data, setData] = useState([]);
    const [isInsightsVisible, setIsInsightsVisible] = useState(false);
    const isFirstRender = useRef(true);
    const prevSearchInputRef = useRef();
    const api_url = docType === 'final-rules' ? 'finalrules' : docType === 'proposed-rules' ? 'proposedrules' : docType === 'guidances' ? 'guidance' : docType === 'notices' ? 'notice' : 'finalrules';
    const fetchData = useCallback(() => {
        axios.get(`/reg/v1/home/insights/documents`)
            .then(response => {
                const sortedData = response.data;
                setData(sortedData);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    logout();
                    navigate('/auth/login')
                }
                console.error('There was an error!', error);
            });

    }, [logout, navigate]);

    useEffect(() => {
        Promise.all([
            fetchData()
        ]).then(() => { }).catch((error) => {
            console.error(error.message);
        });
    }, [fetchData]);

    // useEffect(() => {
    //     window.scrollTo(0, document.body.scrollHeight);
    // }, [currentPage]);

    const fetchTrackedDocuments = useCallback(async () => {

        try {
            const response = await axios.get(`/reg/v1/document/user/${api_url}/${currentUser?.id}`);
            setTrackedDocuments(response.data);
            //returning to filter for tracked documents
            return response.data;

        } catch (error) {
            console.log('Failed to fetch tracked documents', error);
            //returning to filter for tracked documents
            return [];
        }
    }, [currentUser?.id, api_url]);

    useEffect(() => {
        if (interests) {
            const newPrograms = {};
            interests.programs.forEach(program => {
                const match = program ? program.match(/(.*?)\s*\((.*?)\)/) : undefined;
                if (match && match.length > 2) {
                    const key = match[2].trim();
                    const value = match[1].trim();
                    newPrograms[key] = value;
                }
            });
            setPrograms(newPrograms);
        }
    }, [interests]);




    const formatDate = useCallback((timestamp) => {
        if (timestamp === null) {
            return null;
        }
        const date = moment.utc(timestamp); // Use UTC
        return date.format('YYYY-MM-DD'); // Format in UTC
    }, [moment]);

    const formatDate2 = useCallback((timestamp) => {
        if (isNaN(Date.parse(timestamp))) {
            return null;
        }
        const date = moment.utc(timestamp); // Use UTC
        return date.format('YYYY-MM-DD'); // Format in UTC
    }, [moment]);

    const fetchDocuments = useCallback(async () => {
        setLoading(true);

        try {
            // Check for cached data
            let cachedData = localStorage.getItem(api_url);
            let filteredData;

            if (cachedData) {
                filteredData = JSON.parse(cachedData);
            } else {
                // Make a GET request to fetch documents data if no cache is found
                const response = await axios.get(`/reg/v1/document/all/${api_url}/${limit}/0`);
                filteredData = [...response.data];
                try {
                    localStorage.setItem(api_url, JSON.stringify(filteredData));
                } catch (e) {
                    if (e instanceof DOMException && e.code === DOMException.QUOTA_EXCEEDED_ERR) {
                        console.error("LocalStorage quota exceeded. ");
                        // Optionally, implement fallback storage or notify the user
                    } else {
                        console.log(e); // Re-throw the error if it's not a QuotaExceededError
                    }
                }
            }

            // Filter for tracked documents
            if (filters.tracked && docType !== "notices") {
                const trackedDocuments = await fetchTrackedDocuments();
                const trackedDocumentIds = trackedDocuments
                    .filter(doc => doc.track === 1)
                    .map(doc => doc.id);
                filteredData = filteredData.filter(document => trackedDocumentIds.includes(document.id));
            }

            const newPrograms = interests.programs.map(program => {
                const match = program ? program.match(/\((.*?)\)/) : undefined;
                return match && match.length > 1 ? match[1] : program;
            });

            const isCMSAgency = interests.agencies.includes('CMS');
            const isFDAAgency = interests.agencies.includes('FDA');

            if (interests.agencies.length > 0 || newPrograms.length > 0) {
                filteredData = filteredData.filter(document => {
                    // For FDA, apply both agency and program filters
                    if (isFDAAgency && document.agencyId === 'FDA') {
                        return newPrograms.length === 0 || newPrograms.includes(document.program);
                    }
                    // For CMS, do not filter by program
                    if (isCMSAgency && document.agencyId === 'CMS') {
                        return true;
                    }
                    // Default filtering for other cases
                    return (interests.agencies.length === 0 || interests.agencies.includes(document.agencyId)) &&
                        (newPrograms.length === 0 || newPrograms.includes(document.program));
                });
            }

            if (filters.agency && filters.agency !== '') {
                filteredData = filteredData.filter((document) =>
                    document.agencyId.toLowerCase().includes(filters.agency.toLowerCase())
                );
            }

            if (filters.startDate && filters.startDate !== '') {
                const startTimestamp = new Date(filters.startDate).getTime(); // Default to 0 if filterStartDate is empty or null

                filteredData = filteredData.filter((document) => {
                    const modifyDateTimestamp = new Date(document.commentStartDate).getTime();
                    return modifyDateTimestamp >= startTimestamp
                }
                );
            }
            if (filters.genAI && filters.genAI !== '') {
                filteredData = filteredData.filter((document) => document.is_genai_available === 1);
            }
            if (filters.program && filters.program !== '') {
                filteredData = filteredData.filter((document) => document.program?.toLowerCase().includes(filters.program.toLowerCase()));
            }
            // Cache the filtered data



            setDocuments(filteredData);
            setFilteredDocuments(filteredData);
            setError(null);
        } catch (error) {
            setDocuments(null);
            setFilteredDocuments(null);
            setError(error);
        } finally {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchTrackedDocuments, interests, limit]);

    const applyFilters = useCallback(() => {
        if (!loading && documents?.length > 0) {
            let filtered = documents; // Start with the original list

            // Apply filters one by one
            if (filters.document && filters.document !== '') {
                filtered = filtered.filter((document) =>
                    document.document_title.toLowerCase().includes(filters.document.toLowerCase())
                );
            }
            if (filters.program && filters.program !== '') {
                filtered = filtered.filter((document) => document.program?.toLowerCase().includes(filters.program.toLowerCase()));
            }
            if (filters.startDate && filters.startDate !== '') {
                const startTimestamp = new Date(filters.startDate).getTime();
                filtered = filtered.filter((document) => new Date(document.commentStartDate).getTime() >= startTimestamp);
            }
            if (filters.endDate && filters.endDate !== '') {
                const endTimestamp = new Date(filters.endDate).getTime();
                filtered = filtered.filter((document) => new Date(document.commentEndDate).getTime() <= endTimestamp);
            }
            if (filters.tracked && docType !== "notices") {
                const trackedDocumentIds = trackedDocuments.filter(doc => doc.track === 1).map(doc => doc.id);
                filtered = filtered.filter((document) => trackedDocumentIds.includes(document.id));
            }
            if (filters.agency && filters.agency !== '') {
                filtered = filtered.filter((document) => document.agencyId.toLowerCase().includes(filters.agency.toLowerCase()));
            }
            if (filters.genAI && docType !== 'notices') {
                filtered = filtered.filter((document) => document.is_genai_available === 1);
            }

            console.log('Filtered documents:', filtered);

            setFilteredDocuments(filtered);
        }
    }, [filters, documents, loading, trackedDocuments, docType]);


    const handleApplyFilters = useCallback(() => {
        applyFilters();
        //setCurrentPage(1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        prevSearchInputRef.current = searchInput;
    }, [searchInput]);

    useEffect(() => {

        const delayDebounceFn = setTimeout(async () => {
            if ((searchInput || '').trim() !== '') {
                setLoading(true);
                try {
                    let type;
                    if (docType === 'final-rules') {
                        type = 'rule';
                    }
                    else if (docType === 'proposed-rules') {
                        type = 'proposedrule';
                    }
                    else if (docType === 'guidances') {
                        type = 'guidance';
                    }
                    else if (docType === 'notices') {
                        type = 'notice';
                    }
                    const response = await axios.post(`/reg/v1/search/${type}/search_es`,
                        { query: searchInput },
                        {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        }
                    );
                    if (response.data) {
                        setDocuments(response.data);
                        setFilteredDocuments(response.data);
                        // setTotalPages(Math.ceil(response.data[0]?.search_count / Math.max(1, itemsPerPage)));
                        setTotalPages(1);
                    }

                } catch (error) {
                    setDocuments([]);
                    setFilteredDocuments([]);
                    setTotalPages(0);
                    console.error("Error fetching documents:", error);
                } finally {
                    setLoading(false);
                }
            } else {
                setDocuments([]);
                setFilteredDocuments([]);
                fetchDocuments();
                if (docType !== 'notices') {
                    fetchTrackedDocuments();
                }
            }
            handleApplyFilters();
        }, 0);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchDocuments, fetchTrackedDocuments, interests, searchInput, itemsPerPage, handleApplyFilters, currentPage]);

    const handleResetFilters = () => {
        setFilters({
            document: '',
            program: '',
            startDate: stateData.period_code !== 0 ? moment().subtract(stateData.period_code, 'days').format('YYYY-MM-DD') : '',
            endDate: '',
            tracked: documentType ? true : false,
            genAI: false,
            agency: stateData.agency || '',
        });
        setFilteredDocuments(documents);
        setCurrentPage(1);
    };


    useEffect(() => {
        if (!loading && documents?.length > 0) {
            let filtered = documents; // Start with the original list


            // Apply filters one by one
            if (filters.document && filters.document !== '') {
                filtered = filtered.filter((document) =>
                    document.document_title.toLowerCase().includes(filters.document.toLowerCase())
                );
            }
            if (filters.program && filters.program !== '') {
                filtered = filtered.filter((document) => document.program?.toLowerCase().includes(filters.program.toLowerCase()));
            }
            if (filters.startDate && filters.startDate !== '') {
                const startTimestamp = new Date(filters.startDate).getTime(); // Default to 0 if filterStartDate is empty or null

                filtered = filtered.filter((document) => {
                    const modifyDateTimestamp = new Date(document.commentStartDate).getTime();
                    return modifyDateTimestamp >= startTimestamp
                }
                );
            }
            if (filters.endDate && filters.endDate !== '') {
                const endTimestamp = new Date(filters.endDate).getTime(); // Default to max value if filterEndDate is empty or null

                filtered = filtered.filter((document) => {
                    const modifyDateTimestamp = new Date(document.commentEndDate).getTime();
                    return modifyDateTimestamp <= endTimestamp
                });
            }
            if (filters.tracked && docType !== "notices") {
                fetchTrackedDocuments();

                const trackedDocumentIds = trackedDocuments
                    .filter(doc => doc.track === 1)
                    .map(doc => doc.id);

                filtered = (filtered || []).filter((document) => {
                    const isTracked = trackedDocumentIds.includes(document.id);
                    // console.log('Document ID:', document.id, 'Is tracked:', isTracked);
                    return isTracked;
                });

            }

            if (filters.agency && filters.agency !== '') {
                filtered = filtered.filter((document) =>
                    document.agencyId.toLowerCase().includes(filters.agency.toLowerCase())
                );
            }
            if (filters.genAI && filters.genAI !== '') {
                filtered = filtered.filter((document) => document.is_genai_available === 1);
            }
            setFilteredDocuments(filtered);
            //setCurrentPage(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, loading]);

    const onApplySort = () => {
        if (!filteredDocuments || filteredDocuments.length === 0 || loading) {
            return;
        }

        let sortedDocuments = [...filteredDocuments];
        if (sortConfig.direction === 'asc') {
            sortedDocuments.sort((a, b) => a[sortConfig.key] > b[sortConfig.key] ? 1 : -1);
        } else if (sortConfig.direction === 'desc') {
            sortedDocuments.sort((a, b) => a[sortConfig.key] < b[sortConfig.key] ? 1 : -1);
        }
        setFilteredDocuments(sortedDocuments);
    };

    useEffect(() => {
        onApplySort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortConfig]);

    // Define onResetSort function
    const onResetSort = () => {
        if (!filteredDocuments || filteredDocuments.length === 0 || loading) {
            return;
        }
        let sortedDocuments = [...filteredDocuments];
        sortedDocuments.sort((a, b) => a.postedDate < b.postedDate ? 1 : -1);
        setSortConfig({ key: 'postedDate', direction: 'desc' });
        setFilteredDocuments(sortedDocuments);

    };

    useEffect(() => {
        Cookies.set('itemsPerPage', itemsPerPage);
    }, [itemsPerPage]);

    useEffect(() => {
        if ((searchInput || '').trim() === '') {
            setTotalPages(Math.ceil(filteredDocuments?.length / Math.max(1, itemsPerPage)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredDocuments, itemsPerPage, searchInput]);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
        } else {
            setCurrentPage(1);
        }
    }, [searchInput]);

    const topRow = 'document_title';
    const secondaryRow = [['Subtype', 'subtype'], ['Program', 'program']];
    const thirdRow = [['Document ID', 'id'], ['Docket ID', 'docketId']];
    const bottomRow = [['Posted Date', 'postedDate', formatDate2], ['Comment Start Date', 'commentStartDate', formatDate], ['Comment End Date', 'commentEndDate', formatDate]];
    const summaryRow = 'mini_summary';
    const links = ['', 'iddocument'];

    const paginatedDocuments = !loading && (searchInput || '').trim() === ""
        ? filteredDocuments.slice(((currentPage || 1) - 1) * itemsPerPage, (currentPage || 1) * itemsPerPage)
        : filteredDocuments;

    return (

        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            <div className='d-flex flex-column flex-column-fluid'>
                <DocumentHeader
                    docType={docType}
                    setIsInsightsVisible={setIsInsightsVisible}
                    isInsightsVisible={isInsightsVisible}
                    interests={interests}
                    programs={programs}
                    filters={filters}
                    colors={colors}
                    data={data}
                />
                <DocumentToolbar
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    docType={docType}
                    filters={filters}
                    setFilters={setFilters}
                    handleApplyFilters={handleApplyFilters}
                    handleResetFilters={handleResetFilters}
                    sortConfig={sortConfig}
                    setSortConfig={setSortConfig}
                    onApplySort={onApplySort}
                    onResetSort={onResetSort}
                    sortDirection={sortDirection}
                    setSortDirection={setSortDirection}
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                />
                {error && <div className="error-message">{error.message}</div>}
                {alert.message && (
                    <div className={`alert alert-${alert.type} alert-dismissible fade show mt-3`} role="alert">
                        {alert.message}
                        <button type="button" className="btn-close" onClick={() => setAlert({ message: '', type: '' })} aria-label="Close"></button>
                    </div>
                )}

                {loading ?
                    <HourGlass />
                    : (
                        // <DocumentTable loading={loading} error={error} filteredDocuments={filteredDocuments} currentPage={currentPage}
                        //     DOCUMENTS_PER_PAGE={Math.max(itemsPerPage, 1)} trackedDocuments={trackedDocuments}
                        //     setTrackedDocuments={setTrackedDocuments}
                        //     setAlert={setAlert} setError={setError}
                        //     formatDate={formatDate} formatDate2={formatDate2} searchInput={searchInput} />
                        <DocumentListWrapper
                            filteredDocuments={paginatedDocuments}
                            topRow={topRow}
                            secondaryRow={secondaryRow}
                            thirdRow={thirdRow}
                            bottomRow={bottomRow}
                            summaryRow={summaryRow}
                            links={links}
                            linkTo={'id'}
                            formatDate={formatDate}
                            formatDate2={formatDate2}
                            trackedDocuments={trackedDocuments}
                            setTrackedDocuments={setTrackedDocuments}
                            docType={docType}
                            setAlert={setAlert}
                        />
                    )}


                {totalPages >= 1 ? (
                    <div className="d-flex justify-content-center align-items-center">

                        <ItemsPerPageWrapper itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} bottomSpace={7} />
                        <PaginationWrapper totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                    </div>
                ) : (
                    <div className="mb-4"></div>
                )
                }

            </div>

        </div >



    );
};

export default Document;






