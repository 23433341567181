import { useMemo, useState } from 'react'
import { useTable, Column, Row } from 'react-table'
import { CustomHeaderColumn } from './columns/CustomHeaderColumn'
import { CustomRow } from './columns/CustomRow'
import { useQueryResponseData, useQueryResponseLoading } from '../core/QueryResponseProvider'
import { usersColumns } from './columns/_columns'
import { User } from '../core/_models'
//import { UsersListLoading } from '../components/loading/UsersListLoading'
import PaginationWrapper from '../../../../../../components/PaginationWrapper'
import { KTCardBody } from '../../../../../../../_metronic/helpers'
import HourGlass from '../../../../../Loading/HourGlassSpinner/HourGlass'

const UsersTable = () => {
  const users = useQueryResponseData()

  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => users, [users])
  const columns: readonly Column<any>[] = useMemo(() => usersColumns as Column<any>[], [])

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Slice the data according to the current page and items per page
  const currentPageData = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  }, [data, currentPage, itemsPerPage]);

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data: currentPageData, // Use currentPageData instead of data
  })

  // useEffect(() => {
  //   window.scrollTo(0, document.body.scrollHeight);
  // }, [currentPage]);

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        {isLoading ? (
          <HourGlass height="40vh" />
        ) : (
          <table
            id='kt_table_users'
            className='table align-middle  table-hover table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<User>, i) => {
                  prepareRow(row)
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      No matching records found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
      {!isLoading && totalPages > 1 &&
        <div className="mt-5 mb-n3">
          <PaginationWrapper totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
      }
      {/* {isLoading && <UsersListLoading />} */}
    </KTCardBody>
  )
}

export { UsersTable }