import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { KTIcon } from '../../../_metronic/helpers';
import DevicesTable from './DevicesTable';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
// import { LinksListPagination } from './LinksListPagination';
import PaginationWrapper from '../../components/PaginationWrapper';
import ItemsPerPageWrapper from '../../components/ItemsPerPageWrapper';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { MixedWidgetRegistration } from '../Home/MixedWidgetRegistration';
import HourGlass from '../Loading/HourGlassSpinner/HourGlass';
import SearchBar from '../../components/SearchBar';
// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';

const Registration = () => {
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const location = useLocation();
    const [searchTerm, setSearchTerm] = useState(location.state ? location.state.searchTerm : '');
    // const [filtered, setFiltered] = useState([]);
    const [currentPage, setCurrentPage] = useState(location.state ? location.state.currentPage : 1);
    // const totalItems = filtered?.length || data.length;
    const [itemsPerPage, setItemsPerPage] = useState(Number(Cookies.get('itemsPerPage')) || 10);
    const [totalPages, setTotalPages] = useState(0);
    const interests = useSelector(state => state.account.interests);
    const [loadSort, setLoadSort] = useState(false);
    const [filterDecisionDateRange, setFilterDecisionDateRange] = useState(Cookies.get('selectedDays') || '7');
    const [sortConfig, setSortConfig] = useState({ key: 'product_release_date', direction: 'asc' });
    const [days, setDays] = useState(parseInt(Cookies.get('selectedDays'), 10) || 7);
    const handleHeaderClick = (key) => {
        let direction = sortConfig.direction === 'asc' ? 'desc' : 'asc';
        setSortConfig({ key, direction });
    };
    const navigate = useNavigate();
    const [committees, setCommittees] = useState({});
    const firstRenderDays = useRef(true);
    const firstRenderSearchTerm = useRef(true);
    const [specialityOptions] = useState(interests.committees);
    const [productCodeOptions, setProductCodeOptions] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const [selectedDeviceClasses, setSelectedDeviceClasses] = useState(interests.device_classes);
    const handleDeviceClassChange = (event) => {
        const { value, checked } = event.target;
        let newValue = value === 'Class 1' ? 1 : value === 'Class 2' ? 2 : 3;
        setSelectedDeviceClasses(currentClasses => {
            if (checked) {
                if (!currentClasses.includes(newValue)) {
                    return [...currentClasses, newValue];
                }
                return currentClasses;
            } else {
                return currentClasses.filter(item => item !== newValue);
            }
        });
    };

    const [specialityDropdownOpen, setSpecialityDropdownOpen] = useState(false);
    const toggleSpecialityDropdown = () => setSpecialityDropdownOpen(!specialityDropdownOpen);
    const [selectedSpecialities, setSelectedSpecialities] = React.useState(interests.committees);
    const prevSearchInputRef = useRef();

    const handleSpecialityChange = (event) => {
        const { value, checked } = event.target;

        // Handle "All" option separately
        if (value === 'All') {
            if (checked) {
                setSelectedSpecialities(specialityOptions); // Select all options
            }
            else {
                setSelectedSpecialities([]); // Deselect all options
            }
        } else {
            setSelectedSpecialities((prevSelectedSpecialities) => {
                if (checked && !prevSelectedSpecialities.includes(value)) {
                    // If selecting an option and all other options are selected, add "All" to the selection
                    const allSelected = [...prevSelectedSpecialities, value].length === specialityOptions.length;
                    return allSelected ? [...prevSelectedSpecialities, value, 'All'] : [...prevSelectedSpecialities, value];
                } else if (!checked) {
                    // If deselecting an option, also remove "All" from the selection
                    return prevSelectedSpecialities.filter((speciality) => speciality !== value && speciality !== 'All');
                }
                return prevSelectedSpecialities;
            });
        }
    };

    const [productCodeDropdownOpen, setProductCodeDropdownOpen] = useState(false);
    const toggleProductCodeDropdown = () => setProductCodeDropdownOpen(!productCodeDropdownOpen);
    const [selectedProductCodes, setSelectedProductCodes] = React.useState([]);

    const handleProductCodeChange = (event) => {
        const { value, checked } = event.target;

        // Handle "All" option separately
        if (value === 'All') {
            if (checked) {
                setSelectedProductCodes(productCodeOptions); // Select all options
            }
            else {
                setSelectedProductCodes([]); // Deselect all options
            }
        } else {
            setSelectedProductCodes((prevSelectedProductCodes) => {
                if (checked && !prevSelectedProductCodes.includes(value)) {
                    // If selecting an option and all other options are selected, add "All" to the selection
                    const allSelected = [...prevSelectedProductCodes, value].length === productCodeOptions.length;
                    return allSelected ? [...prevSelectedProductCodes, value, 'All'] : [...prevSelectedProductCodes, value];
                } else if (!checked) {
                    // If deselecting an option, also remove "All" from the selection
                    return prevSelectedProductCodes.filter((productCode) => productCode !== value && productCode !== 'All');
                }
                return prevSelectedProductCodes;
            });
        }
    };

    // const processDataForDaysVsDeviceClass = (data) => {
    //     const deviceCounts = data.reduce((acc, item) => {
    //         const deviceclass = item.device_class;
    //         if (!acc[deviceclass]) {
    //             acc[deviceclass] = 0;
    //         }
    //         acc[deviceclass]++;
    //         return acc;
    //     }, {});

    //     const seriesData = Object.entries(deviceCounts).map(([name, count]) => ({
    //         name,
    //         data: [count] // Highcharts expects an array of data even for a single value
    //     }));

    //     return seriesData;

    // };

    // const MyChartComponent = ({ data }) => {
    //     const chartOptions = {
    //         chart: {
    //             type: 'column'
    //         },
    //         title: {
    //             text: ''
    //         },
    //         xAxis: {
    //             min: 0,
    //             title: {
    //                 text: 'Device Class'
    //             },
    //             labels: {
    //                 enabled: false // This will hide the x-axis labels
    //             },
    //         },
    //         yAxis: {
    //             min: 0.1,
    //             type: 'logarithmic',
    //             title: {
    //                 text: 'Number of Registrations'
    //             }
    //         },
    //         tooltip: {
    //             formatter: function () {
    //                 return `Class ${this.series.name}: ${this.y}`;
    //             }
    //         },
    //         series: processDataForDaysVsDeviceClass(data)
    //     };
    //     return (
    //         <div className={`card`} >
    //             <div className='card-header border-0 py-5'>
    //                 <h3 className='card-title align-items-start flex-column'>
    //                     <span className='card-label fw-bold fs-3 mb-1'>Registrations</span>
    //                 </h3>
    //             </div>
    //             <div className='card-body d-flex flex-column'>
    //                 <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    //             </div>
    //         </div >
    //     )
    // };

    // const processDataForEstablishmentType = (data) => {
    //     const deviceCounts = data.reduce((acc, item) => {
    //         const deviceclass = item.establishment_type || "No Establishment Type";
    //         if (!acc[deviceclass]) {
    //             acc[deviceclass] = 0;
    //         }
    //         acc[deviceclass]++;
    //         return acc;
    //     }, {});

    //     const seriesData = Object.entries(deviceCounts).map(([name, count]) => ({
    //         name: name,
    //         data: [count] // Highcharts expects an array of data even for a single value
    //     }));

    //     return seriesData;

    // };

    // const EstablishmentTypeChartComponent = ({ data }) => {
    //     const chartOptions = {
    //         chart: {
    //             type: 'column'
    //         },
    //         title: {
    //             text: ''
    //         },
    //         xAxis: {
    //             min: 0,
    //             title: {
    //                 text: 'Establishment Type'
    //             },
    //             labels: {
    //                 enabled: false // This will hide the x-axis labels
    //             },
    //         },
    //         yAxis: {
    //             min: 0.1,
    //             type: 'logarithmic',
    //             title: {
    //                 text: 'Number of Registrations'
    //             }
    //         },
    //         tooltip: {
    //             formatter: function () {
    //                 return `${this.series.name}: ${this.y}`;
    //             }
    //         },
    //         series: processDataForEstablishmentType(data)
    //     };
    //     return (
    //         <div className={`card`} >
    //             <div className='card-header border-0 py-5'>
    //                 <h3 className='card-title align-items-start flex-column'>
    //                     <span className='card-label fw-bold fs-3 mb-1'>Registrations by Establishment type</span>
    //                 </h3>
    //             </div>
    //             <div className='card-body d-flex flex-column'>
    //                 <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    //             </div>
    //         </div >
    //     )
    // };


    // const processDataForDeviceClassVsAdvisoryCommittee = (data) => {
    //     const result = {};
    //     data.forEach(item => {
    //         if (item.device_class && item.device_class > 0) {
    //             const deviceClass = `Class ${item.device_class}`;
    //             const committee = item.description;
    //             if (!result[deviceClass]) {
    //                 result[deviceClass] = {};
    //             }
    //             if (!result[deviceClass][committee]) {
    //                 result[deviceClass][committee] = 0;
    //             }
    //             result[deviceClass][committee]++;
    //         }
    //     });

    //     const seriesData = Object.keys(result).map(deviceClass => ({
    //         name: deviceClass,
    //         data: Object.values(result[deviceClass]),
    //         stack: 'deviceClass'
    //     }));

    //     const categories = [...new Set(data.map(item => item.description))];

    //     return { seriesData, categories };
    // };

    // const DeviceClassVsAdvisoryCommitteeChart = ({ data }) => {
    //     const { seriesData, categories } = processDataForDeviceClassVsAdvisoryCommittee(data);
    //     const chartOptions = {
    //         chart: {
    //             type: 'bar'
    //         },
    //         title: {
    //             text: ''
    //         },
    //         xAxis: {
    //             categories: categories
    //         },
    //         yAxis: {
    //             min: 0.1,
    //             type: 'logarithmic',
    //             title: {
    //                 text: 'Number of Registrations'
    //             }
    //         },
    //         plotOptions: {
    //             bar: {
    //                 stacking: 'normal'
    //             }
    //         },
    //         series: seriesData
    //     };
    //     return (
    //         <div className={`card`} >
    //             <div className='card-header border-0 py-5'>
    //                 <h3 className='card-title align-items-start flex-column'>
    //                     <span className='card-label fw-bold fs-3 mb-1'>Device Class vs. Specialty</span>
    //                 </h3>
    //             </div>
    //             <div className='card-body d-flex flex-column'>
    //                 <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    //             </div>
    //         </div >
    //     );
    // };

    // const processDataForAdvisoryCommitteeVsDays = (data) => {
    //     const committeeCounts = data.reduce((acc, item) => {
    //         const committee = item.description; // Assuming 'committee' is the field name
    //         if (!acc[committee]) {
    //             acc[committee] = 0;
    //         }
    //         acc[committee]++;
    //         return acc;
    //     }, {});

    //     // Convert the counts object to an array suitable for Highcharts
    //     const seriesData = Object.entries(committeeCounts).map(([name, count]) => ({
    //         name,
    //         data: [count] // Highcharts expects an array of data even for a single value
    //     }));
    //     // const categories = [...new Set(data.map(item => item.review_advisory_committee))];
    //     return { seriesData };
    // };

    // const AdvisoryCommitteeVsDaysChart = ({ data }) => {

    //     const { seriesData } = processDataForAdvisoryCommitteeVsDays(data);
    //     const chartOptions = {
    //         chart: {
    //             type: 'column'
    //         },
    //         title: {
    //             text: ''
    //         },
    //         xAxis: [{
    //             title: {
    //                 text: 'Specialty'
    //             },
    //             labels: {
    //                 enabled: false // This will hide the x-axis labels
    //             },
    //         }],
    //         yAxis: {
    //             min: 0.1,
    //             type: 'logarithmic',
    //             title: {
    //                 text: 'Number of Registrations'
    //             }
    //         },
    //         tooltip: {
    //             formatter: function () {
    //                 return `${this.series.name}: ${this.y}`;
    //             }
    //         },
    //         series: seriesData
    //     };
    //     return (
    //         <div className={`card`}>
    //             <div className='card-header border-0 py-5'>
    //                 <h3 className='card-title align-items-start flex-column'>
    //                     <span className='card-label fw-bold fs-3 mb-1'>Registration by Specialty</span>
    //                 </h3>
    //             </div>
    //             <div className='card-body d-flex flex-column'>
    //                 <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    //             </div>
    //         </div >
    //     );
    // };


    const dropdownRef = useRef(null);
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        // Add when the dropdown is open and remove when closed
        if (dropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        // Cleanup
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownOpen]);


    const dropdownRef2 = useRef(null);
    const handleClickOutsideSpeciality = (event) => {
        if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
            setSpecialityDropdownOpen(false);
        }
    };

    useEffect(() => {
        // Add when the dropdown is open and remove when closed
        if (specialityDropdownOpen) {
            document.addEventListener('mousedown', handleClickOutsideSpeciality);
        } else {
            document.removeEventListener('mousedown', handleClickOutsideSpeciality);
        }

        // Cleanup
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideSpeciality);
        };
    }, [specialityDropdownOpen]);

    const dropdownRef3 = useRef(null);
    const handleClickOutsideProductCode = (event) => {
        if (dropdownRef3.current && !dropdownRef3.current.contains(event.target)) {
            setProductCodeDropdownOpen(false);
        }
    };

    useEffect(() => {
        // Add when the dropdown is open and remove when closed
        if (productCodeDropdownOpen) {
            document.addEventListener('mousedown', handleClickOutsideProductCode);
        } else {
            document.removeEventListener('mousedown', handleClickOutsideProductCode);
        }

        // Cleanup
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideProductCode);
        };
    }, [productCodeDropdownOpen]);



    // useEffect(() => {
    //     const fetchCommittees = () => {
    //         axios.get('/reg/v1/fda/advisory_committee')
    //             .then(response => {
    //                 const options = response.data.map(committee =>
    //                     `${committee.description} (${committee.code})`,
    //                 );
    //                 setSpecialityOptions(options);
    //             });
    //     };
    //     fetchCommittees();
    // }, []);

    useEffect(() => {
        const fetchProductCodes = () => {
            axios.get('/reg/v1/fda/product_codes')
                .then(response => {
                    const options = response.data.map(codes =>
                        `${codes.product_code}`,
                    );
                    setProductCodeOptions(options);
                    setSelectedProductCodes(options);
                });
        };
        fetchProductCodes();
    }, []);

    useEffect(() => {
        setCurrentPage(1);
    }, [selectedDeviceClasses, selectedSpecialities, selectedProductCodes]);



    useEffect(() => {
        if (interests) {
            const newCommittees = {};
            interests.committees.forEach(committee => {
                const match = committee ? committee.match(/(.*?)\s*\((.*?)\)/) : undefined;
                if (match && match.length > 2) {
                    const key = match[2].trim();
                    const value = match[1].trim();
                    newCommittees[key] = value;
                }
            });
            setCommittees(newCommittees);
        }
    }, [interests]);

    useEffect(() => {
        Cookies.set('itemsPerPage', itemsPerPage);
    }, [itemsPerPage]);

    useEffect(() => {
        Cookies.set('selectedDays', filterDecisionDateRange);
    }, [filterDecisionDateRange]);

    // useEffect(() => {
    //     if (currentPage === 1 && data.length > 0) {
    //         setTotalPages(Math.ceil(data[0].reg_count / Math.max(1, itemsPerPage)));
    //     }
    //     else if (currentPage === 1 && data.length === 0) {
    //         setTotalPages(0);
    //     }
    // }, [currentPage, itemsPerPage, data]);

    // const fetchData = async (page) => {
    //     setLoading(true);
    //     setLoadSort(true);
    //     try {
    //         //const response = await axios.get(`/reg/v1/fda/preference/registration/pagination?page_num=1&page_size=10&sort_col=fulldate&sort_order=asc`);
    //         const response = await axios.get(`/reg/v1/fda/preference/registration/pagination?page_num=${page}&page_size=${itemsPerPage}&sort_col=${sortConfig.key}&sort_order=${sortConfig.direction}&days=0`);
    //         console.log('response:', response);
    //         setTotalPages(Math.ceil(data[0].reg_count / Math.max(1, itemsPerPage)));
    //         const newData = response.data;
    //         setData(newData);
    //         setLoading(false);
    //         setLoadSort(false);
    //     } catch (error) {
    //         setLoading(false);
    //         console.log('Error fetching devices:', error);
    //     }
    // };

    // Example useEffect to load initial data
    // useEffect(() => {
    //     fetchData(currentPage);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [currentPage, itemsPerPage, sortConfig]);
    useEffect(() => {
        if (firstRenderDays.current) {
            firstRenderDays.current = false;
            return;
        }
        setSearchTerm('');
        setCurrentPage(1);
    }, [days]);

    useEffect(() => {
        if (firstRenderSearchTerm.current) {
            firstRenderSearchTerm.current = false;
            return;
        }

        const handler = setTimeout(() => {
            setCurrentPage(1);
            if ((searchTerm || '').trim() !== '') {
                setSortConfig({ key: '', direction: '' });
            } else {
                setSortConfig({ key: 'product_release_date', direction: 'asc' });
            }
        }, 0);

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    useEffect(() => {
        setLoadSort(true);
    }, [days]);

    useEffect(() => {
        prevSearchInputRef.current = searchTerm;
    }, [searchTerm]);

    useEffect(() => {
        if (selectedProductCodes.length === 0) {
            return;
        }
        const delayDebounceFn = setTimeout(async () => {
            const fetchNewDevices = async () => {
                setLoading(true);
                //setLoadSort(true);

                const committees = selectedSpecialities.map(item => {
                    const match = item.match(/\(([^)]+)\)/);
                    return match ? match[1] : null;
                }).filter(Boolean);

                const sortedCommittees = committees.sort((a, b) => a.localeCompare(b));
                const sortedProductCodes = selectedProductCodes.sort((a, b) => a.localeCompare(b));
                const sortedDeviceClasses = selectedDeviceClasses.sort((a, b) => a - b);

                const filters = { device_classes: sortedDeviceClasses, committees: sortedCommittees, product_codes: sortedProductCodes };
                const filtersJson = JSON.stringify(filters);

                try {
                    if ((searchTerm || '').trim() === '') {
                        const response = await axios.post(
                            `/reg/v1/fda/preference/registration/pagination?page_num=${currentPage}&page_size=${itemsPerPage}&sort_col=${sortConfig.key}&sort_order=${sortConfig.direction}&days=${days}`,
                            { filter: filtersJson },
                            {
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            }
                        );
                        if (response.data) {
                            setData(response.data);
                            setTotalPages(Math.ceil(response.data[0]?.total_count / Math.max(1, itemsPerPage)));
                        }
                    } else {
                        const response = await axios.post(
                            `/reg/v1/search/search/registration?page_num=${currentPage}&page_size=${itemsPerPage}&sort_col=1&sort_order=1&search_string=${searchTerm}`,
                            { filter: filtersJson },
                            {
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            }
                        );
                        if (response.data) {
                            setData(response.data);
                            setTotalPages(Math.ceil(response.data[0]?.search_count / Math.max(1, itemsPerPage)));
                        }
                    }
                } catch (error) {
                    console.error("Failed to fetch new devices:", error);
                } finally {
                    setLoading(false);
                    setLoadSort(false);
                }
            };

            fetchNewDevices();
        }, 100);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, itemsPerPage, sortConfig.direction, sortConfig.key, days, searchTerm, selectedDeviceClasses, selectedSpecialities, selectedProductCodes]);

    // const matchesCommittee = (event, committees) =>
    //     committees.some(committee => {
    //         const extractedName = committee.split(' (')[0].trim();
    //         return extractedName === event.description;
    //         // const match = committee.match(/\(([^)]+)\)/);
    //         // const code = match ? match[0] : committee;
    //         // console.log(committee, event.description, code);
    //         // return code === event.description;
    //     });


    // useEffect(() => {
    //     const filteredData = data.filter(registration => {
    //         const matchesSearchTerm = (searchTerm || '').trim() === '' ||
    //         (registration.pma_number && registration.pma_number.toLowerCase().includes(searchTerm.toLowerCase())) ||
    //         (registration.proprietary_name && registration.proprietary_name.toLowerCase().includes(searchTerm.toLowerCase()))||
    //         (registration.establishment_type && registration.establishment_type.toLowerCase().includes(searchTerm.toLowerCase()))||
    //             (registration.k_number && registration.k_number.toLowerCase().includes(searchTerm.toLowerCase()));
    //         // const matchesDeviceClass = !filterDeviceClass || parseInt(classification.device_class) === parseInt(filterDeviceClass);
    //         // const matchesRegulationNumber = !filterRegulationNumber || classification.regulation_number.toLowerCase().includes(filterRegulationNumber.toLowerCase());
    //         // const matchesProductCode = !filterProductCode || classification.product_code.toLowerCase().includes(filterProductCode.toLowerCase());
    //         // const matchesMedicalSpecialty = !filterMedicalSpecialty || classification.medical_specialty_description.toLowerCase().includes(filterMedicalSpecialty.toLowerCase());
    //         // const matchesKNumber = !filterKNumber || (classification.k_number && classification.k_number.toLowerCase().includes(filterKNumber.toLowerCase()));
    //         const matchesInterests = (selectedSpecialities.length === 0 || matchesCommittee(registration, selectedSpecialities)) &&
    //             (selectedDeviceClasses.length === 0 || selectedDeviceClasses.includes(Number(registration.device_class)));
    //         return matchesSearchTerm && matchesInterests;
    //         // && matchesDeviceClass && matchesRegulationNumber && matchesProductCode && matchesMedicalSpecialty && matchesKNumber;
    //     });
    //     setFiltered(filteredData);
    // }, [currentPage, searchTerm, data, selectedDeviceClasses, selectedSpecialities, itemsPerPage]);

    // const sortedData = React.useMemo(() => {
    //     let sortableData = [...filtered];
    //     if (sortConfig !== null) {
    //         sortableData.sort((a, b) => {
    //             if (a[sortConfig.key] < b[sortConfig.key]) {
    //                 return sortConfig.direction === 'asc' ? -1 : 1;
    //             }
    //             if (a[sortConfig.key] > b[sortConfig.key]) {
    //                 return sortConfig.direction === 'asc' ? 1 : -1;
    //             }
    //             return 0;
    //         });
    //     }
    //     return sortableData;
    // }, [filtered, sortConfig]);

    // const paginatedData = sortedData.slice((currentPage - 1) * Math.max(1, itemsPerPage), currentPage * Math.max(1, itemsPerPage));

    return (
        <div>
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div className='d-flex flex-column flex-column-fluid'>
                    <div className="d-flex flex-row-fluid px-1">
                        <div className="d-flex flex-column-auto align-items-center justify-content-center ">
                            <h1 className="d-md-block text-wrap ms-1" style={{ color: "#4d4d4d" }}>
                                <div className="d-flex align-items-center ms-4">
                                    <KTIcon iconName='devices' iconType="duotone" className='fw-bold fs-1 mx-3 text-primary ' />
                                    <span>Registrations</span>
                                    {/* {loading && (
                                        <div className="spinner-border text-primary ms-3" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    )} */}
                                </div>
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row-fluid px-10 align-items-center">
                    <h4 className="d-md-block text-wrap mt-1 text-muted">Based on user preferences</h4>
                </div>
                {committees && interests.device_classes && (
                    <div className="d-flex flex-row-fluid px-10">
                        {Object.keys(committees).length > 0 && (
                            <div style={{ position: 'relative', paddingRight: '7px' }}>
                                <h6 className={`d-block text-wrap mt-1`}>Specialties: {' '}
                                    {Object.entries(committees).map(([key, value], index) => (
                                        <OverlayTrigger
                                            key={index}
                                            placement="top"
                                            overlay={
                                                <Tooltip id={`tooltip-${index}`}>
                                                    {value}
                                                </Tooltip>
                                            }
                                        >
                                            <span>{index > 0 && ', '}{key}</span>
                                        </OverlayTrigger>
                                    ))}
                                </h6>
                                {interests.device_classes.length > 0 && (
                                    <div style={{
                                        position: 'absolute',
                                        right: 0,
                                        top: '5%',
                                        bottom: '5%',
                                        width: '1px',
                                        backgroundColor: '#4d4d4d'
                                    }}></div>
                                )}
                            </div>
                        )}
                        {interests.device_classes.length > 0 && (
                            <div style={{ paddingLeft: Object.keys(committees).length === 0 ? '0px' : '7px' }}>
                                <h6 className={`d-block mt-1`}>Device Classes: {interests.device_classes.join(', ')}</h6>
                            </div>
                        )}
                    </div>
                )}
                <div className="d-flex flex-column-auto d-flex align-items-center ml-auto ms-6 mt-4">
                    <ul className='nav mb-4'>
                        <li className='nav-item'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${days === 7 ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setFilterDecisionDateRange(7); setDays(7); }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                7 Days
                            </button>
                        </li>
                        <li className='nav-item ms-1'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${days === 30 ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setFilterDecisionDateRange(30); setDays(30); }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                30 Days
                            </button>
                        </li>
                        <li className='nav-item ms-1'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${days === 90 ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setFilterDecisionDateRange(90); setDays(90); }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                90 Days
                            </button>
                        </li>
                        <li className='nav-item ms-1'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${days === 180 ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setFilterDecisionDateRange(180); setDays(180); }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                180 Days
                            </button>
                        </li>
                        <li className='nav-item ms-1'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${days === 365 ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setFilterDecisionDateRange(365); setDays(365); }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                1 Year
                            </button>
                        </li>
                        <li className='nav-item ms-1'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${days === 0 ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setFilterDecisionDateRange(0); setDays(0); }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                All
                            </button>
                        </li>
                    </ul>

                    <div className='mb-4 dropdown ms-7' ref={dropdownRef}>
                        <button className='btn btn-color-muted btn-active btn-active-light-primary dropdown-toggle' type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded={dropdownOpen} onClick={toggleDropdown}
                            style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}
                        >
                            Device Class
                        </button>
                        {dropdownOpen && (
                            <ul className='dropdown-menu show mt-1' aria-labelledby='dropdownMenuButton'>
                                <li style={{ margin: '10px 0', width: '150px' }}>
                                    <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id='class1' name='deviceClass' value='Class 1' checked={selectedDeviceClasses.includes(1)} onChange={handleDeviceClassChange} />
                                    <label className='form-label fw-bold ms-5 mb-2' htmlFor='class1' style={{ fontSize: '1.1rem' }}>Class 1</label>
                                </li>
                                <li style={{ margin: '10px 0', width: '150px' }}>
                                    <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id='class2' name='deviceClass' value='Class 2' checked={selectedDeviceClasses.includes(2)} onChange={handleDeviceClassChange} />
                                    <label className='form-label fw-bold ms-5 mb-2 ' htmlFor='class2' style={{ fontSize: '1.1rem' }}>Class 2</label>
                                </li>
                                <li style={{ margin: '10px 0', width: '150px' }}>
                                    <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id='class3' name='deviceClass' value='Class 3' checked={selectedDeviceClasses.includes(3)} onChange={handleDeviceClassChange} />
                                    <label className='form-label fw-bold ms-5 mb-2' htmlFor='class3' style={{ fontSize: '1.1rem' }}>Class 3</label>
                                </li>
                            </ul>
                        )}
                    </div>

                    <div className='mb-4 dropdown ms-7' ref={dropdownRef2}>
                        <button className='btn btn-color-muted btn-active btn-active-light-primary dropdown-toggle' type='button' id='dropdownSpecialityButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded={dropdownOpen} onClick={toggleSpecialityDropdown}
                            style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}
                        >
                            Specialty
                        </button>
                        {specialityDropdownOpen && (
                            <ul className='dropdown-menu show mt-1' aria-labelledby='dropdownSpecialityButton' style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                <li style={{ margin: '10px 0', width: '260px' }}>
                                    <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id='specialityAll' name='specialityOption' value='All' checked={selectedSpecialities.length === specialityOptions.length} onChange={handleSpecialityChange} />
                                    <label className='form-label fw-bold ms-5 mb-2' htmlFor='specialityAll' style={{ fontSize: '1.1rem' }}>All</label>
                                </li>
                                {specialityOptions.map((option, index) => (
                                    <li key={index} style={{ margin: '10px 0', width: '260px' }}>
                                        <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id={`speciality${index}`} name='specialityOption' value={option} checked={selectedSpecialities.includes(option)} onChange={handleSpecialityChange} />
                                        <label className='form-label fw-bold ms-5 mb-2' htmlFor={`speciality${index}`} style={{ fontSize: '1.1rem' }}>{option}</label>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <div className='mb-4 dropdown ms-7' ref={dropdownRef3}>
                        <button className='btn btn-color-muted btn-active btn-active-light-primary dropdown-toggle' type='button' id='dropdownProductCodeButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded={dropdownOpen} onClick={toggleProductCodeDropdown}
                            style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}
                        >
                            Product Code
                        </button>
                        {productCodeDropdownOpen && (
                            <ul className='dropdown-menu show mt-1' aria-labelledby='dropdownProductCodeButton' style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                <li style={{ margin: '10px 0', width: '260px' }}>
                                    <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id='productCodeAll' name='productCodeOption' value='All' checked={selectedProductCodes.length === productCodeOptions.length} onChange={handleProductCodeChange} />
                                    <label className='form-label fw-bold ms-5 mb-2' htmlFor='productCodeAll' style={{ fontSize: '1.1rem' }}>All</label>
                                </li>
                                {productCodeOptions.map((option, index) => (
                                    <li key={index} style={{ margin: '10px 0', width: '260px' }}>
                                        <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id={`productCode${index}`} name='productCodeOption' value={option} checked={selectedProductCodes.includes(option)} onChange={handleProductCodeChange} />
                                        <label className='form-label fw-bold ms-5 mb-2' htmlFor={`productCode${index}`} style={{ fontSize: '1.1rem' }}>{option}</label>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                </div>

                <MixedWidgetRegistration
                    className='card-xxl-stretch pt-3'
                    chartHeight='240px'
                    days={days}
                    doctype={'Registrations'}
                    activity={"all"}
                    scale="logarithmic"
                    showChart={true}
                />

                <div className={`d-flex flex-wrap gap-4 flex-row-fluid align-items-center px-8 mb-6 mt-2`}>
                    <div className="col-6">
                        <SearchBar
                            searchInput={searchTerm}
                            setSearchInput={setSearchTerm}
                            placeholder={"Search Registrations"}
                        />
                    </div>
                    <div className="d-flex flex-column-auto ms-4 d-flex align-items-center">
                        <label className="fs-6 fw-bold text-gray-700">Items per page:</label>
                        <input
                            type="number"

                            value={itemsPerPage}
                            onChange={(e) => setItemsPerPage(e.target.value !== '' ? Number(e.target.value) : '')}
                            className='form-control form-control-solid ms-2 border border-1'
                            style={{ width: '90px', backgroundColor: '#f1faff' }}
                        />
                    </div>
                </div>

                {/* {!loading && data.length > 0 && (
                    <div className='row g-5 justify-content-center mt-1 mb-4'>
                        <div className='col-xxl-3'>
                            <EstablishmentTypeChartComponent data={data} />
                        </div>
                        <div className='col-xxl-3'>
                            <AdvisoryCommitteeVsDaysChart data={data} />
                        </div>
                        <div className='col-xxl-3'>
                            <DeviceClassVsAdvisoryCommitteeChart data={data} />
                        </div>
                        <div className='col-xxl-3'>
                            <MyChartComponent data={data} />
                        </div>
                    </div>
                )} */}
            </div>

            {/* {totalPages >= 1 && ( */}
            {loading ? <HourGlass height="40vh" /> : (<DevicesTable
                loadSort={loadSort}
                currentDevices={data}
                handleHeaderClick={handleHeaderClick}
                sortConfig={sortConfig}
                navigate={navigate}
                searchTerm={searchTerm}
                currentPage={currentPage}
            />)}
            {/* )} */}
            {!loading && totalPages >= 1 && (
                <div className="d-flex justify-content-center">

                    <ItemsPerPageWrapper itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} bottomSpace={5} />
                    <PaginationWrapper totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                </div>
            )}
        </div>
    )
}
export default Registration;