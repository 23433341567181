import React, { useState, useEffect } from 'react'
import axios from 'axios'
import TableRowRecalls from './TableRowRecalls';
import PaginationWrapper from '../../components/PaginationWrapper';
import HourGlass from '../Loading/HourGlassSpinner/HourGlass';


type Recall = {
    fulldate: string;
    openfda_device_class: string;
    openfda_device_name: string;
    openfda_medical_specialty_description: string;
    product_code: string;
    product_description: string;
    product_res_number: string;
    recall_count: string;
    recall_status: string;
    recalling_firm: string;
    res_event_number: string;
    root_cause_description: string;
    initiated_date: string;
    action: string;
    reason_for_recall: string;
    distribution_pattern: string;
    regulation_number: string;
    posted_date

}
type Props = {
    days: number;
}
const TablesWidgetRecalls: React.FC<Props> = ({ days }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [details, setDetails] = useState<Recall[]>([]);
    const [itemsPerPage] = useState(10);


    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [sortorder, setSortorder] = useState('desc');
    const [sortcol, setSortcol] = useState('initiated_date')

    const [count, setCount] = useState(0)

    useEffect(() => {
        const fetchData = async () => {
            // setLoading(true);
            try {
                const filtersJson = JSON.stringify({});
                const response = await axios.post(
                    `/reg/v1/fda/preference/recalls/pagination?page_num=${currentPage}&page_size=${itemsPerPage}&sort_col=${sortcol}&sort_order=${sortorder}&days=${days}`,
                    { filter: filtersJson },
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                );
                //const response = await axios.get(`/reg/v1/fda/preference/recalls/pagination?page_num=${currentPage}&page_size=${itemsPerPage}&sort_col=${sortcol}&sort_order=${sortorder}&days=${days}`);
                const totalCount = response.data.length > 0 ? response.data[0].recall_count : 0;
                setCount(totalCount);
                const warningLetters = response.data;
                setDetails(warningLetters);
                setTotalPages(Math.ceil(totalCount / itemsPerPage));
                setLoading(false);
            } catch (error) {
                console.error("Failed to fetch data:", error);
                setLoading(false);
            }
        };

        fetchData();
    }, [currentPage, itemsPerPage, days, sortcol, sortorder]);

    const currentItems = details;

    useEffect(() => {
        setCurrentPage(1);
        setLoading(true);

    }, [days])

    // useEffect(() => {
    //     window.scrollTo(0, document.body.scrollHeight);
    // }, [currentPage]);



    const handleSort = (columnName) => {
        if (sortcol === columnName) {
            setSortorder(sortorder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortcol(columnName);
            setSortorder('asc'); // Default to ascending when changing columns
        }
    };

    return (
        <div className="container-fluid p-0 m-0">
            <div className="row no-gutters">
                <div className="col">
                    <div className={`card`}>
                        {/* begin::Header */}
                        <div className='card-header border-0 pt-5'>
                            <div className="d-flex flex-column" >
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bold fs-3 mb-1'>
                                        Recalls
                                        {/* {loading && <span className='spinner-border text-gray-600 align-middle ms-2'></span>} */}

                                    </span>
                                </h3>
                                {!loading && count > 0 && (
                                    <span className="fs-6 text-gray-700"> Showing {((currentPage - 1) * itemsPerPage) + 1} to {Math.min(currentPage * itemsPerPage, count)} of {count} results</span>
                                )}
                                {!loading && count === 0 && (
                                    <span className="fs-6 text-gray-700"> 0 results </span>
                                )}
                            </div>
                        </div>
                        {/* end::Header */}
                        <div className='card-body px-8'>
                            {/* begin::Table container */}
                            <div className='table-responsive'>
                                {/* begin::Table */}
                                {loading ? (
                                    <HourGlass height="25vh" />
                                ) : (
                                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-1 gy-3' style={{ cursor: 'pointer' }}>
                                        {/* begin::Table head */}
                                        <thead>
                                            <tr className='fw-bolder text-gray-600 fs-5'>
                                                <th style={{ minWidth: '16%', width: '16%' }} onClick={() => handleSort('openfda_device_name')}>
                                                    Device Name
                                                    <span className={`sorting-icon ${sortcol === 'openfda_device_name' ? sortorder : ''}`}>
                                                        {' '}<i className={`bi ${sortcol === 'openfda_device_name' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                    </span>
                                                </th>
                                                <th style={{ minWidth: '15%', width: '15%' }} onClick={() => handleSort('recalling_firm')}>
                                                    Recalling Firm
                                                    <span className={`sorting-icon ${sortcol === 'recalling_firm' ? sortorder : ''}`}>
                                                        {' '}<i className={`bi ${sortcol === 'recalling_firm' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                    </span>
                                                </th>
                                                <th style={{ minWidth: '8%', width: '8%' }} onClick={() => handleSort('openfda_medical_specialty_description')}>
                                                    Specialty
                                                    <span className={`sorting-icon ${sortcol === 'openfda_medical_specialty_description' ? sortorder : ''}`}>
                                                        {' '}<i className={`bi ${sortcol === 'openfda_medical_specialty_description' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                    </span>
                                                </th>
                                                <th style={{ minWidth: '14%', width: '14%' }} className='text-center' onClick={() => handleSort('product_code')}>
                                                    Product Code
                                                    <span className={`sorting-icon ${sortcol === 'product_code' ? sortorder : ''}`}>
                                                        {' '}<i className={`bi ${sortcol === 'product_code' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                    </span>
                                                </th>
                                                <th style={{ minWidth: '12%', width: '12%' }} className='text-center' onClick={() => handleSort('openfda_device_class')}>
                                                    Device Class
                                                    <span className={`sorting-icon ${sortcol === 'openfda_device_class' ? sortorder : ''}`}>
                                                        {' '}<i className={`bi ${sortcol === 'openfda_device_class' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                    </span>
                                                </th>
                                                <th style={{ minWidth: '10%', width: '10%' }} onClick={() => handleSort('root_cause_description')}>
                                                    Root Cause
                                                    <span className={`sorting-icon ${sortcol === 'root_cause_description' ? sortorder : ''}`}>
                                                        {' '}<i className={`bi ${sortcol === 'root_cause_description' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                    </span>
                                                </th>
                                                <th className="text-center" style={{ minWidth: '20%', width: '20%' }} onClick={() => handleSort('product_res_number')}>
                                                    Recall Number
                                                    <span className={`sorting-icon ${sortcol === 'product_res_number' ? sortorder : ''}`}>
                                                        <i className={`ms-1 bi ${sortcol === 'product_res_number' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                    </span>
                                                </th>
                                                <th style={{ minWidth: '25%', width: '25%' }} className='text-end' onClick={() => handleSort('initiated_date')}>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        Received
                                                        <span className={`sorting-icon ${sortcol === 'initiated_date' ? sortorder : ''}`}>
                                                            <i className={`ms-1 bi ${sortcol === 'initiated_date' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                        </span>
                                                    </div>
                                                </th>

                                                {/* <th className='min-w-60px text-end'>Actions</th> */}
                                                {/* <th className=''>Product Res Number</th>
                      <th className=''>CFRes ID</th>
                      <th className=''>Product Code</th>
                      <th className=''>Product Description</th>
                      <th className=''>Reason for Recall</th>
                      <th className=''>Recall Status</th>
                      <th className=''>Date</th>
                      <th className='text-end'>Actions</th> */}
                                            </tr>
                                        </thead>
                                        {loading ? (
                                            <span className='indicator-progress' style={{ display: 'block', textAlign: 'center' }}>

                                            </span>
                                        ) : (
                                            <tbody>
                                                <TableRowRecalls items={currentItems} />
                                            </tbody>
                                        )}
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {totalPages > 1 && (
                    <div className="mt-5 mb-n5">
                        <PaginationWrapper
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                )}
            </div>

        </div>
    )
}
export { TablesWidgetRecalls }