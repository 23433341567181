import React, { useState, useEffect, CSSProperties } from "react";
import axios from 'axios';
import DataOpsToolbar from "./DataOpsToolbar";
import { Link } from "react-router-dom";
import PaginationWrapper from "../../../../components/PaginationWrapper";
import HourGlass from "../../../Loading/HourGlassSpinner/HourGlass";


interface History {
    document_id: string;
    summary: string;
    faq: string;
    impact: string;
    action: string;
    prediction: number;
    create_date: string;
}

const GenAITable = () => {
    const [histories, setHistory] = useState<History[]>([]);
    const [currentHistory, setCurrentHistory] = useState<History[]>([]);
    const [filteredHistory, setFilteredHistory] = useState<History[]>([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [totalPages, setTotalPages] = useState(0);
    const [sortConfig, setSortConfig] = useState({ key: 'create_date', direction: 'desc' });
    const [searchInput, setSearchInput] = useState('');
    const date = new Date();
    date.setDate(date.getDate() - 14);
    const twoWeeksAgo = date.toISOString().split('T')[0];
    const [filters, setFilters] = useState({
        startDate: twoWeeksAgo,
        endDate: '',
        id: '',
        prediction: '',
    });

    const tableStyle: CSSProperties = {
        '--bs-table-hover-bg': 'rgba(204, 229, 255, 1)',
        '--bs-table-striped-bg': 'rgba(230, 242, 255, 1)',
        'tableLayout': 'fixed'
    } as CSSProperties;

    const parseDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', { timeZone: 'UTC' });
    };

    // useEffect(() => {
    //     setTimeout(() => {
    //         window.scrollTo(0, document.body.scrollHeight);
    //     }, 0);
    // }, [currentPage]);

    const handleFilterChange = (filter: string, value: string) => {
        setFilters({ ...filters, [filter]: value });
    };

    const handleApplyFilters = () => {
        setLoading(true);
        let filteredData = histories;

        if (filters.id !== "") {
            filteredData = filteredData.filter(history =>
                history.document_id.toLowerCase().includes(filters.id.toLowerCase())
            );
        }

        if (filters.prediction !== "") {
            filteredData = filteredData.filter(history =>
                history.prediction.toString() === filters.prediction
            );
        }

        if (filters.startDate !== "") {
            const startDate = new Date(filters.startDate);
            filteredData = filteredData.filter(history =>
                new Date(history.create_date) >= startDate
            );
        }

        if (filters.endDate !== "") {
            const endDate = new Date(filters.endDate);
            filteredData = filteredData.filter(history =>
                new Date(history.create_date) <= endDate
            );
        }

        setFilteredHistory(filteredData);
        setCurrentPage(1);
        setLoading(false);
    };

    const handleResetFilters = () => {
        setFilters({
            startDate: twoWeeksAgo,
            endDate: '',
            id: '',
            prediction: ''
        });
        setCurrentPage(1);
    };

    const fetchHistory = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`/reg/v1/superadmin/genai/run_history/${filters.startDate}`);
            if (filters.endDate !== "") {
                const endDate = new Date(filters.endDate);
                response.data = response.data.filter(history =>
                    new Date(history.create_date) <= endDate
                );
            }
            // console.log(response.data)
            if (filters.id !== "") {
                response.data = response.data.filter(history =>
                    history.document_id.toLowerCase().includes(filters.id.toLowerCase())
                );
            }
            if (filters.prediction !== "") {
                response.data = response.data.filter(history =>
                    history.prediction.toString() === filters.prediction
                );
            }
            const sortedHistory = response.data.sort((a: History, b: History) => {
                const startDateComparison = new Date(b.create_date).getTime() - new Date(a.create_date).getTime();
                if (startDateComparison !== 0) {
                    return startDateComparison;
                } else {
                    return new Date(b.create_date).getTime() - new Date(a.create_date).getTime();
                }
            });
            setHistory(sortedHistory);
            setFilteredHistory(sortedHistory);
        } catch (error) {
            console.error('Error fetching histories:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchHistory();
        // eslint-disable-next-line
    }, [filters.startDate, filters.endDate]);



    useEffect(() => {
        setTotalPages(Math.ceil(filteredHistory.length / itemsPerPage));
    }, [filteredHistory, itemsPerPage]);

    useEffect(() => {
        setCurrentHistory(filteredHistory.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage));
    }, [filteredHistory, currentPage, itemsPerPage]);

    const handleHeaderClick = (key: string) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    useEffect(() => {
        const sortedHistory = [...filteredHistory].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
            if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });
        setFilteredHistory(sortedHistory);
        setCurrentPage(1);
        // eslint-disable-next-line 
    }, [sortConfig]);

    useEffect(() => {
        let filteredData = histories;

        if (searchInput !== "") {
            filteredData = filteredData.filter(history =>
                history.document_id.toLowerCase().includes(searchInput.toLowerCase()) ||
                history.summary.toLowerCase().includes(searchInput.toLowerCase()) ||
                history.faq.toLowerCase().includes(searchInput.toLowerCase()) ||
                history.impact.toLowerCase().includes(searchInput.toLowerCase()) ||
                history.action.toLowerCase().includes(searchInput.toLowerCase()) ||
                history.prediction.toString().includes(searchInput.toLowerCase()) ||
                parseDate(history.create_date).toLowerCase().includes(searchInput.toLowerCase())
            );
        }

        if (filters.id !== "") {
            filteredData = filteredData.filter(history =>
                history.document_id.toLowerCase().includes(filters.id.toLowerCase())
            );
        }

        if (filters.prediction !== "") {
            filteredData = filteredData.filter(history =>
                history.prediction.toString() === filters.prediction
            );
        }

        if (filters.startDate !== "") {
            const startDate = new Date(filters.startDate);
            filteredData = filteredData.filter(history =>
                new Date(history.create_date) >= startDate
            );
        }

        if (filters.endDate !== "") {
            const endDate = new Date(filters.endDate);
            filteredData = filteredData.filter(history =>
                new Date(history.create_date) <= endDate
            );
        }

        setFilteredHistory(filteredData);
        setCurrentPage(1);
        // eslint-disable-next-line 
    }, [searchInput, filters]);

    const formatDate = (dateString: string) => {
        const [year, month, day] = dateString.split('-');
        const date = new Date(Date.UTC(Number(year), Number(month) - 1, Number(day))); // Use Date.UTC to create a date in UTC
        const formattedDay = String(date.getUTCDate()).padStart(2, '0');
        const formattedMonth = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
        const formattedYear = date.getUTCFullYear();
        return `${formattedMonth}/${formattedDay}/${formattedYear}`;
    };

    return (
        <div>
            <div className="d-flex flex-column px-10 align-items-start">
                {(Object.values(filters).some(value => value !== '')) && (
                    <div className="mt-n2 mb-1">
                        <h5>Active Filters:</h5>
                        <ul>
                            {filters.id !== '' && (
                                <li><h6 className="d-md-block text-wrap">Document ID: {' '}
                                    {filters.id}
                                </h6></li>
                            )}
                            {filters.prediction !== '' && (
                                <li><h6 className="d-md-block text-wrap">Prediction: {' '}
                                    {filters.prediction}
                                </h6></li>
                            )}
                            {filters.startDate !== '' && filters.endDate !== '' && (
                                <li><h6 className="d-md-block text-wrap">Create Date Range: {formatDate(filters.startDate)} to {formatDate(filters.endDate)}</h6></li>
                            )}
                            {filters.startDate !== '' && filters.endDate === '' && (
                                <li><h6 className="d-md-block text-wrap">Start Create Date: {formatDate(filters.startDate)}</h6></li>
                            )}
                            {filters.startDate === '' && filters.endDate !== '' && (
                                <li><h6 className="d-md-block text-wrap">End Create Date: {formatDate(filters.endDate)}</h6></li>
                            )}
                        </ul>
                    </div>
                )}
            </div>
            <DataOpsToolbar
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                filters={filters}
                activeTab="gen-ai"
                handleFilterChange={handleFilterChange}
                handleApplyFilters={handleApplyFilters}
                handleResetFilters={handleResetFilters}
                objType={0}
            />
            {loading ? (
                <HourGlass height="25vh" />
            ) : (
                <div>
                    <div className='table-responsive mb-5'>
                        <table className="table table-striped table-hover table-rounded border gy-5 gs-7 align-middle dataTable no-footer"
                            style={tableStyle}>
                            <thead className="text-start text-muted fw-bolder fs-7 gs-0">
                                <tr className='fw-bold fs-5 text-gray-800 border-bottom-3 border-gray-200'>
                                    <th className="ps-10" style={{ whiteSpace: 'nowrap', width: '28%', cursor: 'pointer' }} onClick={() => handleHeaderClick('document_id')}>
                                        Document ID
                                        <span className={`sorting-icon ${sortConfig.key === 'document_id' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'document_id' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                    <th style={{ whiteSpace: 'nowrap', width: '20%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('summary')}>
                                        Summary
                                        <span className={`sorting-icon ${sortConfig.key === 'summary' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'summary' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                    <th style={{ whiteSpace: 'nowrap', width: '20%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('faq')}>
                                        FAQ
                                        <span className={`sorting-icon ${sortConfig.key === 'faq' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'faq' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                    <th style={{ whiteSpace: 'nowrap', width: '20%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('impact')}>
                                        Impact
                                        <span className={`sorting-icon ${sortConfig.key === 'impact' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'impact' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                    <th style={{ whiteSpace: 'nowrap', width: '20%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('action')}>
                                        Action Items
                                        <span className={`sorting-icon ${sortConfig.key === 'action' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'action' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                    <th className="text-center" style={{ whiteSpace: 'nowrap', width: '13%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('prediction')}>
                                        Prediction
                                        <span className={`sorting-icon ${sortConfig.key === 'prediction' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'prediction' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                    <th style={{ whiteSpace: 'normal', width: '22%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('create_date')}>
                                        Create Date
                                        <span className={`sorting-icon ${sortConfig.key === 'create_date' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'create_date' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            {loading ? (
                                <tbody className='text-gray-600 fw-bold'>
                                    <tr>
                                        <td colSpan={7}>
                                            <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                Loading...
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody className='text-gray-600 fw-bold'>
                                    {currentHistory.length === 0 ? (
                                        <tr style={{ backgroundColor: 'rgba(230, 242, 255, 1)' }}>
                                            <td colSpan={7} className='text-dark fw-semibold fs-5' style={{ textAlign: 'center' }}>No records found with selected filters. Please try again with other filters.</td>
                                        </tr>
                                    ) : (
                                        currentHistory.map((history) => (
                                            <tr key={history.document_id} className='align-items-center bg-hover-light-primary'>
                                                <td className="py-9 ps-10" style={{ whiteSpace: 'normal', textAlign: 'left' }}>
                                                    <Link to={`/document/${history.document_id}`}>{history.document_id}</Link>
                                                </td>
                                                <td className="ms-10" style={{ whiteSpace: 'normal', textAlign: 'left' }}>{history.summary}</td>
                                                <td className="ms-10" style={{ whiteSpace: 'normal', textAlign: 'left' }}>{history.faq}</td>
                                                <td className="ms-10" style={{ whiteSpace: 'normal', textAlign: 'left' }}>{history.impact}</td>
                                                <td className="ms-10" style={{ whiteSpace: 'normal', textAlign: 'left' }}>{history.action}</td>
                                                <td className="ms-10" style={{ whiteSpace: 'normal', textAlign: 'center' }}>{history.prediction}</td>
                                                <td style={{ whiteSpace: 'normal', textAlign: 'left', paddingRight: '20px' }}>{parseDate(history.create_date)}</td>
                                            </tr>
                                        )))}
                                </tbody>
                            )}
                        </table>
                    </div>
                    {totalPages > 1 &&
                        <div className='card-footer mb-n15'>
                            <div className='row mt-2 '>
                                <PaginationWrapper totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                            </div>
                        </div>
                    }
                </div>
            )}
        </div>
    );
};

export default GenAITable;
