import { Suspense, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { AuthInit, useAuth } from './modules/auth'
import { ThemeModeProvider } from '../_metronic/partials'
import axios from 'axios'
import Cookies from 'js-cookie'

const App = () => {
  const { currentUser, logout } = useAuth()
  const navigate = useNavigate()


  useEffect(() => {
    if (currentUser) { // Check if currentUser exists
      const checkSession = () => {
        axios.get('/reg/v1/home/')
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              Cookies.set('initialPath', '/home');
              logout();
              navigate('/auth/login')
            }
          })
      };

      checkSession(); // Run the function immediately

      const interval = setInterval(checkSession, 10 * 60 * 1000); // Then run it every 10 minutes

      // clear the interval when the component unmounts
      return () => clearInterval(interval);
    }
  }, [currentUser, logout, navigate]);
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ThemeModeProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </ThemeModeProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export { App }
