/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { KTIcon } from '../../../_metronic/helpers';
import HourGlass from '../Loading/HourGlassSpinner/HourGlass';
// import axios from 'axios';

function CountryCompare() {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [alert, setAlert] = useState({ message: '', type: '' });
    const [countryDropdownOpen, toggleCountryDropdown] = useState(false);
    const [topicDropdownOpen, toggleTopicDropdown] = useState(false);

    // List of countries
    const countryOptions = [
        { value: 'usa', label: 'United States' },
        { value: 'canada', label: 'Canada' },
        { value: 'uk', label: 'United Kingdom' },
        { value: 'australia', label: 'Australia' },
        { value: 'germany', label: 'Germany' },
        { value: 'india', label: 'India' },
        // Add more countries as needed
    ];

    const rowsOptions = [
        { value: 'Classification for Medical Device', label: 'Classification for Medical Device' },
        { value: 'Clinical Trials Medical Device', label: 'Clinical Trials Medical Device' },
        { value: 'Fees for Medical Device', label: 'Fees for Medical Device' },
        { value: 'Global Post-Market Overview', label: 'Global Post-Market Overview' },
        { value: 'Global Pre-Market Overview', label: 'Global Pre-Market Overview' },
        { value: 'Import and Export', label: 'Import and Export' },
        { value: 'IVD as a Medical Device', label: 'IVD as a Medical Device' },
        { value: 'Market Clearance', label: 'Market Clearance' },
        { value: 'Safety Medical Device', label: 'Safety Medical Device' },
    ]

    // Custom handler to enforce max selection
    const handleChange = (selectedOptions) => {
        if (selectedOptions.length > 5) {
            setAlert({ message: 'You can only select up to 5 countries.', type: 'danger' });
            return;
        }
        setSelectedCountries(selectedOptions);
        setIsLoading(false);
    };

    return (
        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            <div className='d-flex flex-column flex-column-fluid'>
                <div className="d-flex flex-row-fluid px-1">
                    <div className="d-flex flex-column-auto align-items-center justify-content-center ">
                        <h1 className="d-md-block text-wrap ms-1" style={{ color: "#4d4d4d" }}>
                            <div className="d-flex align-items-center ms-4">
                                <KTIcon iconName='parcel-tracking' iconType="duotone" className='fw-bold fs-1 mx-3 text-primary ' />
                                <span>Country Compare</span>
                            </div>
                        </h1>
                    </div>
                </div>
                {isLoading ? (
                    <HourGlass />
                ) : (
                    <>
                        <div className="d-flex flex-column-fluid px-1 align-items-center justify-content-center mt-3">
                            <div className="d-flex flex-row align-items-center justify-content-start w-100 gap-4 px-10">
                                {/* Country Select */}
                                <div className="d-flex flex-column">
                                    <div className="mb-4 dropdown">
                                        <button
                                            className="btn btn-color-muted btn-active btn-active-light-primary dropdown-toggle text-start form-control form-control-sm form-control-solid fw-bold"
                                            type="button"
                                            id="countryDropdown"
                                            onClick={() => toggleCountryDropdown(!countryDropdownOpen)}
                                            style={{
                                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                                width: 'auto', // Ensures the button has auto width
                                            }}
                                        >
                                            Select up to 5 Countries
                                        </button>
                                        {countryDropdownOpen && (
                                            <ul
                                                className="dropdown-menu show mt-1"
                                                style={{
                                                    maxWidth: 'none', // Allows dropdown to expand to fit content
                                                    minWidth: '200px', // Sets a minimum width for consistency
                                                    maxHeight: 'auto', // Allows dropdown to grow
                                                    overflowY: 'auto', // Adds scroll when necessary
                                                    borderRadius: '8px',
                                                    borderColor: '#e5e5e5',
                                                }}
                                                aria-labelledby="countryDropdown"
                                            >
                                                {countryOptions.map((country, index) => (
                                                    <li key={index} style={{ margin: '10px 0', width: '100%' }}>
                                                        <input
                                                            className="form-check-input me-2 mb-2 ms-3"
                                                            type="checkbox"
                                                            id={`country-${index}`}
                                                            value={country.value}
                                                            checked={selectedCountries.some(
                                                                (selected) => selected.value === country.value
                                                            )}
                                                            onChange={(e) => {
                                                                const updatedSelection = e.target.checked
                                                                    ? [...selectedCountries, country]
                                                                    : selectedCountries.filter(
                                                                        (selected) => selected.value !== country.value
                                                                    );
                                                                if (updatedSelection.length <= 5) {
                                                                    handleChange(updatedSelection);
                                                                }
                                                                else {
                                                                    setAlert({ message: 'You can only select up to 5 countries.', type: 'danger' });
                                                                }
                                                            }}
                                                        />
                                                        <label
                                                            className="form-label fw-bold ms-2"
                                                            htmlFor={`country-${index}`}
                                                            style={{
                                                                fontSize: '1.1rem',
                                                                display: 'inline-block', // Ensures label stays in the same line as checkbox
                                                                wordBreak: 'break-word', // Ensures text breaks if it's too long
                                                                whiteSpace: 'normal', // Allows text to wrap
                                                                maxWidth: 'calc(80%)', // Adjusts max width to prevent overflow
                                                            }}
                                                        >
                                                            {country.label}
                                                        </label>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}

                                    </div>
                                </div>

                                {/* Topic Select */}
                                <div className="d-flex flex-column">
                                    <div className="mb-4 dropdown">
                                        <button
                                            className="btn btn-color-muted btn-active btn-active-light-primary dropdown-toggle text-start form-control form-control-sm form-control-solid fw-bold"
                                            type="button"
                                            id="topicDropdown"
                                            onClick={() => toggleTopicDropdown(!topicDropdownOpen)}
                                            style={{
                                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                                width: 'auto', // Ensures button adjusts width based on content
                                            }}
                                        >
                                            Select Topics to Compare
                                        </button>

                                        {topicDropdownOpen && (
                                            <ul
                                                className="dropdown-menu show mt-1"
                                                style={{
                                                    minWidth: '280px', // Sets a minimum width for consistency
                                                    maxHeight: 'auto', // Lets the dropdown grow
                                                    overflowY: 'auto', // Adds scrolling for long content
                                                    borderRadius: '8px',
                                                    borderColor: '#e5e5e5',
                                                }}
                                                aria-labelledby="topicDropdown"
                                            >
                                                {/* All Option */}
                                                <li key="all" style={{ margin: '10px 0', width: '100%' }}>
                                                    <input
                                                        className="form-check-input me-2 mb-2 ms-6"
                                                        type="checkbox"
                                                        id="all-topics"
                                                        checked={selectedRows.length === rowsOptions.length} // Check if all rows are selected
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                setSelectedRows(rowsOptions); // Select all topics
                                                            } else {
                                                                setSelectedRows([]); // Deselect all topics
                                                            }
                                                        }}
                                                    />
                                                    <label
                                                        className="form-label fw-bold ms-2"
                                                        htmlFor="all-topics"
                                                        style={{
                                                            fontSize: '1.1rem',
                                                            display: 'inline-block',
                                                            wordBreak: 'break-word',
                                                            whiteSpace: 'normal',
                                                            maxWidth: 'calc(100% - 30px)', // Adjust width to prevent overflow
                                                        }}
                                                    >
                                                        ALL
                                                    </label>
                                                </li>
                                                {rowsOptions.map((row, index) => (
                                                    <li key={index} style={{ margin: '10px 0', width: '100%' }}>
                                                        <input
                                                            className="form-check-input me-2 mb-2 ms-6"
                                                            type="checkbox"
                                                            id={`row-${index}`}
                                                            value={row.value}
                                                            checked={selectedRows.some(
                                                                (selected) => selected.value === row.value
                                                            )}
                                                            onChange={(e) => {
                                                                const updatedSelection = e.target.checked
                                                                    ? [...selectedRows, row]
                                                                    : selectedRows.filter(
                                                                        (selected) => selected.value !== row.value
                                                                    );
                                                                setSelectedRows(updatedSelection);
                                                            }}
                                                        />
                                                        <label
                                                            className="form-label fw-bold ms-2"
                                                            htmlFor={`row-${index}`}
                                                            style={{
                                                                fontSize: '1.1rem',
                                                                display: 'inline-block', // Make label behave inline
                                                                wordBreak: 'break-word', // Ensures the text breaks if it's too long
                                                                whiteSpace: 'normal',    // Allows text wrapping within the container
                                                                maxWidth: 'calc(80%)', // Ensures label does not overflow
                                                            }}
                                                        >
                                                            {row.label}
                                                        </label>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}



                                    </div>
                                </div>

                                {/* Compare Button */}
                                <div className="d-flex flex-column justify-content-center align-items-center mb-4">
                                    <button
                                        className="btn btn-primary fw-bold"
                                        onClick={() => console.log(selectedCountries, selectedRows)}
                                        disabled={selectedCountries.length === 0 || selectedRows.length === 0}
                                    >
                                        Generate Results
                                    </button>
                                </div>
                            </div>
                        </div>

                        {alert.message && (
                            <div className={`alert alert-${alert.type} alert-dismissible fade show mt-3 mx-11`} role="alert">
                                {alert.message}
                                <button type="button" className="btn-close" onClick={() => setAlert({ message: '', type: '' })} aria-label="Close"></button>
                            </div>
                        )}
                        <div className='table-responsive px-5 mt-3 mb-10'>
                            <table
                                className="table table-striped table-hover table-rounded border gy-5 gs-7 align-middle dataTable no-footer"
                                style={{
                                    '--bs-table-hover-bg': 'rgba(204, 229, 255, 1)',
                                    '--bs-table-striped-bg': 'rgba(230, 242, 255, 1)',
                                    tableLayout: 'fixed',
                                }}
                            >
                                {/* Table Head */}
                                <thead className="text-start text-muted fw-bolder fs-7 gs-0">
                                    <tr className='fw-bold fs-5 text-gray-800 border-bottom-3 border-gray-200'>
                                        {/* First Column - Topics */}
                                        <th style={{ whiteSpace: 'nowrap', width: '20%', paddingRight: '20px' }}>Topics</th>
                                        {/* Dynamically render columns for each selected country */}
                                        {selectedCountries.length === 0 ? (
                                            <th colSpan={1} className='text-dark fw-semibold fs-5' style={{ textAlign: 'center' }}>
                                                Countries
                                            </th>
                                        ) :
                                            selectedCountries.map((country, index) => (
                                                <th
                                                    key={index}
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        textAlign: 'center',
                                                        width: `${100 / selectedCountries.length}%`,
                                                        paddingRight: '20px',
                                                    }}
                                                >
                                                    {country.label}
                                                </th>
                                            ))}
                                    </tr>
                                </thead>

                                {/* Table Body */}
                                <tbody>
                                    {/* Dynamically render rows for each selected row (topic) */}
                                    {selectedRows.length === 0 || selectedCountries.length === 0 ? (
                                        <tr>
                                            <td colSpan={selectedCountries.length + 2} className='text-dark fw-semibold fs-5' style={{ textAlign: 'center' }}>
                                                No records found. Please select at least one country and one topic to get started.
                                            </td>
                                        </tr>
                                    ) : (
                                        selectedRows && !isLoading && selectedRows.map((row, rowIndex) => (
                                            <tr key={rowIndex} className='align-items-center'>
                                                {/* First column - Row Title */}
                                                <td className='text-dark fw-semibold fs-6'>{row.label}</td>
                                                {/* Render data for each country in this row */}
                                                {selectedCountries.map((country, countryIndex) => (
                                                    <td
                                                        key={`${rowIndex}-${countryIndex}`}
                                                        className="text-center text-dark text-wrap fw-semibold pe-5 fs-6"
                                                    >
                                                        {/* Example: Replace with your actual data fetching */}
                                                        {``}
                                                    </td>
                                                ))}
                                            </tr>
                                        )))}

                                    {/* If no data is selected */}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default CountryCompare;
