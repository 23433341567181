import React from 'react';
import SearchBar from '../../../components/SearchBar';
import DocumentFilter from './DocumentFilter';
import SortFilterWrapper from '../../../components/SortFilterWrapper';
import ItemsPerPageWrapper from '../../../components/ItemsPerPageWrapper';
import { KTIcon } from '../../../../_metronic/helpers';

const DocumentToolbar = ({
    filters,
    setFilters,
    searchInput,
    setSearchInput,
    placeholder,
    docType,
    handleApplyFilters,
    handleResetFilters,
    sortConfig,
    setSortConfig,
    onApplySort,
    onResetSort,
    sortDirection,
    setSortDirection,
    itemsPerPage,
    setItemsPerPage
}) => {
    let sortFields = []
    let filterConfig = []
    if (docType === 'warning-letters') {
        sortFields = [['Company Name', 'company_name'], ['Subject', 'subject'],
        ['Issuing Office', 'issuing_office'], ['Posted Date', 'posted_date'], ['Letter Issue Date', 'letter_issue_date']]
        filterConfig = [
            {
                id: 'response',
                type: 'checkbox',
                label: 'Contains Response Letter:',
            },
            {
                id: 'closeout',
                type: 'checkbox',
                label: 'Contains Closeout Letter:',
            },
            {
                id: 'startDate',
                type: 'date',
                label: 'Posted Date Range:',
            },
            {
                id: 'endDate',
                type: 'date',
                label: '',
            },
        ];

    }
    else if (docType === 'debarment') {
        sortFields = [['Name', 'document_title'], ['Program', 'program'], ['Posted Date', 'postedDate'], ['Effective Date', 'effective_date']]
        filterConfig = [
            {
                id: 'name',
                type: 'text',
                label: 'Name:',
                placeholder: 'Name',
            },
            {
                id: 'agency',
                type: 'text',
                label: 'Agency:',
                placeholder: 'CMS, FDA, etc.',
            },
            {
                id: 'program',
                type: 'text',
                label: 'Program:',
                placeholder: 'CDER, CDRH, etc.',
            },
        ];

    }
    else if (docType === 'international') {
        sortFields = [['Publish Date', 'publish_date']]
    }
    else {
        sortFields = [['ID', 'id'], ['Title', 'document_title'], ['Posted Date', 'postedDate'], ['Comment Start Date', 'commentStartDate'], ['Comment End Date', 'commentEndDate']]
        filterConfig = [
            ...(docType !== 'notices' ? [
                {
                    id: 'tracked',
                    type: 'checkbox',
                    label: 'Tracked:',
                },
                {
                    id: 'genAI',
                    type: 'checkbox',
                    label: 'AI Enabled:',
                }
            ] : []),

            {
                id: 'agency',
                type: 'text',
                label: 'Agency:',
                placeholder: 'CMS, FDA, etc.',
            },
            {
                id: 'program',
                type: 'text',
                label: 'FDA Program:',
                placeholder: 'CDER, CDRH, etc.',
            },
            {
                id: 'startDate',
                type: 'date',
                label: 'Comment Date Range:',
            },
            {
                id: 'endDate',
                type: 'date',
                label: '',
            },
        ];

    }

    return (
        <div className={`d-flex flex-wrap gap-4 flex-row-fluid align-items-center px-8 mb-3 mt-2`}>
            <div className="col-7">
                <SearchBar
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    placeholder={placeholder ? placeholder : `Search ${docType === 'final-rules' ? 'Final Rules'
                        : docType === 'proposed-rules' ? 'Proposed Rules'
                            : docType === 'guidances' ? 'Guidances'
                                : docType === 'notices' ? 'Notices'
                                    : docType === 'debarment' ? 'Debarments'
                                        : 'Documents'}`}
                />
            </div>
            {docType !== 'international' && (
                <div className="d-flex flex-column-auto d-flex align-items-center ml-auto">
                    <button
                        type='button'
                        className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                    >
                        <KTIcon iconName='filter' className='fs-2' /> Filter
                    </button>
                    <DocumentFilter
                        filters={filters}
                        setFilters={setFilters}
                        onApplyFilters={handleApplyFilters}
                        onResetFilters={handleResetFilters}
                        filterConfig={filterConfig}
                    />
                </div>
            )}
            <div className="d-flex flex-column-auto d-flex align-items-center py-1">
                <button
                    type='button'
                    className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                >
                    <KTIcon iconName={sortDirection === 'asc' ? 'arrow-up' : 'arrow-down'} iconType="solid" className='fs-2' /> Sort
                </button>
                <SortFilterWrapper
                    sortFields={sortFields}
                    sortConfig={sortConfig}
                    setSortConfig={setSortConfig}
                    onApplySort={onApplySort}
                    onResetSort={onResetSort}
                    setSortDirection={setSortDirection}
                />
            </div>
            <>
                <ItemsPerPageWrapper itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} bottomSpace={0} />
            </>
        </div>
    );
};

export default DocumentToolbar;
