/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect, useCallback, memo, useRef, useLayoutEffect } from 'react'
import { useIntl } from 'react-intl'
// import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import axios from 'axios';
import { useAuth } from '../../modules/auth'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { ErrorBoundary } from "react-error-boundary";
import CurrentActivityCharts from './CurrentActivityCharts'
import HistoricalActivityCharts from './HistoricalActivityCharts';

const DashboardPage: FC = () => {
  const { period } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [retry, setRetry] = useState(0);
  const [data, setData] = useState([]);
  const [hasError, setHasError] = useState(false);
  // const [impDates, setImpDates] = useState<DataItem[]>([]);
  const { logout } = useAuth();
  const colors = ['#0000CD', '#FF8C00', '#A9A9A9', '#FFD700', '#1E90FF'];
  const currentRef = useRef<HTMLDivElement>(null);
  const historicalRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(true);
  // console.log("period", period)

  const fetchData = useCallback(() => {
    setLoading(true);
    axios.get(`/reg/v1/home/insights/documents`)
      .then(response => {
        const sortedData = response.data;
        setData(sortedData);
        setLoading(false);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          logout();
          navigate('/auth/login')
        }
        setLoading(false);
        console.error('There was an error!', error);
      });

  }, [logout, navigate]);

  // useEffect(() => {
  //   setLoading(true);
  //   const observer = new MutationObserver(() => {
  //     if (historicalRef.current) {
  //       setHistoricalPosition(historicalRef.current.getBoundingClientRect().top + window.pageYOffset - 60);
  //     }
  //   });

  //   if (historicalRef.current) {
  //     observer.observe(historicalRef.current, { attributes: true, childList: true, subtree: true });
  //   }
  //   setLoading(false);
  //   return () => observer.disconnect();
  // }, []);

  useLayoutEffect(() => {
    setLoading(true);
    if (data.length > 0) {
      if (period === 'current') {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      if (period === 'historical' && historicalRef.current !== null) {
        if (historicalRef.current !== null) {
          const newHistoricalPosition = historicalRef.current.getBoundingClientRect().top + window.pageYOffset - 60;
          window.scrollTo({ top: newHistoricalPosition, behavior: 'smooth' });
        }
      }
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, location.pathname, data, historicalRef.current]);

  useEffect(() => {
    setHasError(false);
    Promise.all([
      fetchData()
    ]).then(() => { }).catch((error) => {
      setHasError(error.message);
    });
  }, [fetchData, retry, period]);

  if (hasError) {
    return (
      <div>
        <div className="alert alert-danger" role="alert">{hasError}</div>
        <div><button type="button" className="btn btn-primary" onClick={() => setRetry(retry + 1)}>Try again</button></div>
      </div>

    );
  }
  return (
    <ErrorBoundary fallback={<div>
      <div className="alert alert-danger" role="alert">Something went wrong!</div>
      <div><button type="button" className="btn btn-primary" onClick={() => setRetry(retry + 1)}>Try again</button></div>
    </div>}>

      <>

        {/* <ImportantDatesTable impDates={impDates} /> */}

        {/* <div style={{ width: '50%', height: '1px', backgroundColor: '#ccc', margin: 'auto' }}></div> */}

        <div ref={currentRef}>
          <CurrentActivityCharts loading={loading} colors={colors} data={data} />
        </div>
        <div ref={historicalRef}>
          <div style={{ width: '50%', height: '1px', backgroundColor: '#ccc', margin: 'auto' }}></div>


          <HistoricalActivityCharts loading={loading} colors={colors} data={data} />
        </div>
      </>

    </ErrorBoundary>
  );


};
const MemoizedDashboardPage = memo(DashboardPage);

const RegulatoryInsights: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>

      <MemoizedDashboardPage />

    </>
  )
}

export { RegulatoryInsights }
