import React from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';

const CreateFolderModal = ({
    showModal,
    setShowModal,
    orgS3Path,
    selectedFolder,
    currentUser,
    fetchFiles,
    setModalMessage,
    setShowCreateSuccessModal,
    isLoading,
}) => {
    const [folderName, setFolderName] = React.useState('');

    const closeModal = () => {
        setShowModal(false);
        setFolderName('');
    };

    const handleCreate = () => {
        handleNewFolder(folderName);
        closeModal();
    };

    const handleNewFolder = async (folderName: string) => {
        if (!folderName.trim()) {
            alert('Folder name cannot be empty.');
            return;
        }

        const response = await fetch(`${process.env.REACT_APP_DEEP_API_URL as string}/create-folder`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ folder_name: folderName, vault_s3_link: orgS3Path })
        })

        // const response = await fetch(`https://deepapi.lexim.ai/create-folder`, {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify({ folder_name: folderName, vault_s3_link: orgS3Path })
        // })

        if (response && response.status === 200) {
            const parent_id = selectedFolder ? Object.values(selectedFolder)[0] : null;

            axios.post(`/reg/v1/document/folder/${currentUser?.organization}/${currentUser?.id}`, { folder_name: folderName, parent_id: parent_id })
                .then(response => {
                    console.log("response:", response);
                    fetchFiles();
                    setModalMessage(`Folder created successfully! \n ${folderName}`);
                    setShowCreateSuccessModal(true);
                })
                .catch(error => {

                    setModalMessage(`Failed to create folder: ${folderName} \n\n Reason: ${error.response.data[0].message}`);
                    setShowCreateSuccessModal(true);
                    console.error('There was an error!', error);
                });
        }
        else {
            setModalMessage(`Failed to create folder: ${folderName} \n\n Reason: ${response.statusText}`);
            setShowCreateSuccessModal(true);
            console.log("response:", response);
        }

    };

    return (
        <Modal show={showModal} onHide={closeModal} dialogClassName="modal-dialog-centered">
            <Modal.Header closeButton>
                <Modal.Title>Create New Folder</Modal.Title>
            </Modal.Header>

            <div className="form-group px-10 py-7">
                <input
                    type="text"
                    className="form-control"
                    id="folderName"
                    value={folderName}
                    onChange={(e) => setFolderName(e.target.value)}
                    placeholder="Folder Name"
                />
            </div>

            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={closeModal}>
                    Close
                </button>
                <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isLoading || folderName === ''}
                    onClick={handleCreate}
                >
                    {!isLoading ? 'Create Folder' : (
                        <span className="indicator-progress" style={{ display: 'block' }}>
                            Please wait...{' '}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    )}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateFolderModal;
