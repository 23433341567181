import React, { useState, useEffect } from 'react';
import { KTIcon } from '../../_metronic/helpers';

const SearchBar = ({
    searchInput,
    setSearchInput,
    placeholder,
    dropdownAdded = false,
    debounceDelay = 500
}) => {
    const [tempSearchInput, setTempSearchInput] = useState(searchInput);

    useEffect(() => {
        if (tempSearchInput === '') {
            setSearchInput('');
        }
    }, [tempSearchInput, setSearchInput]);


    // Debounce function
    // const debounce = (fn, delay) => {
    //     let timeout;
    //     return (...args) => {
    //         if (timeout) {
    //             clearTimeout(timeout);
    //         }
    //         timeout = setTimeout(() => {
    //             fn(...args);
    //         }, delay);
    //     };
    // };

    // Debounced version of setting searchInput
    // const debouncedSearch = debounce((value) => {
    //     setSearchInput(value);
    // }, debounceDelay);


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            setSearchInput(tempSearchInput); // Update searchInput on Enter key
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setTempSearchInput(value);
        // if (dropDownAdded) {
        //     debouncedSearch(value); // Call the debounced search function
        // }
    };

    return (
        <div className="flex-column-auto align-items-center">
            <div className="d-flex align-items-center position-relative my-1">
                <KTIcon iconName="magnifier" className="fs-2 position-absolute ms-6" />
                <input
                    type="search"
                    id="form1"
                    className="form-control form-control-solid w-100 ps-14 border border-1"
                    placeholder={placeholder}
                    aria-label={placeholder}
                    value={tempSearchInput}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    maxLength={200}
                    style={{ backgroundColor: '#f1faff' }}
                />
            </div>
        </div>
    );
};

export default SearchBar;
