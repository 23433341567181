// CurrentActivityCharts.tsx
import React, { useState } from 'react';
import { KTIcon } from '../../../_metronic/helpers';
import {
    MixedWidget9,
    MixedWidget11
} from '../../../_metronic/partials/widgets'
import HourGlass from '../Loading/HourGlassSpinner/HourGlass';

const CurrentActivityCharts = ({ loading, colors, data }) => {
    const [activity, setActivity] = useState('month');

    return (
        <div className="pb-5">
            <div className="pb-8 text-center">
                <h1 className="d-flex align-items-center justify-content-center">
                    <span style={{ position: 'relative', top: '2px' }}>
                        <KTIcon iconName='chart-simple' className='fs-1 me-2' />
                    </span>
                    Current Activity (Past
                    <select
                        className="form-select form-select-solid border border-1 d-inline-block w-auto ms-2 me-2 pt-2 pb-2"
                        value={activity}
                        onChange={(e) => setActivity(e.target.value)}
                    >
                        <option value="month">30</option>
                        <option value="quarter">90</option>
                        <option value="6month">180</option>
                        <option value="year">365</option>
                    </select>
                    Days)
                    {/* {(!(data.length > 0) || loading) && (
                        <div className="spinner-border text-primary ms-3" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    )} */}
                </h1>
            </div>

            {(data.length === 0 || loading) ? (
                <HourGlass height="700px" />
            ) : (
                <div>
                    <div className='row g-5 gx-xxl-14 justify-content-center mb-4 px-15'>
                        <div className='col-xxl-4 pe-5'>
                            <MixedWidget11
                                className='card-xxl-stretch mb-xl-3'
                                chartColor={colors}
                                chartHeight='225px'
                                data={data}
                                title="Total Documents By Agency"
                                columns={{
                                    "Total Documents": "doc_total"
                                }}
                                activity={activity}
                                scale={activity === 'month' ? 'linear' : 'logarithmic'}
                            />
                        </div>
                        <div className='col-xxl-4 pe-5'>
                            <MixedWidget11
                                className='card-xxl-stretch mb-xl-3'
                                chartColor={colors}
                                chartHeight='225px'
                                data={data}
                                title="Total Pages & Document Attachments"
                                columns={{
                                    "Total Pages": "pages_total",
                                    "Document Attachments": "doc_attach_total"
                                }}
                                activity={activity}
                                scale={activity === 'month' ? 'linear' : 'logarithmic'}
                            />
                        </div>
                        <div className='col-xxl-4 pe-5'>
                            <MixedWidget11
                                className='card-xxl-stretch mb-xl-3'
                                chartColor={colors}
                                chartHeight='225px'
                                data={data}
                                title="Documents By Type"
                                columns={{
                                    "Proposed Rules": "proposed_total",
                                    "Final Rules": "rule_total",
                                    "Notices": "notice_total",
                                    "Supporting": "supporting_total",
                                    "Other": "other_total"
                                }}
                                activity={activity}
                            />
                        </div>
                    </div>
                    <div className='row g-5 gx-xxl-14 justify-content-center mb-6 px-10'>
                        {data.some(item => item.agency === "CMS" && item.period_code === activity) &&
                            <div className='col-xxl-5 me-5'>
                                <MixedWidget9
                                    className='card-xxl-stretch mb-xl-3'
                                    chartColor={colors}
                                    chartHeight='300px'
                                    data={data}
                                    title="Documents By Type"
                                    columns={{
                                        "Proposed Rules": "proposed_total",
                                        "Final Rules": "rule_total",
                                        "Notices": "notice_total",
                                        "Supporting Materials": "supporting_total",
                                        "Other": "other_total"
                                    }}
                                    agency={"CMS"}
                                    activity={activity}
                                />
                            </div>
                        }
                        <div className='col-xxl-5 ms-5'>
                            <MixedWidget9
                                className='card-xxl-stretch mb-xl-3'
                                chartColor={colors}
                                chartHeight='300px'
                                data={data}
                                title="Documents By Type"
                                columns={{
                                    "Proposed Rules": "proposed_total",
                                    "Final Rules": "rule_total",
                                    "Notices": "notice_total",
                                    "Supporting Materials": "supporting_total",
                                    "Other": "other_total"
                                }}
                                agency={"FDA"}
                                activity={activity}
                            />
                        </div>
                    </div>
                    <div className='row g-5 gx-xxl-14 justify-content-center mb-5 px-15'>
                        <div className='col-xxl-4 pe-5'>
                            <MixedWidget11
                                className='card-xxl-stretch mb-xl-3'
                                chartColor={colors}
                                chartHeight='200px'
                                data={data}
                                title="Total Comments & Attachments"
                                columns={{
                                    "Total Comments": "comment_total",
                                    "Comment Attachments": "comment_attach_total"
                                }}
                                activity={activity}
                                scale={activity === 'month' ? 'linear' : 'logarithmic'}
                            />
                        </div>
                        {data.some(item => item.agency === "CMS" && item.period_code === activity) &&
                            <div className='col-xxl-4 pe-5'>
                                <MixedWidget9
                                    className='card-xxl-stretch mb-xl-3'
                                    chartColor={colors}
                                    chartHeight='305px'
                                    data={data}
                                    title="Comments By Type"
                                    columns={{
                                        "Anonymous": "anon_total",
                                        "Organization": "org_total",
                                        "Individual": "individual_total",
                                    }}
                                    agency={"CMS"}
                                    activity={activity}
                                />
                            </div>
                        }
                        <div className='col-xxl-4 pe-5'>
                            <MixedWidget9
                                className='card-xxl-stretch mb-xl-3'
                                chartColor={colors}
                                chartHeight='305px'
                                data={data}
                                title="Comments By Type"
                                columns={{
                                    "Anonymous": "anon_total",
                                    "Organization": "org_total",
                                    "Individual": "individual_total",
                                }}
                                agency={"FDA"}
                                activity={activity}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CurrentActivityCharts;