/* eslint-disable no-unused-vars */
import { Link, useParams } from 'react-router-dom';
import { KTIcon } from '../../../../_metronic/helpers';
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
// import DocumentDetailsCard from './DocumentDetailsCard';
// import { Tab, Tabs } from 'react-bootstrap';
import FullWindow from '../../PDFViewer/FullWindow';
// import TextViewer from './TextViewer';
// import SummaryViewer from './SummaryViewer';
import FAQViewer from './FAQViewer';
import ImpactViewer from './ImpactViewer';
// import KeywordsViewer from './KeywordsViewer';
import ActionItemsViewer from './ActionItemsViewer';
import { useAuth } from '../../../modules/auth';
import { useNavigate } from 'react-router-dom';
// import DiscussionViewer from './DiscussionViewer';
// import VideoViewer from './VideoViewer';
import Comments from '../../Comments/Comments';
import HalfWindow from '../HalfPDFViewer/HalfWindow';
import { QAInterface } from './QAInterface'; // Import the QAInterface component
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import SuggestedDocuments from './SuggestedDocuments';
// import { useRef } from 'react';
import BriefViewer from './BriefViewer';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
// import { useSelector } from 'react-redux';

const INDocumentDetails = () => {
    const { id } = useParams();
    //const [fileText, setFileText] = useState('');
    const [document, setDocument] = useState([]);
    const location = useLocation();
    const activeTab = location.state ? location.state.activeTab : null;
    const searchInput = location.state ? location.state.searchInput : '';
    const currentPage = location.state ? location.state.currentPage : 1;
    const givenCountry = location.state ? location.state.givenCountry : '';
    const myInternational = location.state ? location.state.myInternational : false;
    const [tab, setTab] = useState(activeTab || 'Document');
    const navigate = useNavigate();
    const { currentUser, logout } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [trackedDocuments, setTrackedDocuments] = useState([]);

    console.log(givenCountry)

    // const [trackedDocuments, setTrackedDocuments] = useState([]);
    // const [summaryLikes, setSummaryLikes] = useState(0);
    // const [summaryDislikes, setSummaryDislikes] = useState(0);
    // const [userSummaryRating, setUserSummaryRating] = useState(0);

    // const [actionLikes, setActionLikes] = useState(0);
    // const [actionDislikes, setActionDislikes] = useState(0);
    // const [userActionRating, setUserActionRating] = useState(0);

    // const [faqLikes, setFAQLikes] = useState(0);
    // const [faqDislikes, setFAQDislikes] = useState(0);
    // const [userFAQRating, setUserFAQRating] = useState(0);

    // const [impactLikes, setImpactLikes] = useState(0);
    // const [impactDislikes, setImpactDislikes] = useState(0);
    // const [userImpactRating, setUserImpactRating] = useState(0);

    // const [ratingLoad, setratingLoad] = useState(true);
    // const [countLoad, setCountLoad] = useState(true);
    const [leftSize, setLeftSize] = useState(6);
    const [rightSize, setRightSize] = useState(6);
    // const [trackedDocuments, setTrackedDocuments] = useState([]);
    // const conciseSummaryRef = useRef(null);
    // const detailedSummaryRef = useRef(null);
    // const [arrowToggleDirection, setArrowToggleDirection] = useState('left');

    // const addons = useSelector(state => state.account.addons);

    useEffect(() => {
        const fetchDocument = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`/reg/v1/document/international_details/${id}`);

                setDocument(response.data[0]);

                //fetchComments(response.data[0].iddocument); 
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    logout();
                    navigate('/auth/login');
                } else {
                    console.log('Error fetching document:', error);
                }
            }
            setIsLoading(false);
        };
        fetchDocument();
    }, [id, logout, navigate]);

    const fetchInTrackedDocuments = useCallback(async () => {

        try {
            const response = await axios.get(`/reg/v1/document/user/international/${currentUser?.id}`);
            setTrackedDocuments(response.data);
            return response.data;

        } catch (error) {
            console.log('Failed to fetch tracked documents', error);
            return [];
        }
    }, [currentUser?.id]);

    const handleTrackInDocument = (id) => {
        // setLoading(true);
        axios.post(`/reg/v1/document/international/track/${currentUser?.id}/${id}`)
            .then((response) => {
                //setAlert({ message: response.data.message, type: 'success' });
                // fetchTrackedDocuments(); // Fetch tracked documents again after tracking a new document
                setTrackedDocuments(prevState => {

                    const newState = prevState.map(doc => {
                        if (doc.iddocument === id) {
                            // console.log('Toggling track for document:', doc);
                            return { ...doc, track: doc.track === 1 ? 0 : 1 };
                        } else {
                            return doc;
                        }
                    });
                    return newState;
                });

                fetchInTrackedDocuments();

                // setLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    logout();
                    navigate('/auth/login');
                } else {
                    console.error('Error tracking document:', error);
                }
                // setLoading(false);
            });
    }

    useEffect(() => {
        fetchInTrackedDocuments();
    }, [fetchInTrackedDocuments]);

    const moment = require('moment-timezone');

    const formatDate1 = useCallback((timestamp) => {
        if (timestamp === null) {
            return null;
        }
        const date = moment.utc(timestamp); // Use UTC
        return date.format('YYYY-MM-DD'); // Format in UTC
    }, [moment]);

    // const formatDate2 = useCallback((timestamp) => {
    //     if (isNaN(Date.parse(timestamp))) {
    //         return null;
    //     }
    //     const date = moment.utc(timestamp); // Use UTC
    //     return date.format('YYYY-MM-DD'); // Format in UTC
    // }, [moment]);

    // const toggleColumnSize = () => {
    //     // Toggle the sizes between 4 and 8 (or any other values)
    //     if (leftSize === 6) {
    //         setLeftSize(0);
    //         setRightSize(12);
    //         setArrowToggleDirection('right'); // Change arrow direction when sizes change
    //     } else {
    //         setLeftSize(6);
    //         setRightSize(6);
    //         setArrowToggleDirection('left'); // Change arrow direction back
    //     }
    // };

    // useEffect(() => {
    //     setratingLoad(true);
    //     const docId = id;
    //     const userId = currentUser?.id;
    //     axios.get(`/reg/v1/user/document/rating/${userId}/${docId}/action`)
    //         .then(response => {
    //             const data = response.data[0];
    //             // console.log("get user rating", data)
    //             if (data) {
    //                 setUserSummaryRating(data ? data.summary : 0);
    //                 setUserActionRating(data ? data.action : 0);
    //                 setUserFAQRating(data ? data.faq : 0);
    //                 setUserImpactRating(data ? data.impact : 0);
    //             }
    //             setratingLoad(false);
    //         })
    //         .catch(error => {
    //             console.error('Failed to fetch user document rating:', error);
    //             setratingLoad(false);
    //         });
    // }, [currentUser?.id, id]);

    // useEffect(() => {
    //     setCountLoad(true);
    //     const docId = id;
    //     axios.get(`/reg/v1/document/rating/${docId}`)
    //         .then(response => {
    //             const data = response.data[0];
    //             // console.log("get document count", data)
    //             setSummaryLikes(data.summary_total_pos);
    //             setSummaryDislikes(data.summary_total_neg);
    //             setActionLikes(data.action_total_pos);
    //             setActionDislikes(data.action_total_neg);
    //             setFAQLikes(data.faq_total_pos);
    //             setFAQDislikes(data.faq_total_neg);
    //             setImpactLikes(data.impact_total_pos);
    //             setImpactDislikes(data.impact_total_neg);
    //             setCountLoad(false);
    //         })
    //         .catch(error => {
    //             console.error('Failed to fetch document ratings:', error);
    //             setCountLoad(false);
    //         });
    // }, [id]);



    // const handleScrollToDetailedSummary = () => {
    //     const detailedSummary = detailedSummaryRef.current;
    //     if (detailedSummary) {
    //         window.scrollTo({
    //             top: detailedSummary.offsetTop + 275,  // adjust offset if needed
    //             behavior: 'smooth'
    //         });
    //     }
    // };

    // const handleScrollToConciseSummary = () => {
    //     const conciseSummary = conciseSummaryRef.current;
    //     if (conciseSummary) {
    //         window.scrollTo({
    //             top: conciseSummary.offsetTop + 200,  // adjust offset if needed
    //             behavior: 'smooth'
    //         });
    //     }
    // };

    return (
        <div>
            {!isLoading &&
                <div className="ms-1 mt-n2 pb-2" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', maxWidth: '100%' }}>
                    <div style={{ marginRight: '20px', width: '400px' }}>
                        <Link
                            // to={`/${document.country.toLowerCase().replace(/\s+/g, '-')}`
                            // }
                            to={!myInternational ? `/${document.country.toLowerCase().replace(/\s+/g, '-')}` : `/myitems/international`}
                            state={{ currentPage: currentPage, searchInput: searchInput, givenCountry: givenCountry }}
                            style={{
                                color: '#0d6efd',
                                textDecoration: 'none',
                                fontSize: '1.3em',
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                            onMouseEnter={(e) => e.target.style.color = '#0d6efd'}
                            onMouseLeave={(e) => e.target.style.color = '#0d6efd'}
                        >
                            <KTIcon iconName='arrow-left' className='fs-2 me-2' style={{ marginRight: '10px' }} />
                            {
                                !myInternational ? document.country : 'My International'
                            }
                            {' '} List
                        </Link>
                    </div>
                </div>
            }

            <div className='card mt-3 mb-4' style={{}}>
                <div className='card-body d-flex align-items-center '>
                    <div className='position-relative flex-wrap w-100'>
                        <div>
                            {/* {!isLoading && <h4 className='text-gray-800 fw-bold mb-3'>Document: {document.title}</h4>} */}
                            {(isLoading) && (
                                <div className="d-flex align-items-center">
                                    <div>Document Loading...</div>
                                    <div className="spinner-border spinner-border-sm ms-3 mb-1" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            )}
                            {!isLoading &&
                                <>
                                    {/* <div className="d-flex justify-content-between"> */}
                                    <div className="d-flex align-items-center mb-1 w-100">
                                        <span className={`badge card-title fs-6 ${document?.documentType === "Guidance" ? 'badge-warning' : 'badge-danger'}`}>
                                            {document?.documentType}
                                        </span>
                                        {/* {document?.documentType === "Proposed Rule" && (
                                            <span className={`badge card-title fs-6 ms-2 badge-danger`}>
                                                {document?.subtype}
                                            </span>
                                        )} */}
                                        <div className='ms-auto me-2 mb-2'>{trackedDocuments.some(trackedDocument => trackedDocument.id === document.id && trackedDocument.track === 1) ? (
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="button-tooltip-2">Remove from My Items</Tooltip>}
                                            >
                                                {({ ref, ...triggerHandler }) => (
                                                    <button
                                                        {...triggerHandler}
                                                        className="btn h-25px w-5px btn-sm d-flex align-items-center justify-content-center"
                                                        onClick={() => handleTrackInDocument(document.id)}
                                                    >
                                                        <i ref={ref} className="bi bi-bookmark-fill fs-1 ms-6" style={{ color: '#007bff' }}></i>
                                                    </button>
                                                )}
                                            </OverlayTrigger>
                                        ) : (
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="button-tooltip-2">Add to My Items</Tooltip>}
                                            >
                                                {({ ref, ...triggerHandler }) => (
                                                    <button
                                                        {...triggerHandler}
                                                        className="btn h-25px w-5px btn-sm d-flex align-items-center justify-content-center"
                                                        onClick={() => handleTrackInDocument(document.id)}
                                                    >
                                                        <i ref={ref} className="bi bi-bookmark fs-1 ms-6" style={{ color: '#008000' }}></i>
                                                    </button>
                                                )}
                                            </OverlayTrigger>
                                        )}</div>
                                    </div>
                                    {/* </div> */}
                                    <div className="d-flex align-items-center  mb-1">
                                        <h4 className='text-gray-800 fw-bold mb-2'>{document.title}</h4>

                                    </div>
                                    {/* <div className="d-flex align-items-center  mb-1">
                                        <h5 className="card-title text-gray-600  " >Abstract: <span className="">{document?.abstract ? document.abstract : "No abstract available."}</span></h5>

                                    </div> */}
                                    <div className="d-flex align-items-center">
                                        {document.publish_date !== null &&
                                            <>
                                                <h5 className="card-title text-gray-600" >Publish Date: <span className="">{(formatDate1(document.publish_date))}</span></h5>

                                            </>
                                        }
                                        {document.effective_date !== null &&
                                            <>
                                                <span className="card-title px-2" >|</span>
                                                <h5 className="card-title text-gray-600" >Effective Date: <span className="">{(formatDate1(document.effective_date))}</span></h5>
                                            </>
                                        }
                                    </div>
                                    <span className="badge badge-light" >
                                        <a href={`${document.url}`} target="_blank" rel="noopener noreferrer">{document.long_name}</a>
                                    </span>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='card card-custom'>
                <div className='card-header card-header-stretch overflow-auto'>
                    <ul
                        className='nav nav-stretch nav-line-tabs
                        nav-line-tabs-2x
                        border-transparent
                        flex-nowrap'
                        role='tablist'
                    >
                        <li className='nav-item fs-4 '>
                            {/* <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Details' })}
                                onClick={() => setTab('Details')}
                                role='tab'
                            >
                                Details
                            </h5> */}
                        </li>
                        <li className='nav-item fs-4'>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Document' })}
                                onClick={() => setTab('Document')}
                                role='tab'
                            >
                                {/* <KTIcon iconName='information' className='fs-5 me-1 text-primary' />  */}
                                Document
                            </h5>
                        </li>



                        {document.is_genai_exists === 1 ? <>
                            <li className={`nav-item fs-4 `} >
                                <h5
                                    className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Brief' })}
                                    onClick={() => setTab('Brief')}
                                    role='tab'
                                >
                                    Lexim Brief
                                </h5>
                            </li>
                            {/* <li className={`nav-item fs-4 `} >
                                <h5
                                    className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Summary' })}
                                    onClick={() => setTab('Summary')}
                                    role='tab'
                                >
                                    Lexim Summary
                                </h5>
                            </li> */}
                            <li className='nav-item fs-4' >
                                <h5
                                    className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Impact' })}
                                    onClick={() => setTab('Impact')}
                                    role='tab'
                                >
                                    Impact
                                </h5>
                            </li>
                            <li className='nav-item fs-4 ' >
                                <h5
                                    className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'ActionItems' })}
                                    onClick={() => setTab('ActionItems')}
                                    role='tab'
                                >
                                    Action Items
                                </h5>
                            </li>
                            <li className='nav-item fs-4'>
                                <h5
                                    className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'FAQ' })}
                                    onClick={() => setTab('FAQ')}
                                    role='tab'
                                >
                                    {/* <KTIcon iconName='information' className='fs-3 me-1 text-primary' /> */}
                                    FAQ
                                </h5>
                            </li>
                        </> : ''}
                        {document.in_elastic === 1 ?
                            <li className='nav-item fs-4'>
                                <h5
                                    className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'LeximQ&A' })}
                                    onClick={() => setTab('LeximQ&A')}
                                    role='tab'
                                >
                                    Lexim Query
                                </h5>
                            </li>
                            : ''}
                        {/* <li className='nav-item fs-4'>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'PublicSubmissions' })}
                                onClick={() => setTab('PublicSubmissions')}
                                role='tab'
                            >
                                Public Comments
                            </h5>
                        </li> */}
                        {/* {addons.translation &&
                            <li className='nav-item fs-4'>
                                <h5
                                    className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Translation' })}
                                    onClick={() => setTab('Translation')}
                                    role='tab'
                                >
                                    Translation
                                </h5>
                            </li>
                        }
                        {addons.videoTraining &&
                            <li className='nav-item fs-4'>
                                <h5
                                    className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Training' })}
                                    onClick={() => setTab('Training')}
                                    role='tab'
                                >
                                    Training
                                </h5>
                            </li>
                        } */}
                        {/* <li className='nav-item fs-4'>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'RelatedDocuments' })}
                                onClick={() => setTab('RelatedDocuments')}
                                role='tab'
                            >

                                Supporting Material

                            </h5>
                        </li>
                        <li className='nav-item fs-4'>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Discussion' })}
                                onClick={() => setTab('Discussion')}
                                role='tab'
                            >
                                Discussion
                            </h5>
                        </li> */}
                    </ul>
                </div>

                <div className='card-body'>
                    <div className='tab-content'>
                        {/* <div className={clsx('tab-pane', { active: tab === 'Details' })}>
                            {document && <DocumentDetailsCard document={document} loading={isLoading} />}
                        </div> */}
                        <div className={clsx('tab-pane', { active: tab === 'Document' })}>
                            {document.doc_id &&
                                <div className="d-flex flex-column flex-column-fluid w-100 h-100 overflow-auto mb-5 border-top bg-white ">
                                    <FullWindow documentId={document.id} isInternational={true} />
                                </div>
                            }
                        </div>
                        <div className={clsx('tab-pane', { active: tab === 'RelatedDocuments' })}>
                            {document.iddocument &&
                                <div className="d-flex flex-column flex-column-fluid w-100 h-100 overflow-auto mb-5 border-top bg-white ">

                                    <SuggestedDocuments docketId={document.docketId} documentId={document.iddocument} />

                                </div>
                            }
                        </div>
                        <div className={clsx('tab-pane', { active: tab === 'PublicSubmissions' })}>
                            {document.iddocument &&
                                <Comments document={document} />

                            }
                        </div>
                        <div className={clsx('tab-pane', { active: tab === 'LeximQ&A' })}>
                            {document.id &&
                                <div className="d-flex flex-column flex-column-fluid w-100 h-100 overflow-auto mb-5 border-top bg-white ">
                                    <div className="row h-100">
                                        {leftSize && <div className={`col-md-${leftSize}`}>
                                            <HalfWindow documentId={document.id} isInternational={true} />
                                        </div>}
                                        <div className={`col-md-${rightSize} d-flex flex-column justify-content-start`}>
                                            {/* <button onClick={toggleColumnSize} className='position-absolute' style={{ background: 'none', border: 'none'}}>
                                                    <KTIcon
                                                        iconName={`arrow-${arrowToggleDirection}`}
                                                        iconType="duotone"
                                                        className='fw-bold fs-1 ms-2'
                                                    />
                                                </button> */}
                                            <QAInterface documentId={document.id} subtype={'international'} documentType={'international'} />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={clsx('tab-pane', { active: tab === 'Brief' })}>
                            {document.doc_id &&
                                <div className="">
                                    <BriefViewer iddocument={document.doc_id} brief={document?.concise_summary} />
                                </div>}
                        </div>
                        {/* <div className={clsx('tab-pane', { active: tab === 'Summary' })}>
                            {document.doc_id &&
                                <div className="">
                                    <SummaryViewer iddocument={document.doc_id} summary={document?.summary} />
                                </div>}
                        </div> */}
                        <div className={clsx('tab-pane', { active: tab === 'Impact' })}>
                            {document.doc_id &&
                                <div className="">
                                    <ImpactViewer iddocument={document.doc_id} hasRatings={false} />
                                </div>}
                        </div>
                        <div className={clsx('tab-pane', { active: tab === 'FAQ' })}>
                            {document.doc_id &&
                                <div className="">
                                    <FAQViewer iddocument={document.doc_id} hasRatings={false} />
                                </div>}
                        </div>
                        <div className={clsx('tab-pane', { active: tab === 'ActionItems' })}>
                            {document.doc_id &&
                                <div className="">
                                    <ActionItemsViewer iddocument={document.doc_id} hasRatings={false} />
                                </div>}
                        </div>
                        {/* <div className={clsx('tab-pane', { active: tab === 'Translation' })}>
                            {document.doc_id && <div><pre>{fileText}</pre> </div>}
                        </div> */}
                        {/* <div className={clsx('tab-pane', { active: tab === 'Training' })}>
                            {document.doc_id && <VideoViewer />}
                        </div> */}
                        {/* <div className={clsx('tab-pane', { active: tab === 'Discussion' })}>
                            {document.doc_id && <DiscussionViewer documentId={document.iddocument} />}
                        </div> */}
                    </div>
                </div>

            </div>

            {/* {document && <DocumentDetailsCard document={document} />} */}
            {/* <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} id="uncontrolled-tab-example" className="mb-3 mt-4">
                <Tab eventKey="details" title={<span style={{ fontSize: '14px' }}>Details</span>}>
                    {document && <DocumentDetailsCard document={document} loading={isLoading} />}
                </Tab>
                <Tab eventKey="document" title={<span style={{ fontSize: '14px' }}>Document</span>}>


                    {document.iddocument &&
                        <div className="d-flex flex-column flex-column-fluid w-100 h-100 overflow-auto mb-5 border-top bg-white ">
                            <FullWindow documentId={document.iddocument} />
                        </div>
                    } 
                </Tab> */}
            {/* <Tab eventKey="viewAsText" title={<span style={{ fontSize: '14px' }}>View as Text</span>}>
                   
                    {document.iddocument &&
                        <div className="d-flex flex-column flex-column-fluid w-100 h-100 overflow-auto mb-5 border-top bg-white">
                            <TextViewer iddocument={document.iddocument} />
                        </div>}
                </Tab> */}
            {/* <Tab eventKey="publicSubmissions" title={<span style={{ fontSize: '14px' }}>Public Submissions</span>}>
                    {document.iddocument &&
                        <div className="d-flex flex-column flex-column-fluid w-100 h-100 overflow-auto mb-5 border-top bg-white">
                            <Comments document={document} />
                        </div>}

                </Tab> */}
            {/* <Tab eventKey="docqa" title={<span style={{ fontSize: '14px' }}>Q&A</span>}>
                    {document.iddocument &&
                        <div className="d-flex flex-column flex-column-fluid w-100 h-100 overflow-auto mb-5 border-top bg-white ">


                            {document.in_elastic !== 1 ? (

                                <div className="d-flex flex-column align-items-center w-100 mt-20 text-wrap mx-auto vh-100"><div className={`alert alert-info alert-dismissible fade show mt-3`} role="alert">
                                    Q&A for this document is coming soon !

                                </div>
                                    <img src="/media/illustrations/dozzy-1/2.png" alt="Background" style={{ maxWidth: '80%' }} /></div>
                            ) : (
                                <div className="row h-100">
                                    <div className="col-md-6">
                                        <HalfWindow documentId={document.iddocument} />
                                    </div>
                                    <div className="col-md-6">
                                        <QAInterface documentid={document.id} />
                                    </div>
                                </div>
                            )}
                        </div>
                    }

                </Tab> */}
            {/* <Tab eventKey="summary" title={<span style={{ fontSize: '14px' }}>Summary</span>}>
                    {document.id &&
                        <div className="d-flex flex-column flex-column-fluid w-100 h-100 overflow-auto mb-5 border-top bg-white">
                            <SummaryViewer iddocument={document.id} />
                        </div>}

                </Tab>
                <Tab eventKey="Impact" title={<span style={{ fontSize: '14px' }}>Impact</span>}>
                    {document.id &&
                        <div className="d-flex flex-column flex-column-fluid w-100 h-100 overflow-auto mb-5 border-top bg-white">
                            <ImpactViewer iddocument={document.id} />
                        </div>}
                </Tab>
                <Tab eventKey="faq" title={<span style={{ fontSize: '14px' }}>FAQ</span>}>
                    {document.id &&
                        <div className="d-flex flex-column flex-column-fluid w-100 h-100 overflow-auto mb-5 border-top bg-white">
                            <FAQViewer iddocument={document.id} />
                        </div>}
                </Tab> */}
            {/* <Tab eventKey="keywords" title="Keywords"> */}
            {/* Keywords content goes here */}
            {/* {document.id &&
                        <div className="d-flex flex-column flex-column-fluid w-100 h-100 overflow-auto mb-5 border-top bg-white">
                            <KeywordsViewer iddocument={document.id} />
                        </div>}
                </Tab> */}
            {/* <Tab eventKey="actionItems" title={<span style={{ fontSize: '14px' }}>Action Items</span>}>
                    {document.id &&
                        <div className="d-flex flex-column flex-column-fluid w-100 h-100 overflow-auto mb-5 border-top bg-white">
                            <ActionItemsViewer iddocument={document.id} />
                        </div>}
                </Tab>
                <Tab eventKey="translation" title={<span style={{ fontSize: '14px' }}>Translation</span>}>
                    {document.id &&
                        <div>
                            <pre>{fileText}</pre>
                        </div>}
                </Tab>
                <Tab eventKey="training" title={<span style={{ fontSize: '14px' }}>Training</span>}>
                    {document.id &&
                        <VideoViewer />}
                </Tab>
                <Tab eventKey="discussion" title={<span style={{ fontSize: '14px' }}>Discussion</span>}>
                    {document.id &&
                        <DiscussionViewer documentId={document.iddocument} />}
                </Tab>
            </Tabs> */}
        </div >
    );
};
export default INDocumentDetails;