import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { KTIcon } from '../../../../_metronic/helpers';
import { useAuth } from '../../../modules/auth';
import * as pdfjs from 'pdfjs-dist';

// const API_URL = 'http://127.0.0.1:8000';

const UploadDocumentModal = ({ isOpen, onClose, setIsUploaded, setMessage, selectedFolder, folder_s3_link }) => {
    const [showModal, setShowModal] = useState(isOpen);
    const [loading, setLoading] = useState(false);
    const [fileNames, setFileNames] = useState<string[]>([]);
    const checkbox1Ref = useRef<HTMLInputElement>(null);
    const checkbox2Ref = useRef<HTMLInputElement>(null);
    const checkbox3Ref = useRef<HTMLInputElement>(null);
    const checkbox4Ref = useRef<HTMLInputElement>(null);
    const checkbox5Ref = useRef<HTMLInputElement>(null);
    const checkbox6Ref = useRef<HTMLInputElement>(null);
    const [isChecked, setIsChecked] = useState(true);
    const [isChecked2, setIsChecked2] = useState(false);
    // const [isChecked3, setIsChecked3] = useState(false);
    const [alert, setAlert] = useState('');
    const { currentUser } = useAuth();
    const [fromLanguage, setFromLanguage] = useState('');
    const [toLanguage, setToLanguage] = useState('');
    const MAX_FILE_SIZE = 20 * 1024 * 1024;
    const MAX_FILES = 5;

    pdfjs.GlobalWorkerOptions.workerSrc = "/files/pdf.worker.min.mjs";

    const uploadDocumentSchema = Yup.object().shape({
        document: Yup.mixed().required('An uploaded file is required.'),
        // documentName: Yup.array()
        //     .of(Yup.string().required('Document name is required.').max(100))
        //     .max(MAX_FILES, `You can only upload up to ${MAX_FILES} documents.`),
        ...Object.fromEntries(
            Array.from({ length: fileNames.length }).map((_, index) => [
                `documentName-${index}`,
                Yup.string()
                    .required(`Document ${fileNames.length > 1 ? `#${index + 1}` : ''} name is required.`)
                    .max(100, `Document ${fileNames.length > 1 ? `#${index + 1}` : ''} name cannot exceed 100 characters.`),
            ])
        ),
        description: Yup.string().max(500),
        // ...(isChecked2?  {
        //     translate_from: Yup.string().required('From language is required.'),
        //     translate_to: Yup.string().required('To language is required.')
        // }: {})
    });

    const closeModal = () => {
        setShowModal(false);
        onClose();
    }

    const handleUploadClick = async (values: any) => {
        const MAX_FILE_SIZE = 20 * 1024 * 1024;
        const MAX_FILES = 5;

        if (values.document.length > MAX_FILES) {
            setMessage('You can only upload up to 5 files.');
            setLoading(false);
            return;
        }

        values.document.forEach(async (file) => {
            if (file.size > MAX_FILE_SIZE) {
                setMessage('File size is greater than 20 MB. Please select a smaller file.');
                setLoading(false);
                return;
            }

            const fileContent = await file.arrayBuffer();
            const pdf = await pdfjs.getDocument({ data: fileContent }).promise;

            let isScanned = true;

            for (let i = 1; i <= pdf.numPages; i++) {
                const page = await pdf.getPage(i);
                const textContent = await page.getTextContent();

                if (textContent.items.length > 0) {
                    isScanned = false;
                    break;
                }
            }

            if (isScanned) {
                setMessage('One or more documents is a scanned PDF and cannot be processed. Please upload text-based PDFs only.');
                setLoading(false);
                return;
            }

        });

        // console.log(values.document);
        // console.log('values:', checkbox5Ref.current?.checked, fromLanguage, toLanguage);
        if (checkbox5Ref.current?.checked) {
            if (!fromLanguage || !toLanguage) {
                setMessage('Both "From" and "To" languages are required for translation.');
                return;
            }
        }
        console.log('values:', values);
        if (values.document) {
            setLoading(true);
            const formData = new FormData();
            // const uploadFormData = new FormData();
            values.document.forEach((file, index) => {
                formData.append('file', file);
                formData.append('titles', values[`documentName-${index}`]);
                // uploadFormData.append('files', file);
            });
            ///formData.append('title', values.documentName);
            formData.append('description', values.description);
            formData.append('genai_requested', isChecked ? 'True' : 'False');
            formData.append('translation_requested', checkbox5Ref.current?.checked ? 'True' : 'False');
            formData.append('video_training_requested', checkbox6Ref.current?.checked ? 'True' : 'False');
            formData.append('translate_from', fromLanguage);
            formData.append('translate_to', toLanguage);
            formData.append('folder_id', selectedFolder ? String(Object.values(selectedFolder)[0]) : '');
            formData.append('folder_s3_link', folder_s3_link)

            // uploadFormData.append('user_id', String(currentUser?.id));
            // uploadFormData.append('vault_s3_link', folder_s3_link);

            // const response = await fetch(`${process.env.REACT_APP_DEEP_API_URL as string}/upload-file`, {
            //     method: 'POST',
            //     body: uploadFormData
            // })

            // const response = await fetch(`https://deepapi.lexim.ai/upload-file`, {
            //     method: 'POST',
            //     body: uploadFormData
            // })

            // if (response.ok) {

            //     const data = await response.json();

            //     // If data is an array, you need to use the first element of it
            //     const uploadedResults = JSON.parse(data[0].body);  // Access the first element in the array

            //     // Check if uploadedResults is defined before trying to iterate
            //     if (uploadedResults) {
            //         uploadedResults.forEach(result => {
            //             formData.append('mv_document_id_external', result.mv_document_id_external);
            //             formData.append('s3_link', result.file_path);
            //         });
            //     } else {
            //         console.error('Uploaded results are missing or undefined');
            //     }

            axios.post(`/reg/v1/document/upload/${currentUser?.organization}/${currentUser?.id}`, formData)
                .then(response => {
                    setLoading(false);
                    if (values.document.length > 1) {
                        setMessage('Documents uploaded successfully!');
                    } else {
                        setMessage('Document uploaded successfully!')
                    }
                    // console.log(response);
                })
                .catch(error => {
                    setLoading(false);
                    console.error('Error response:', error.response?.data);

                    if (error.response && error.response.status === 413) {
                        setMessage(`Failed to upload document due to size limit: File size is greater than 20 MB`);
                    } else {
                        setMessage(`Failed to upload documents: \n ${error.response?.data?.message || 'Unknown error'}`);

                        // Check if error.response.data is an array before calling filter
                        if (Array.isArray(error.response?.data)) {
                            const failedMessages = error.response.data
                                .filter((item) => item.status === "failed")
                                .map((item) => item.message)
                                .join('\n');
                            setMessage(`Failed to upload documents: \n ${failedMessages || 'Unknown error'}`);
                        } else {
                            console.error('Expected an array but got:', typeof error.response.data);
                        }
                    }
                });

            // }
            // else {
            //     console.error('Failed to upload documents:', response);
            //     setMessage('Failed to upload documents: \n' + response.statusText);
            // }
        }
    };

    const formik = useFormik({
        initialValues: {
            document: null,
            'documentName-0': '',
            'documentName-1': '',
            'documentName-2': '',
            'documentName-3': '',
            'documentName-4': '',
            description: ''
        },
        validationSchema: uploadDocumentSchema,
        onSubmit: async (values) => {
            setLoading(true);
            setAlert('');
            if (await formikRef.current.validateForm() && formikRef.current.isValid) {
                // if (await formik.validateForm() && formik.isValid) {
                try {
                    await handleUploadClick(values);
                    formikRef.current.resetForm();
                    setFileNames([]);
                    setIsUploaded(true);
                    closeModal();
                } catch (error) {
                    console.error('Failed to upload document:', error);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        },
    });

    const formikRef = useRef(formik);
    formikRef.current = formik;
    useEffect(() => {
        setShowModal(isOpen);
        if (!isOpen) {
            setFileNames([]);
            checkbox1Ref.current && (checkbox1Ref.current.checked = true);
            checkbox2Ref.current && (checkbox2Ref.current.checked = false);
            checkbox3Ref.current && (checkbox3Ref.current.checked = false);
            checkbox4Ref.current && (checkbox4Ref.current.checked = false);
            checkbox5Ref.current && (checkbox5Ref.current.checked = false);
            checkbox6Ref.current && (checkbox6Ref.current.checked = false);
            setIsChecked(true);
            setIsChecked2(false);
            // setIsChecked3(false);
            setFromLanguage('');
            setToLanguage('');
            formikRef.current.resetForm();
            // formik.resetForm();
        }
    }, [isOpen]);

    // console.log('folder_s3_link:', folder_s3_link);
    return (
        <>
            <div
                className={`modal fade ${showModal ? 'show d-block' : 'd-none'}`}
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered mw-750px' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {/* begin::Modal content */}
                    <div className='modal-content position-relative'>
                        {/* begin::Modal header */}
                        <div className='modal-header'>
                            {/* begin::Modal title */}
                            <h2 className='fw-bolder'>Upload Document to {selectedFolder && Object.keys(selectedFolder)[0] !== "All Documents" ? Object.keys(selectedFolder)[0] : "Root Folder"}</h2>
                            {/* end::Modal title */}
                            {/* begin::Close */}
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-organizations-modal-action='close'
                                onClick={() => { closeModal(); setAlert(''); setMessage(''); }}
                                style={{ cursor: 'pointer' }}
                            >
                                <KTIcon iconName='cross' className='fs-1' />
                            </div>
                            {/* end::Close */}
                        </div>
                        {/* begin::Modal body */}
                        <form onSubmit={formik.handleSubmit} className="col-lg-12">
                            <div className='modal-body scroll-y' style={{ height: 'calc(100vh - 300px)', overflowY: 'auto' }}>
                                <div className='card-body pt-0 pb-0  mx-5 mx-xl-15'>
                                    <div>
                                        <div className="row">
                                            <label htmlFor='document' className="col-3">
                                                <h4 className="required mt-3">Browse:</h4>
                                                <span>*File size &le; 20 MB, </span>
                                                <span>Maximum 5 files</span>
                                            </label>
                                            <div className="col-8 offset-1">
                                                <input
                                                    id="document"
                                                    name="document"
                                                    type="file"
                                                    multiple
                                                    onChange={async (event) => {
                                                        setLoading(true);
                                                        try {
                                                            if (event.currentTarget.files && event.currentTarget.files.length > 0) {
                                                                if (event.currentTarget.files.length > MAX_FILES) {
                                                                    setAlert(`Maximum ${MAX_FILES} files are allowed.`);
                                                                    setFileNames([]);
                                                                    setLoading(false);
                                                                    return;
                                                                }

                                                                const files = Array.from(event.currentTarget.files).slice(0, MAX_FILES);
                                                                const filesExceedingLimit = files.filter(file => file.size > MAX_FILE_SIZE);
                                                                if (filesExceedingLimit.length > 0) {
                                                                    setAlert('One or more file size exceeds the 20MB limit. Please select smaller files.');
                                                                    setFileNames([]);
                                                                    setLoading(false);
                                                                    return;
                                                                }

                                                                for (const file of files) {
                                                                    const fileContent = await file.arrayBuffer();
                                                                    const pdf = await pdfjs.getDocument({ data: fileContent }).promise;

                                                                    let isScanned = true;
                                                                    for (let i = 1; i <= pdf.numPages; i++) {
                                                                        const page = await pdf.getPage(i);
                                                                        const textContent = await page.getTextContent();
                                                                        if (textContent.items.length > 0) {
                                                                            isScanned = false;
                                                                            break;
                                                                        }
                                                                    }

                                                                    if (isScanned) {
                                                                        setAlert('One or more documents is a scanned PDF and cannot be processed. Please upload text-based PDFs only.');
                                                                        setFileNames([]);
                                                                        setLoading(false);
                                                                        return;
                                                                    }
                                                                }

                                                                setAlert('');
                                                                formik.setFieldValue('document', files);
                                                                formik.validateField('document');
                                                                await setFileNames(files.map(file => file.name));
                                                            }
                                                        } catch (error) {
                                                            console.error('Error processing PDF files:', error);
                                                            setAlert('An error occurred while processing the files. Please try again.');
                                                        } finally {
                                                            setLoading(false);
                                                        }
                                                    }}
                                                    className="form-control mb-10"
                                                    accept=".txt,.pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                    required
                                                    style={{ display: 'none' }} // Hide the input element
                                                />
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    style={{ width: '35%' }}
                                                    onClick={() => {
                                                        const inputElement = document.getElementById('document') as HTMLInputElement;
                                                        if (inputElement) {
                                                            inputElement.value = ''; // Reset the value
                                                            setTimeout(() => inputElement.click(), 0); // Trigger the file input click after resetting
                                                        }
                                                    }}
                                                >
                                                    Browse
                                                </button>
                                                {alert !== '' ? (
                                                    <div className="text-danger mt-3 mb-5 col-12 text-start">{alert}</div>
                                                ) : formik.errors.document && formik.touched.document ? (
                                                    <div className="text-danger mt-3 mb-5 col-12 text-start">{formik.errors.document}</div>
                                                ) : (
                                                    <ul className="mt-3 mb-5">
                                                        {fileNames.map((name, index) => (
                                                            <li key={index}>{name}</li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>

                                        </div>
                                        {fileNames.length > 1 ?
                                            fileNames.map((name, index) => (
                                                <>
                                                    <div key={index} className="row align-items-center">
                                                        <label htmlFor={`documentName-${index}`} className="col-4 mt-4">
                                                            <h4 className="required">Document #{index + 1} Title:</h4>
                                                        </label>
                                                        <div className="col-8 ">
                                                            <input
                                                                id={`documentName-${index}`}
                                                                name={`documentName-${index}`}
                                                                type="text"
                                                                onChange={formik.handleChange}
                                                                placeholder={`Document Title ${index + 1}`}
                                                                className="form-control mt-3"
                                                                value={formik.values[`documentName-${index}`]}
                                                                maxLength={100}
                                                                style={{ backgroundColor: '#f5f5f5' }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={`${alert !== '' || (formik.errors[`documentName-${index}`] && formik.touched[`documentName-${index}`]) ? 'col-8 offset-4 d-flex justify-content-between align-items-center mt-3 pe-2' : 'justify-content-end mt-3 me-2'} ${index === fileNames.length - 1 ? 'mb-6' : 'mb-2'}`}>
                                                        {alert !== '' ? (
                                                            <div className={`text-danger text-start`} style={{ flex: 1 }}>
                                                                {alert}
                                                            </div>
                                                        ) : formik.errors[`documentName-${index}`] && formik.touched[`documentName-${index}`] ? (
                                                            <div className={`text-danger text-start mt-n3 ps-2`} style={{ flex: 1 }}>
                                                                {formik.errors[`documentName-${index}`]}
                                                            </div>
                                                        ) : null}

                                                        <p className={`text-end`} style={{ flex: 0 }}>
                                                            {formik.values[`documentName-${index}`]?.length || 0}/100
                                                        </p>
                                                    </div>

                                                </>
                                            )) : (
                                                <>
                                                    <div className="row align-items-center">
                                                        <label htmlFor={`documentName-0`} className="col-4 mt-4">
                                                            <h4 className="required">Document Title:</h4>
                                                        </label>
                                                        <div className="col-8 ">
                                                            <input
                                                                id={`documentName-0`}
                                                                name={`documentName-0`}
                                                                type="text"
                                                                onChange={formik.handleChange}
                                                                placeholder={`Document Title`}
                                                                className="form-control mt-3"
                                                                value={formik.values[`documentName-0`]}
                                                                maxLength={100}
                                                                style={{ backgroundColor: '#f5f5f5' }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={`${alert !== '' || (formik.errors[`documentName-0`] && formik.touched[`documentName-0`]) ? 'col-8 offset-4 d-flex justify-content-between align-items-center mt-3 pe-2' : 'justify-content-end mt-3 me-2'} mb-6`}>
                                                        {alert !== '' ? (
                                                            <div className={`text-danger text-start`} style={{ flex: 1 }}>
                                                                {alert}
                                                            </div>
                                                        ) : formik.errors[`documentName-0`] && formik.touched[`documentName-0`] ? (
                                                            <div className={`text-danger text-start mt-n3 ps-2`} style={{ flex: 1 }}>
                                                                {formik.errors[`documentName-0`]}
                                                            </div>
                                                        ) : null}

                                                        <p className={`text-end`} style={{ flex: 0 }}>
                                                            {formik.values[`documentName-0}`]?.length || 0}/100
                                                        </p>
                                                    </div>

                                                </>
                                            )}
                                        <div className="row">
                                            <label htmlFor='description' className="col-3">
                                                <h4>Description:</h4>
                                            </label>
                                            <div className="col-8 offset-1">
                                                <textarea
                                                    id='description'
                                                    name='description'
                                                    placeholder={fileNames.length > 1 ? "Documents Description" : "Document Description"}
                                                    className="form-control"
                                                    rows={7}
                                                    maxLength={500}
                                                    value={formik.values.description}
                                                    onChange={formik.handleChange}
                                                    style={{ resize: 'none', overflow: 'auto', backgroundColor: '#f5f5f5' }}
                                                />
                                            </div>
                                        </div>
                                        <p className="mt-3 mb-6 col-12 text-end pe-2">{formik.values.description?.length || 0}/500</p>
                                        <div className="row mt-3 mb-10">
                                            <label htmlFor='description' className="col-10">
                                                <h4>Generate:</h4>
                                            </label>
                                        </div>
                                        <div className="row offset-1" style={{ marginLeft: '10%' }}>
                                            <div className="col d-flex flex-row justify-content flex-wrap">
                                                <div className="d-flex flex-row justify-content flex-wrap">
                                                    <div className="form-check mb-6 d-flex">
                                                        <input ref={checkbox1Ref} className="form-check-input" type="checkbox" id="checkbox1" checked={isChecked} onChange={() => { setIsChecked(!isChecked); }} style={{ marginRight: '10px' }} />
                                                        <label className="form-check-label text-dark" htmlFor="checkbox1" style={{ fontSize: '1.2rem' }}>Summary, FAQ, Action Items, Impact</label>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row justify-content flex-wrap">
                                                    <div className="form-check mb-3 d-flex align-items-center">
                                                        <input ref={checkbox5Ref} className="form-check-input mr-2" type="checkbox" id="checkbox5" checked={isChecked2} onChange={() => { setIsChecked2(!isChecked2); }} style={{ marginRight: '10px' }} />
                                                        <label className="form-check-label text-dark mb-1" htmlFor="checkbox5" style={{ fontSize: '1.2rem' }}>Translation</label>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <label className="form-check-label ms-5 mb-4 text-dark" htmlFor="checkbox5" style={{ fontSize: '1.2rem' }}>From:</label>
                                                        <div className="ms-5 mb-3">
                                                            <select className="form-select form-select-sm mb-2" value={fromLanguage}
                                                                onChange={(event) => {
                                                                    const selectedValue = event?.target.value;
                                                                    // console.log('selectedValue:', selectedValue);
                                                                    setFromLanguage(selectedValue);
                                                                }}
                                                                required={isChecked2}
                                                            >
                                                                <option value="">Select...</option>
                                                                <option value="Arabic">Arabic</option>
                                                                <option value="Czech">Czech</option>
                                                                <option value="German">German</option>
                                                                <option value="English">English</option>
                                                                <option value="Spanish">Spanish</option>
                                                                <option value="Finnish">Finnish</option>
                                                                <option value="French">French</option>
                                                                <option value="Hindi">Hindi</option>
                                                                <option value="Italian">Italian</option>
                                                                <option value="Japanese">Japanese</option>
                                                                <option value="Korean">Korean</option>
                                                                <option value="Dutch">Dutch</option>
                                                                <option value="Sinhala">Sinhala</option>
                                                                <option value="Chinese">Chinese</option>
                                                                <option value="Hebrew">Hebrew</option>
                                                                <option value="Portuguese">Portuguese</option>
                                                                <option value="Swedish">Swedish</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <label className="form-check-label ms-5 mb-4 text-dark" htmlFor="checkbox5" style={{ fontSize: '1.2rem' }}>To:</label>
                                                        <div className="ms-5 mb-3">
                                                            <select className="form-select form-select-sm mb-2" value={toLanguage}
                                                                onChange={(event) => {
                                                                    const selectedToValue = event?.target.value;
                                                                    // console.log('selectedToValue:', selectedToValue);
                                                                    setToLanguage(selectedToValue);
                                                                }}
                                                                required={isChecked2}
                                                            >
                                                                <option value="">Select...</option>
                                                                <option value="Arabic">Arabic</option>
                                                                <option value="Czech">Czech</option>
                                                                <option value="German">German</option>
                                                                <option value="English">English</option>
                                                                <option value="Spanish">Spanish</option>
                                                                <option value="Finnish">Finnish</option>
                                                                <option value="French">French</option>
                                                                <option value="Hindi">Hindi</option>
                                                                <option value="Italian">Italian</option>
                                                                <option value="Japanese">Japanese</option>
                                                                <option value="Korean">Korean</option>
                                                                <option value="Dutch">Dutch</option>
                                                                <option value="Sinhala">Sinhala</option>
                                                                <option value="Chinese">Chinese</option>
                                                                <option value="Hebrew">Hebrew</option>
                                                                <option value="Portuguese">Portuguese</option>
                                                                <option value="Swedish">Swedish</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="d-flex flex-row justify-content-center flex-wrap">
                                                    <div className="form-check mb-5 mt-3 d-flex align-items-center">
                                                        <input ref={checkbox6Ref} className="form-check-input mr-2" type="checkbox" id="checkbox6" checked={isChecked3} onChange={() => { setIsChecked3(!isChecked3); }} style={{ marginRight: '10px' }} />
                                                        <label className="form-check-label text-dark" htmlFor="checkbox6" style={{ fontSize: '1.2rem' }}>Video Training</label>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="my-10 mx-5 mx-xl-16">
                                <button
                                    type="button"
                                    className="btn btn-secondary col-3 offset-5"
                                    onClick={(e) => {
                                        closeModal();
                                        setFileNames([]);
                                        checkbox1Ref.current && (checkbox1Ref.current.checked = true);
                                        checkbox2Ref.current && (checkbox2Ref.current.checked = false);
                                        checkbox3Ref.current && (checkbox3Ref.current.checked = false);
                                        checkbox4Ref.current && (checkbox4Ref.current.checked = false);
                                        checkbox5Ref.current && (checkbox5Ref.current.checked = false);
                                        checkbox6Ref.current && (checkbox6Ref.current.checked = false);
                                        setIsChecked(true);
                                        setIsChecked2(false);
                                        // setIsChecked3(false);
                                        setFromLanguage('');
                                        setToLanguage('');
                                        setMessage('');
                                        setAlert('');
                                        formik.resetForm();
                                    }}
                                >
                                    Discard
                                </button>

                                <button
                                    type="button"
                                    className="btn btn-success ms-10 col-3"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setAlert('');
                                        formik.handleSubmit(e as any);
                                    }}
                                >
                                    {loading ? 'Loading...' : 'Upload'}
                                </button>
                            </div>
                        </form>
                        {/* end::Modal body */}
                    </div>
                    {/* end::Modal content */}
                </div >
                {/* end::Modal dialog */}
            </div >
            {/* begin::Modal Backdrop */}
            {showModal && <div className='modal-backdrop fade show'></div>}
            {/* end::Modal Backdrop */}
        </>
    );
};

export default UploadDocumentModal;