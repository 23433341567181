import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { KTIcon } from '../../../_metronic/helpers';
import clsx from 'clsx';
import HourGlass from '../Loading/HourGlassSpinner/HourGlass';

const AdverseEventsDetails = () => {
    const { id } = useParams();
    const [device, setDevice] = useState([]);
    const [tab, setTab] = useState('Details');
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const searchTerm = location.state ? location.state.searchTerm : '';
    const currentPage = location.state ? location.state.currentPage : 1;
    const replacements = {
        '': '™',
        '': '"',
        '': '"',
        '®': '®',
        '©': '©',
        '': '—',
        '': '–',
        '': '•',
        '': '\'',
        '': '\'',
        '': '…',
        '': '¨',
        '': '‰',
        '': '‹',
        '': '›',
        '': '«',
        '': '»',
        '¿': '®',
    }
    useEffect(() => {
        const fetchDevice = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`/reg/v1/fda/adverse_events/${id}`);
                setDevice(response.data[0]);
                console.log(response.data[0]);

                setIsLoading(false);
            } catch (error) {
                console.log('Error fetching document:', error);
                setIsLoading(false);
            }
        };
        fetchDevice();
    }, [id]);

    return (
        <div>
            <div className="pb-2" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', maxWidth: '100%' }}>
                <div style={{ marginRight: '20px', width: '250px' }}>
                    <Link
                        to={"/device-intelligence/adverseevents"}
                        state={{ searchTerm: searchTerm, currentPage: currentPage }}
                        style={{
                            color: '#0d6efd',
                            textDecoration: 'none',
                            fontSize: '1.3em',
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        onMouseEnter={(e) => e.target.style.color = '#0d6efd'}
                        onMouseLeave={(e) => e.target.style.color = '#0d6efd'}
                    >
                        <KTIcon iconName='arrow-left' className='fs-2 me-2' style={{ marginRight: '10px' }} />
                        Adverse Events List
                    </Link>
                </div>
            </div>

            <div className='card mt-4 mb-4' style={{}}>
                <div className='card-body d-flex align-items-center '>
                    <div className='position-relative flex-wrap'>
                        <div>
                            {!isLoading && <h4 className='text-gray-700 fw-bold'>Medical Device Report #{id}</h4>}
                            {isLoading && (
                                <div className="d-flex align-items-center">
                                    <div>Adverse Event Loading...</div>
                                    <div className="spinner-border spinner-border-sm ms-3 mb-1" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className='card card-custom'>
                <div className='card-header card-header-stretch overflow-auto'>
                    <ul
                        className='nav nav-stretch nav-line-tabs
                        nav-line-tabs-2x
                        border-transparent
                        flex-nowrap'
                        role='tablist'
                    >
                        <li className='nav-item fs-4 '>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Details' })}
                                onClick={() => setTab('Details')}
                                role='tab'
                            >
                                Details
                            </h5>
                        </li>
                        {/* <li className='nav-item fs-4'>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'PDF' })}
                                onClick={() => setTab('PDF')}
                                role='tab'
                            >
                                PDF
                            </h5>
                        </li> */}
                    </ul>
                </div>
                {isLoading ? (
                    <div className='card-body'>
                        <div className='tab-content'>
                            <HourGlass height="49vh" />
                        </div>
                    </div>
                ) : (
                    <div className='card-body'>
                        <div className='tab-content'>
                            <div className={clsx('tab-pane', { active: tab === 'Details' })}>
                                <div className="card-body card-scroll">
                                    {device && device.openfda_device_name && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Device Name</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.openfda_device_name}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device && device.brand_name && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Brand Name</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">  {Object.keys(replacements).reduce((str, key) => {
                                                    return str.replace(new RegExp(key, 'g'), replacements[key]);
                                                }, device.brand_name)}</span>
                                            </div>
                                        </div>
                                    )}
                                    {/* {device && device.manufacturer_name && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Manufacturer Name</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.manufacturer_name}</span>
                                            </div>
                                        </div>
                                    )} */}
                                    {device && device.device_operator && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Device Operator</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.device_operator}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device && device.device_report_product_code && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5" style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Product Code</span>
                                            </div>
                                            <div className="col-10">
                                                <Link to={`/device-intelligence/classification/${device.device_report_product_code}`} target="_blank" rel="noopener noreferrer" className="fw-bold fs-5" style={{ color: 'primary' }}>{device.device_report_product_code}</Link>
                                            </div>
                                        </div>
                                    )}
                                    {device && device.openfda_device_class && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Device Class</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.openfda_device_class}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device && device.openfda_medical_specialty_description
                                        && (
                                            <div className="mb-3 row">
                                                <div className="col-2">
                                                    <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Specialty</span>
                                                </div>
                                                <div className="col-10">
                                                    <span className="card-title fw-bold text-gray-600 fs-5 ">{device.openfda_medical_specialty_description
                                                    }</span>
                                                </div>
                                            </div>
                                        )}
                                    {device && device.openfda_regulation_number && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Regulation Number</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.openfda_regulation_number}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device && device.date_received && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5" style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Received Date</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5">{new Date(device.date_received).toLocaleDateString('en-US', { timeZone: 'UTC' })}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device && device.expiration_date_of_device && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5" style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Expiration Date</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5">{new Date(device.expiration_date_of_device).toLocaleDateString('en-US', { timeZone: 'UTC' })}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device && device.patient_problems && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Patient Problems</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.patient_problems}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device && device.additional_info_contact && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Additional Info Contact</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.additional_info_contact}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device && device.event_type && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Event Type</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device?.event_type}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device && device.event_report_source && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Event Report Source</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device?.event_report_source}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device && device.event_source_type && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Event Source Type</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device?.event_source_type}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device && device.reported_event_by && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Event Reported By</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device?.reported_event_by}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
export default AdverseEventsDetails;
