import React, { useState } from 'react';
import axios from 'axios';
// import clsx from 'clsx';
import PaginationWrapper from '../../../components/PaginationWrapper';

const CommentsCard = ({ comments, currentUser, fetchComments, docId, isFilter }) => {
    const [editing, setEditing] = useState(null);
    const [editValue, setEditValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const commentsPerPage = 5;


    const handleEdit = (comment) => {
        setEditing(comment);
        setEditValue(comment['comment']);
    };

    const handleCancel = () => {
        setEditing(null);
        setEditValue('');
    };

    const handleSave = async (comment) => {
        setLoading(true);
        try {
            await axios.patch(`/reg/v1/user/discussions/${currentUser?.id}/${currentUser?.organization}/${docId}/`, {
                comment_id: comment['id'],
                new_comment: editValue
            });

            // console.log(response.data);
        } catch (error) {
            console.error("Error updating comment: ", error);
            // Handle the error appropriately in your application
        } finally {
            setLoading(false);
            fetchComments(docId);
            setEditing(null);
            setEditValue('');
        }
    };
    // const handlePageChange = (pageNumber) => {
    //     setCurrentPage(pageNumber);
    // };

    // Calculate the comments for the current page
    const indexOfLastComment = currentPage * commentsPerPage;
    const indexOfFirstComment = indexOfLastComment - commentsPerPage;
    const currentComments = comments.slice(indexOfFirstComment, indexOfLastComment);

    // Calculate the page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(comments.length / commentsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <>
            {currentComments && currentComments.length > 0 ? (
                currentComments.map((comment, index) => (
                    <div key={index} className="card card-custom mt-5" style={{ border: '1px solid  #d3d3d3' }}>
                        <div className="card-body card-scroll">
                            <div className="d-flex flex-column align-items-start justify-content-between">
                                <div className="d-flex justify-content-between align-items-center w-100">
                                    <h2 style={{ color: "#4d4d4d", margin: '0', padding: '0', marginRight: '10px', marginTop: '1px' }}>
                                        {comment?.first_name} {comment?.last_name}
                                    </h2>
                                    <button
                                        onClick={() => handleEdit(comment)}
                                        className={`btn btn-icon btn-circle btn-color-primary btn-active-color-light-primary w-20px h-20px bg-body ${comment.user_id !== currentUser?.id || editing === comment ? 'invisible' : ''}`}
                                    >
                                        <i className='bi bi-pencil-fill fs-5'></i>
                                    </button>
                                </div>
                                <div className="mt-2">
                                    <p style={{ color: "#6c757d", margin: '0' }}>Last modified at {new Date(comment['modified_date']).toLocaleString('en-US', { timeZone: 'UTC' })}</p>
                                    <p style={{ color: "#6c757d", margin: '0' }}>Created at {new Date(comment['create_date']).toLocaleString('en-US', { timeZone: 'UTC' })}</p>
                                </div>
                            </div>
                            {editing === comment ? (
                                <>
                                    <div className='d-flex align-items-center mb-3 mt-5'>
                                        <div style={{ position: 'relative', flex: 1, marginRight: '10px' }}>
                                            <textarea
                                                id='postComment'
                                                className='form-control form-control-lg form-control-solid'
                                                value={editValue}
                                                onChange={(e) => setEditValue(e.target.value)}
                                                style={{
                                                    backgroundColor: '#f0f0f0',
                                                    height: '130px',
                                                    overflow: 'auto',
                                                    textAlign: 'left',
                                                    resize: 'none',
                                                    verticalAlign: 'top'
                                                }}
                                                rows={10}
                                                maxLength={1000}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ color: '#6c757d', marginLeft: '10px' }}>{`${editValue.length}/1000`}</div>
                                    <div className='d-flex justify-content-between'>
                                        <button
                                            className='btn btn-light-primary px-6 btn-sm  mt-5'
                                            style={{ marginLeft: '10px' }}
                                            onClick={handleCancel}
                                            disabled={loading}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type='submit'
                                            id='kt_password_submit'
                                            className='btn btn-primary px-6 btn-sm  mt-5'
                                            style={{ marginRight: '20px' }}
                                            disabled={loading}
                                            onClick={() => handleSave(comment)}
                                        >
                                            {!loading && <span className='indicator-label'>Save Comment</span>}
                                            {loading && (
                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                    Please wait...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <h6 className="mt-5">{comment['comment']}</h6>
                            )}
                        </div>
                    </div>
                ))) : (
                <h4 className="d-md-block text-wrap mt-5" style={{ color: "#4d4d4d", margin: '0', padding: '0', marginLeft: '20px' }}>No discussion comments found for selected document! {isFilter ? "with the applied filters" : ""} </h4>
            )}
            {pageNumbers.length > 1 && (
                <PaginationWrapper
                    totalPages={pageNumbers.length}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            )}
        </>
    );
};

export default CommentsCard;