/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import DocumentCard from './DocumentTitleCard';
import HourGlass from '../pages/Loading/HourGlassSpinner/HourGlass';
import { debounce } from 'lodash';

const DropdownWithAPI = ({
  apiEndpoint, // API URL
  queryParams = {}, // Additional query parameters
  searchInput, // Search input to use in API calls
  onSelect, // Callback when an option is selected
  selectedDocId,
  isInternalUpload,
  firstDoc
}) => {
  const [options, setOptions] = useState([]); // Dropdown options
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(''); // Error handling

  // Create a ref to store the previous search string
  const prevSearchStringRef = useRef(queryParams.search_string);

  const fetchOptions = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.get(apiEndpoint, {
        params: queryParams
      });
      setOptions(response.data || []); // Adjust to match backend response
    } catch (err) {
      setError('Failed to fetch options');
    } finally {
      setLoading(false);
    }
  };

  const fetchDocs = useCallback(
    debounce(async () => {
      if (options && options.length === 0) {
        setLoading(true);
      }
      try {
        const docketId = firstDoc !== '' && firstDoc.split('-').slice(0, -1).join('-');
        const response = await axios.get(`/reg/v1/document/suggested_documents/${docketId}`, {
          params: { id: firstDoc, searchInput: queryParams.search_string },
        });
        console.log(firstDoc);
        const data = response.data;
        setOptions(data);
      } catch (err) {
        setError('Failed to load documents.');
      } finally {
        setLoading(false);
      }
    }, 300),
    [selectedDocId, queryParams.search_string] // Add searchInput to the dependency array
  );

  // Fetch options when search input or API changes
  useEffect(() => {
    const trimmedInput = queryParams.search_string?.trim() || '';
    // Only fetch if search string has changed and is not empty
    // if (trimmedInput && prevSearchStringRef.current !== trimmedInput) {
    if ((trimmedInput === '' && firstDoc !== '')) {
      fetchDocs();
    } else if (options.length === 0 || (trimmedInput !== '' && prevSearchStringRef.current !== trimmedInput)) {
      // prevSearchStringRef.current = trimmedInput; // Update the previous search string
      fetchOptions();
    } else if (!trimmedInput) {
      setOptions([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, apiEndpoint, isInternalUpload]);

  return (
    <div style={{ flex: 1, overflowY: 'scroll', padding: '5px', paddingBottom: '70px' }}>
      {/* Show loading spinner if data is being fetched */}
      {loading ? (
        <div className="d-flex justify-content-center">
          <HourGlass height={"calc(35vh)"} />
        </div>
      ) : error ? (
        <div className="alert alert-danger">{error}</div>
      ) : (
        <>
          {/* Render the documents with a scrollable list */}
          <div className="document-list">
            {options.length > 0 ? (
              options.map((option) => (
                <div
                  key={option.id}
                  style={{
                    overflow: 'hidden',
                    marginBottom: '5px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {/* Radio Button for selection */}
                  <input
                    type="radio"
                    id={`radio-${option.id}`}
                    name="documentSelect"
                    value={option.id}
                    checked={selectedDocId === option.id}
                    onChange={() => { prevSearchStringRef.current = queryParams.search_string; onSelect(option); }}
                    style={{ marginRight: '10px' }}
                    aria-label={`Select ${option.title}`}
                  />
                  {/* DocumentCard */}
                  <div style={{ width: '100%' }}>
                    <DocumentCard
                      docTitle={option.title}
                      docTopics={option?.topics}
                      docPostedDate={option?.postedDate}
                      doc_s3_link={option?.doc_s3_link}
                      docType={option.documentType}
                      showTopics={false}
                      showKeywords={false}
                      showDates={true}
                      height={`${60 + (Math.ceil(option.title.length / 50)) * 15}px`}
                    />
                  </div>
                </div>
              ))
            ) : (!loading && options.length === 0 && queryParams.search_string !== "") && (
              <div>No documents available</div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const MemoizedDropdownWithAPI = React.memo(DropdownWithAPI);
export default MemoizedDropdownWithAPI;
