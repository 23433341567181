import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useAuth } from '../../../modules/auth';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
// import { KTIcon } from '../../../_metronic/helpers';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm'
import styled from 'styled-components';
const StyledMarkdown = styled(ReactMarkdown)`
&.markdown table {
    width: 100%;
    border-collapse: collapse;
}

&.markdown th, &.markdown td {
    border: 1px solid #ddd;
    padding: 8px;
}

&.markdown th {
    background-color: #f2f2f2;
    text-align: left;
}
`;

const BriefViewer = ({ iddocument, likes, dislikes, userRating, ratingLoad = false, brief }) => {
    const [textData, setTextData] = useState('');
    const [alert, setAlert] = useState({ message: '', type: '' });
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { logout } = useAuth();
    const renderedDataRef = useRef();
    // const { currentUser } = useAuth();

    // const [likeActive, setLikeActive] = useState(userRating === 1);
    // const [dislikeActive, setDislikeActive] = useState(userRating === -1);
    // const [likeCount, setLikeCount] = useState(likes);
    // const [dislikeCount, setDislikeCount] = useState(dislikes);
    // const [currentUserRating, setCurrentUserRating] = useState(userRating);

    // console.log("in summary viewer", likes, dislikes, userRating);
    // useEffect(() => {
    //     setLikeActive(currentUserRating === 1);
    //     setDislikeActive(currentUserRating === -1);
    // }, [currentUserRating]);


    // const handleLike = () => {
    //     if (likeActive) {
    //         setLikeActive(false);
    //         setLikeCount(likeCount - 1);
    //         updateRating(0);
    //     } else {
    //         setLikeActive(true);
    //         setLikeCount(likeCount + 1);
    //         if (dislikeActive) {
    //             setDislikeActive(false);
    //             setDislikeCount(dislikeCount - 1);
    //         }
    //         updateRating(1);
    //     }
    // };

    // const handleDislike = () => {
    //     if (dislikeActive) {
    //         setDislikeActive(false);
    //         setDislikeCount(dislikeCount - 1);
    //         updateRating(0);
    //     } else {
    //         setDislikeActive(true);
    //         setDislikeCount(dislikeCount + 1);
    //         if (likeActive) {
    //             setLikeActive(false);
    //             setLikeCount(likeCount - 1);
    //         }
    //         updateRating(-1);
    //     }
    // };

    // const updateRating = (rating) => {
    //     setCurrentUserRating(rating);
    //     const userId = currentUser.id;
    //     const docId = iddocument;
    //     const data = {
    //         summary: rating,
    //         summary_comment: ""
    //     }
    //     const jsonData = JSON.stringify(data);
    //     axios.patch(`/reg/v1/user/document/rating/${userId}/${docId}/summary`, jsonData, {
    //         headers: {
    //             'Content-Type': 'application/json'
    //         }
    //     })
    //         .then(response => {
    //             console.log('Rating updated:', response);
    //         })
    //         .catch(error => {
    //             console.error('Failed to update rating:', error);
    //         });
    // };



    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`/reg/v1/document/concise_summary/${iddocument}`);

                if (response.headers['content-type'] === 'application/json') {
                    // Content is provided as JSON directly, display it
                    const formattedText = typeof response.data.data === 'string' ? JSON.parse(response.data.data) : response.data.data;
                    setTextData(formattedText);
                    // console.log(formattedText)
                    setAlert({ message: '', type: '' });
                } else {
                    // Check if a URL is provided for further data retrieval
                    const url = response.data.url;
                    if (url) {
                        // Fetch content from the URL and display it
                        const textResponse = await axios.get(url);
                        if (textResponse.headers['content-type'] === 'application/json') {
                            const formattedText = textResponse.data;
                            setTextData(formattedText);
                            setAlert({ message: '', type: '' });
                        }
                    } else {
                        // No text or URL provided, show a "coming soon" message
                        setAlert({ message: 'Brief for this document is coming soon!', type: 'info' });
                    }
                }

            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            logout();
                            navigate('/auth/login');
                            break;
                        case 400:
                            setAlert({ message: 'Brief for this document is coming soon!', type: 'info' });
                            break;
                        default:
                            setAlert({ message: 'An error occurred', type: 'danger' });
                    }
                } else {
                    setAlert({ message: 'An error occurred', type: 'danger' });
                }
            }
            setIsLoading(false);
        };
        if (brief) {
            setTextData(JSON.parse(brief));
        } else {
            fetchData();
        }
    }, [iddocument, logout, navigate, brief]);

    function generateTable(data) {
        const lines = data.split('\n');
        let html = '<table style="width: 100%; border-collapse: collapse;">';

        lines.forEach(line => {
            const parts = line.split('  ').filter(part => part.trim() !== '');
            if (parts.length === 2) {
                html += '<tr><td style="border: 1px solid #ddd; padding: 8px; padding-right: 10px;">' + parts[0].trim().replace(/\./g, '') + '</td><td style="border: 1px solid #ddd; padding: 8px;">' + parts[1].trim() + '</td></tr>';
            }
        });

        html += '</table>';
        return html;
    }

    const renderData = (data, level = 0) => {
        return Object.entries(data).map(([key, value], index) => {
            if (typeof value === 'string' && value.includes('..........')) {
                return (
                    <React.Fragment key={index}>
                        {key && (
                            <Row className={`p-1 card-title  fw-bold bg-light-primary text-uppercase text-primary text-justify ${level > 0 ? 'ps-7 fs-6' : 'fs-5'}`}>
                                <Col md={12}>{level > 0 ? `• ${key}` : key}</Col>
                            </Row>
                        )}
                        {value && (
                            <Row className={`card-title mb-4 fs-6 pb-2 text-justify ${level > 0 ? 'ps-7' : ''}`}>
                                <Col md={12} dangerouslySetInnerHTML={{ __html: generateTable(value) }}></Col>
                            </Row>
                        )}
                    </React.Fragment>
                );
            }
            else if (typeof value === 'object' && value !== null) {
                return (
                    <React.Fragment key={index}>
                        {/* {key && (
                            <Row className={`p-1 card-title  fw-bold bg-light-primary text-uppercase text-primary text-justify ${level > 0 ? 'ps-7 fs-6' : 'fs-5'}`}>
                                <Col md={12}>{level > 0 ? `• ${key}` : key}</Col>
                            </Row>
                        )} */}
                        {value && (
                            <Row className={`card-title fs-6  text-justify ${level > 0 ? 'ps-7' : ''}`}>
                                <Col md={12}>{renderData(value, level + 1)}</Col>
                            </Row>
                        )}
                    </React.Fragment>
                );
            } else {
                return (
                    <React.Fragment key={index}>
                        {/* {key && (
                            <Row className={`p-1 card-title  fw-bold text-uppercase bg-light-primary text-primary text-justify ${level > 0 ? 'ps-7 fs-6' : 'fs-5'}`}>
                                <Col md={12}>{level > 0 ? `\u23F5  ${key}` : key}</Col>
                            </Row>
                        )} */}
                        {value && (
                            <Row className={`card-title fs-6 text-justify ${level > 0 ? 'ps-7' : ''}`}>
                                <Col md={12}>

                                    <StyledMarkdown className="markdown" remarkPlugins={[gfm]} >{value.replace(/(\n)(?!\|)/g, '\n\n')}</StyledMarkdown>

                                </Col>
                            </Row>
                        )}
                    </React.Fragment>
                );
            }
        });
    }

    const handleDownload = () => {
        let textString = renderedDataRef.current.innerText;

        // Split the text into lines
        let lines = textString.split('\n');

        // Process each line
        lines = lines.map(line => {
            // Remove ** from the line
            line = line.replace(/\*\*/g, '');

            // Trim the line
            line = line.trim();

            // Return the processed line
            return line;
        });

        // Join the lines back together, adding extra line breaks between them
        textString = lines.join('\n');

        // Add some space at the top of the file
        textString = '\n\n\n' + textString;

        const blob = new Blob([textString], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${iddocument} brief.txt`;
        link.click();
    };

    return (
        <>
            {/* {alert.message && (
                <div className={`alert alert-${alert.type} alert-dismissible fade show mt-3`} role="alert">
                    {alert.message}
                    
                </div>
            )} */}
            {(isLoading || ratingLoad) ? (
                <div className="d-flex justify-content-center">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            ) : textData ? (
                <Container className="w-100 text-wrap vh-100"
                    style={{ overflowY: 'auto', overflowX: 'hidden', whiteSpace: 'pre-wrap', marginLeft: 0, maxWidth: '100%' }}>
                    <div className="d-flex justify-content-end mb-4">
                        {/* <div className='d-flex align-items-center mb-3 mt-3'>
                            <button
                                onClick={handleLike}
                                className={`btn btn-sm btn-light px-4 py-2 me-3 ${likeActive ? 'btn-active-light-success btn-light-success' : ''}`}
                            >
                                <KTIcon iconName='like' className='fs-2' />
                                {likeCount}
                            </button>
                            <button
                                onClick={handleDislike}
                                className={`btn btn-sm btn-light px-4 py-2 me-3 ${dislikeActive ? 'btn-active-light-danger btn-light-danger' : ''}`}
                            >
                                <KTIcon iconName='dislike' className='fs-2' />
                                {dislikeCount}
                            </button>
                        </div> */}
                        <Button className="btn btn-primary " onClick={handleDownload}><i className="bi bi-download fs-4 me-2"></i>Download</Button>
                    </div>
                    <div ref={renderedDataRef} className="mt-5">
                        {renderData(textData)}
                    </div>
                </Container>
            ) : (
                <div className="d-flex flex-column align-items-center w-100 mt-5 text-wrap mx-auto vh-100">
                    <div className={`alert alert-${alert.type} alert-dismissible fade show mt-3`} role="alert">
                        {alert.message}
                    </div>
                    {alert.type === 'info' && (
                        <img src="/media/illustrations/dozzy-1/2.png" alt="Background" style={{ maxWidth: '80%' }} />
                    )}
                </div>
            )}
        </>

    );
};

export default BriefViewer;