import React, { useEffect, useState, useCallback } from 'react';
import './CSS/Title21Viewer.css';
import axios from 'axios';
import PaginationWrapper from '../../components/PaginationWrapper';
import HourGlass from '../Loading/HourGlassSpinner/HourGlass';

export default function Title21Viewer({ searchInput }) {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [events, setEvents] = useState([]);
    const [showEvents, setShowEvents] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [tooltip, setTooltip] = useState({ visible: false, content: '', position: { top: 0, left: 0 } });

    const headers = ['Date', 'Subchapter', 'Part', 'Subpart', 'Regulation', 'Details'];
    const pageSize = 10;

    const handleDateChange = (e, setter) => setter(e.target.value);

    // Function to convert timestamp to MM/DD/YYYY format
    const convertTimestampToMMDDYYYY = (timestamp) => {
        const date = new Date(timestamp);
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Use getUTCMonth()
        const day = String(date.getUTCDate()).padStart(2, '0');         // Use getUTCDate()
        const year = date.getUTCFullYear();                             // Use getUTCFullYear()
        return `${month}/${day}/${year}`;
    };


    // Function to get only the name from a string
    const getOnlyName = (name) => {
        const words = name.trim().split(/\s+/);
        return words.slice(2).join(' ').slice(0, -1);
    };

    const fetchEvents = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            console.log(searchInput)
            const response = await axios.get('/reg/v1/fda/title_21/history', {
                headers: { 'Content-Type': 'application/json' },
                params: {
                    start_date: new Date(startDate).getTime() || null,
                    end_date: new Date(endDate).getTime() || null,
                    searchInput: searchInput,
                },
            });
            setEvents(response.data);
            setTotalPages(Math.ceil(response.data.length / pageSize));
            setCurrentPage(1);
        } catch (error) {
            console.error('Error fetching events:', error);
            setError('Failed to fetch events. Please try again.');
        } finally {
            setIsLoading(false);
        }
    }, [startDate, endDate, searchInput]);

    useEffect(() => {
        fetchEvents();
    }, [fetchEvents]);

    useEffect(() => {
        const paginatedEvents = events.slice(
            (currentPage - 1) * pageSize,
            currentPage * pageSize
        );
        setShowEvents(paginatedEvents);
    }, [currentPage, events]);

    const handleSort = (key, e) => {
        let map = {
            'date': 'issue_date',
            'subchapter': 'subchapter',
            'part': 'identifier',
            'subpart': 'subpart',
            'regulation': 'name',
            'details': 'ecfr_url',
        };

        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });

        const sortedEvents = [...events].sort((a, b) => {
            const aValue = a[map[key]] ? a[map[key]].toString() : '';
            const bValue = b[map[key]] ? b[map[key]].toString() : '';

            if (aValue < bValue) return direction === 'asc' ? -1 : 1;
            if (aValue > bValue) return direction === 'asc' ? 1 : -1;
            return 0;
        });

        setEvents(sortedEvents);

        // Positioning tooltip
        const tooltipContent = direction === 'asc' ? 'Ascending' : 'Descending';
        const rect = e.currentTarget.getBoundingClientRect();
        setTooltip({
            visible: true,
            content: tooltipContent,
            position: {
                top: rect.top + window.scrollY + rect.height,
                left: rect.left + window.scrollX + rect.width / 2,
            },
        });

        // Hide tooltip after a short duration
        setTimeout(() => {
            setTooltip({ ...tooltip, visible: false });
        }, 1000);
    };

    return (
        <div className="table-responsive px-0 pr-0 mb-2">
            <div className="title21-form-container mb-4">
                <div className="title21-form-group d-flex">
                    <div className="title21-date-input">
                        <label className='form-label fw-bold ms-1' htmlFor="startDate">Start Date:</label>
                        <input
                            type="date"
                            id="startDate"
                            name="startDate"
                            className='form-control form-control-solid'
                            value={startDate}
                            onChange={(e) => handleDateChange(e, setStartDate)}
                        />
                    </div>
                    <div className="title21-date-input">
                        <label className='form-label fw-bold ms-1' htmlFor="endDate">End Date:</label>
                        <input
                            type="date"
                            id="endDate"
                            name="endDate"
                            className='form-control form-control-solid'
                            value={endDate}
                            onChange={(e) => handleDateChange(e, setEndDate)}
                        />
                    </div>
                </div>
            </div>

            {isLoading ? (
                <div style={{ width: '83vw' }}>
                    <HourGlass height='60vh' />
                </div>
            ) : error ? (
                <p className="title21-error-message">{error}</p>
            ) : (
                <div className='table-responsive px-0 mb-5'>
                    <table
                        className="table table-striped table-hover table-rounded border gy-5 gs-7 align-middle dataTable no-footer table-viewer"
                        style={{
                            '--bs-table-hover-bg': 'rgba(204, 229, 255, 1)',
                            '--bs-table-striped-bg': 'rgba(230, 242, 255, 1)',
                            tableLayout: 'fixed',
                        }}
                    >
                        <thead className="text-start text-muted fw-bolder fs-7 gs-0">
                            <tr className='title21-viewer fw-bold fs-5 text-gray-800 border-bottom-3 border-gray-200'>
                                {headers.map((header, index) => (
                                    <th
                                        key={index}
                                        className={`${header.replace(/\s+/g, '-')}-column`}
                                        style={{ whiteSpace: 'nowrap', paddingRight: '20px', cursor: 'pointer' }}
                                        onClick={(e) => handleSort(header.toLowerCase(), e)} // Pass event to handleSort
                                    >
                                        {header}
                                        <span className={`sorting-icon ${sortConfig.key === header.toLowerCase() ? sortConfig.direction : ''}`}>
                                            <i className={`bi ${sortConfig.key === header.toLowerCase() ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                ))}
                            </tr>
                            {tooltip.visible && (
                                <div
                                    className="tooltip"
                                    style={{
                                        position: 'absolute',
                                        top: tooltip.position.top,
                                        left: tooltip.position.left,
                                        background: '#333',
                                        color: '#fff',
                                        padding: '5px 10px',
                                        borderRadius: '4px',
                                        pointerEvents: 'none',
                                    }}
                                >
                                    {tooltip.content}
                                </div>
                            )}
                        </thead>
                        <tbody>
                            {showEvents.length > 0 ? (
                                showEvents.map((event, index) => (
                                    <tr className='title21-viewer align-items-center bg-hover-light-primary' key={index}>
                                        <td className="date-column text-dark fw-semibold fs-6" >
                                            {convertTimestampToMMDDYYYY(event.issue_date)}
                                        </td>
                                        <td className="sub-chapter-column text-dark fw-semibold fs-6" style={{ verticalAlign: 'middle' }}>
                                            {event.subchapter}
                                        </td>
                                        <td className="part-column text-dark fw-semibold fs-6" style={{ verticalAlign: 'middle' }}>
                                            {event.identifier}
                                        </td>
                                        <td className="sub-part-column text-dark fw-semibold fs-6" style={{ verticalAlign: 'middle' }}>
                                            {event.subpart}
                                        </td>
                                        <td className="regulation-column text-dark fw-semibold fs-6" style={{ verticalAlign: 'middle' }}>
                                            {getOnlyName(event.name)}
                                        </td>
                                        <td className="details-column text-dark fw-semibold fs-6" style={{ verticalAlign: 'middle' }}>
                                            <a href={event.ecfr_url} target="_blank" rel="noopener noreferrer">
                                                Details
                                            </a>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={headers.length} className="">
                                        No data available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
            {!isLoading && totalPages > 1 &&
                <div className="title21-pagination d-flex justify-content-center mt-2 mb-0">
                    <PaginationWrapper
                        totalPages={totalPages}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </div>
            }
        </div>
    );
}
