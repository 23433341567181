import React, { useState, useEffect } from "react";
import { QAInterface } from "./QAInterface.tsx";
import clsx from "clsx";
import HourGlass from "../Loading/HourGlassSpinner/HourGlass";
import ViewSDKClient from './ViewSDKClient';

function FileViewer({ setIsLoading, expandAll, collapseAll, isLoading }) {
    const [content] = useState(null);
    const isChatOpen = true;
    const [activeTab, setActiveTab] = useState('Document');
    const [alert, setAlert] = useState({ message: '', type: '' });

    const fetchAndDisplayPDF = async (url, divId) => {
        try {
            const viewSDKClient = new ViewSDKClient();
            viewSDKClient.ready().then(() => {
                viewSDKClient.previewFileUsingUrl(divId, url, {
                    embedMode: "FULL_WINDOW",
                    showAnnotationTools: false,
                    showThumbnails: false,
                    showBookmarks: false,
                    showDownloadPDF: true
                });
                setIsLoading(false); // Assuming setIsLoading handles the PDF loading state
            });
        } catch (error) {
            setIsLoading(false);
            if (error?.response?.status === 401 || error?.response?.status === 400) {
                setAlert({ message: 'Document is not available', type: 'danger' });
            } else {
                setAlert({ message: 'Error fetching PDF', type: 'danger' });
            }
        }
    };

    useEffect(() => {
        if (activeTab === 'Document') {
            fetchAndDisplayPDF("/files/FDAPreamble.pdf", "pdf-div-document");
        } else if (activeTab === 'LeximQ&A') {
            fetchAndDisplayPDF("/files/FDAPreamble.pdf", "pdf-div-lexim");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);

    useEffect(() => {
        const firstMark = document.querySelector('mark');
        if (firstMark) {
            const scrollableParent = getScrollableParent(firstMark);
            if (scrollableParent) {
                const markOffsetTop = firstMark.offsetTop;
                scrollableParent.scrollTo({ top: markOffsetTop - scrollableParent.offsetTop, behavior: 'smooth' });
            }
        }
    }, [content]);

    function getScrollableParent(element) {
        let parent = element.parentNode;
        while (parent) {
            const overflowY = window.getComputedStyle(parent).overflowY;
            const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';
            if (isScrollable && parent.scrollHeight > parent.clientHeight) {
                return parent;
            }
            parent = parent.parentNode;
        }
        return null;
    }

    return (
        <div className='card card-custom'>
            <div className='card-header card-header-stretch'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent flex-nowrap' role='tablist'>
                    <li className='nav-item fs-4'>
                        <h5
                            className={clsx('nav-link cursor-pointer fw-semibold', { 'active text-dark fw-bold': activeTab === 'Document' })}
                            onClick={() => setActiveTab('Document')}
                            role='tab'
                        >
                            Document
                        </h5>
                    </li>
                    <li className='nav-item fs-4'>
                        <h5
                            className={clsx('nav-link cursor-pointer fw-semibold', { 'active text-dark fw-bold': activeTab === 'LeximQ&A' })}
                            onClick={() => setActiveTab('LeximQ&A')}
                            role='tab'
                        >
                            Lexim Query
                        </h5>
                    </li>
                </ul>
            </div>
            <div className='card-body'>
                <div className='tab-content'>
                    <div className={clsx('tab-pane', { active: activeTab === 'Document' })}>
                        <div className="container p-3 text-wrap vh-100 xml-file-viewer-container d-flex flex-row">
                            <div className="card-body text-wrap w-100"
                                style={{
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                    whiteSpace: 'pre-wrap',
                                    boxShadow: '1px 0 1px -1px rgba(0,0,0,0.25)',
                                    maxHeight: 'calc(100vh)'
                                }}
                                data-kt-scroll='true'
                                data-kt-scroll-height='auto'
                            >
                                <div style={{ height: '90vh' }}>

                                    {alert.message && (
                                        <div className={`alert alert-${alert.type} alert-dismissible fade show mt-3`} role="alert">
                                            {alert.message}
                                            <button type="button" className="btn-close" onClick={() => setAlert({ message: '', type: '' })} aria-label="Close"></button>
                                        </div>
                                    )}
                                    {isLoading && <HourGlass />}
                                    <div id="pdf-div-document" className="full-window-div" style={{ width: '100%', height: '95%' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={clsx('tab-pane', { active: activeTab === 'LeximQ&A' })}>
                        <div>
                            <div className="container p-3 text-wrap mx-auto vh-100 xml-file-viewer-container d-flex flex-row">
                                <div className={`card-body text-wrap col-6`}
                                    style={{
                                        overflowY: 'auto',
                                        overflowX: 'hidden',
                                        whiteSpace: 'pre-wrap',
                                        boxShadow: '1px 0 1px -1px rgba(0,0,0,0.25)',
                                        maxHeight: 'calc(100vh)',
                                        flex: 2
                                    }}
                                    data-kt-scroll='true'
                                    data-kt-scroll-height='auto'
                                >
                                    <div style={{ height: '90vh' }}>
                                        {isLoading && <HourGlass />}
                                        {alert.message && (
                                            <div className={`alert alert-${alert.type} alert-dismissible fade show mt-3`} role="alert">
                                                {alert.message}
                                                <button type="button" className="btn-close" onClick={() => setAlert({ message: '', type: '' })} aria-label="Close"></button>
                                            </div>
                                        )}
                                        <div id="pdf-div-lexim" className="full-window-div" style={{ width: '100%', height: '95%' }} />
                                    </div>
                                </div>
                                <QAInterface chatIsOpen={isChatOpen} className='col-6' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FileViewer;