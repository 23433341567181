import React, { useState, useEffect, useRef } from 'react'
import { IOrgDetails, getOrgDetailsInitValues } from '../SettingsModel'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { useAuth } from '../../../../../modules/auth'
// import Tooltip from 'react-bootstrap/Tooltip';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Modal, Button } from 'react-bootstrap';
import { KTIcon, toAbsoluteUrl } from '../../../../../../_metronic/helpers';
// import { updateOrganizationLogo } from '../../../../../../_metronic/redux/account/actions';
import HourGlass from '../../../../Loading/HourGlassSpinner/HourGlass';

const profileDetailsSchema = Yup.object().shape({
  name: Yup.string().required('name is required'),
  primaryuser: Yup.number().required('admin Id is required'),
  website: Yup.string(),
  industry: Yup.string(),
  address: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  region: Yup.string(),
  // company: Yup.string().required('Company name is required'),
  country: Yup.string(),
  zip: Yup.string(),
  phone: Yup.string(),
  language: Yup.string(),
  // timeZone: Yup.string().required('Time zone is required'),
  // currency: Yup.string().required('Currency is required'),
})

const defaultOrgDetails: IOrgDetails = {
  avatar: '/media/avatars/300-1.jpg',
  name: '',
  primaryuser: '',
  fullname: '',
  industry: '',
  phone: '',
  website: '',
  country: '',
  language: '',
  timeZone: '',
  allowMarketing: false,
  address: '',
  city: '',
  state: '',
  region: '',
  zip: '',

};

const ProfileDetails: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<IOrgDetails | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const dispatch = useDispatch();
  const organizationLogo = useSelector((state: any) => state.account.organizationLogo);
  const [logo, setLogo] = useState(organizationLogo !== 'none' ? organizationLogo : toAbsoluteUrl('/media/avatars/lexim_logo.png'));
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const { currentUser } = useAuth();

  useEffect(() => {
    setLogo(organizationLogo !== 'none' ? organizationLogo : toAbsoluteUrl('/media/avatars/lexim_logo.png'));
  }, [organizationLogo]);

  const formik = useFormik<IOrgDetails>({
    initialValues: data || defaultOrgDetails,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true);

      try {
        // Convert values to a JSON string
        const data = {
          columns: {
            name: values.name,
            website: values.website,
            industry: values.industry,
            address: values.address,
            city: values.city,
            state: values.state,
            region: values.region,
            country: values.country,
            zip: values.zip,
            phone: values.phone,
            language: values.language
          }
        };
        const jsonData = JSON.stringify(data);

        // Send a PATCH request to your backend API with the JSON data
        const response = await axios.patch(`/reg/v1/admin/organization/${currentUser?.organization}`, jsonData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        // console.log(data)

        if (response.status === 200) {
          // Request was successful, update the state or perform any other actions
          // you want to take when the update is successful.
          dispatch({ type: 'UPDATE_ORGANIZATION', organization: values });
          setModalMessage('Organization Settings updated successfully!');
          setShowModal(true);
        } else {
          // Handle errors if the response status is not 200.
          setModalMessage('Failed to update organization settings.');
          console.error('Failed to update organization settings:', response.data);
          setShowModal(true);
        }
      } catch (error) {
        // Handle network errors or exceptions here.
        console.error('Failed to update Organization Settings:', error);
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    // Fetch the initial values when the component mounts
    getOrgDetailsInitValues(currentUser?.organization)
      .then((initialValues) => {
        if (initialValues) {
          setData(initialValues);
          formik.setValues(initialValues); // Set Formik's initialValues
          // console.log('initialvalues:', data);
        }
        setIsLoading(false); // Set loading to false after data is fetched
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setIsLoading(false); // Set loading to false on error
      });
  }, [currentUser?.organization, formik]);


  const handleProfilePictureChange = () => {
    // console.log("opening files")
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
    setDropdownOpen(!dropdownOpen);
  };


  const uploadImage = async (file: File, orgId) => {
    const formData = new FormData();
    if (file) {
      formData.append('file', file);
      // console.log("formData", formData.get('file'));
    }

    // console.log("orgId", orgId);
    try {
      const response = await axios.post(`/reg/v1/admin/organization/logo/${orgId}`, formData);
      // console.log("response", response);
      return response.data;
    } catch (error: any) {
      throw error;
    }
  };


  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    // console.log("file read is", file);
    reader.onloadend = () => {
      setLogo(reader.result as string);
      dispatch({ type: 'UPDATE_ORGANIZATION_LOGO', organizationLogo: reader.result as string });
    };
    if (file) {
      reader.readAsDataURL(file);
      try {
        console.log("uploading image")
        const response = await uploadImage(file, currentUser?.organization);
        response.status === 200 ? console.log("") : console.log("");
        // console.log("final response",response);
      } catch (error) {
        console.error('Image upload failed:', error);
      }
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Organization Settings</h3>
          {/* {loading ? <div className="spinner-border text-primary ms-4 mb-1" role="status">
            <span className="visually-hidden">Loading...</span>
          </div> : null} */}
        </div>
      </div>


      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            {isLoading ? (
              <HourGlass height="40vh" />
            ) : (
              <>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Organization Logo</label>
                  <div className='col-lg-8'>
                    <div
                      className='image-input image-input-outline'
                      data-kt-image-input='true'
                    >
                      <img src={logo} alt='M' style={{ backgroundColor: 'gray', maxWidth: '100px', maxHeight: '100px' }} />
                      <button className="btn btn-sm position-absolute bottom-0 end-0" onClick={toggleDropdown}><KTIcon iconName='pencil' className='fs-1 text-white' /></button>
                      <input type="file" ref={fileInputRef} onChange={handleFileChange} accept="image/*" style={{ display: 'none' }} />
                      {dropdownOpen && (
                        <div className="dropdown-menu show">
                          <button className="dropdown-item" onClick={handleProfilePictureChange}>Upload Logo</button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Organization Name</label>

                  <div className='col-lg-5 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Organization name'
                      {...formik.getFieldProps('name')}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.name}</div>
                      </div>
                    )}
                  </div>


                </div>

                {/* <div className='row mb-6'>
              <OverlayTrigger
                placement="top-start"
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    User ID for Admin can be found in the User Tab
                  </Tooltip>
                }
              >
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Admin User ID</label>
              </OverlayTrigger>

              <div className='col-lg-5 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Primary User'
                  {...formik.getFieldProps('primaryuser')}
                />
                {formik.touched.primaryuser && formik.errors.primaryuser && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.primaryuser}</div>
                  </div>
                )}
              </div>

            </div> */}
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className=''>Organization Website</span>
                  </label>

                  <div className='col-lg-5 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Company website'
                      {...formik.getFieldProps('website')}
                    />
                    {formik.touched.website && formik.errors.website && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.website}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className=''>Contact Phone</span>
                  </label>

                  <div className='col-lg-5 fv-row'>
                    <input
                      type='tel'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Phone number'
                      {...formik.getFieldProps('phone')}
                    />
                    {formik.touched.phone && formik.errors.phone && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.phone}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label  fw-bold fs-6'>Industry</label>

                  <div className='col-lg-5 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Industry'
                      {...formik.getFieldProps('industry')}
                    />
                    {formik.touched.industry && formik.errors.industry && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.industry}</div>
                      </div>
                    )}
                  </div>

                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label  fw-bold fs-6'>Address</label>

                  <div className='col-lg-5 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Address'
                      {...formik.getFieldProps('address')}
                    />
                    {formik.touched.address && formik.errors.address && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.address}</div>
                      </div>
                    )}
                  </div>

                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label  fw-bold fs-6'>City</label>

                  <div className='col-lg-5 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='City'
                      {...formik.getFieldProps('city')}
                    />
                    {formik.touched.city && formik.errors.city && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.city}</div>
                      </div>
                    )}
                  </div>


                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>State</label>

                  <div className='col-lg-5 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='State'
                      {...formik.getFieldProps('state')}
                    />
                    {formik.touched.state && formik.errors.state && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.state}</div>
                      </div>
                    )}
                  </div>


                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label  fw-bold fs-6'>Zip</label>
                  <div className='col-lg-5 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Zip Code'
                      {...formik.getFieldProps('zip')}
                    />
                    {formik.touched.zip && formik.errors.zip && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.zip}</div>
                      </div>
                    )}
                  </div>


                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label  fw-bold fs-6'>Region</label>

                  <div className='col-lg-5 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Region'
                      {...formik.getFieldProps('region')}
                    />
                    {formik.touched.region && formik.errors.region && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.region}</div>
                      </div>
                    )}
                  </div>


                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span>Country</span>
                  </label>

                  <div className='col-lg-5 fv-row'>
                    <select
                      className='form-select form-select-solid form-select-lg'
                      {...formik.getFieldProps('country')}
                    >
                      <option value=''>Select a Country...</option>
                      <option value='AF'>Afghanistan</option>
                      <option value='AX'>Aland Islands</option>
                      <option value='AL'>Albania</option>
                      <option value='DZ'>Algeria</option>
                      <option value='AS'>American Samoa</option>
                      <option value='AD'>Andorra</option>
                      <option value='AO'>Angola</option>
                      <option value='AI'>Anguilla</option>
                      <option value='AQ'>Antarctica</option>
                      <option value='AG'>Antigua and Barbuda</option>
                      <option value='AR'>Argentina</option>
                      <option value='AM'>Armenia</option>
                      <option value='AW'>Aruba</option>
                      <option value='AU'>Australia</option>
                      <option value='AT'>Austria</option>
                      <option value='AZ'>Azerbaijan</option>
                      <option value='BS'>Bahamas</option>
                      <option value='BH'>Bahrain</option>
                      <option value='BD'>Bangladesh</option>
                      <option value='BB'>Barbados</option>
                      <option value='BY'>Belarus</option>
                      <option value='BE'>Belgium</option>
                      <option value='BZ'>Belize</option>
                      <option value='BJ'>Benin</option>
                      <option value='BM'>Bermuda</option>
                      <option value='BT'>Bhutan</option>
                      <option value='BO'>Bolivia, Plurinational State of</option>
                      <option value='BQ'>Bonaire, Sint Eustatius and Saba</option>
                      <option value='BA'>Bosnia and Herzegovina</option>
                      <option value='BW'>Botswana</option>
                      <option value='BV'>Bouvet Island</option>
                      <option value='BR'>Brazil</option>
                      <option value='IO'>British Indian Ocean Territory</option>
                      <option value='BN'>Brunei Darussalam</option>
                      <option value='BG'>Bulgaria</option>
                      <option value='BF'>Burkina Faso</option>
                      <option value='BI'>Burundi</option>
                      <option value='KH'>Cambodia</option>
                      <option value='CM'>Cameroon</option>
                      <option value='CA'>Canada</option>
                      <option value='CV'>Cape Verde</option>
                      <option value='KY'>Cayman Islands</option>
                      <option value='CF'>Central African Republic</option>
                      <option value='TD'>Chad</option>
                      <option value='CL'>Chile</option>
                      <option value='CN'>China</option>
                      <option value='CX'>Christmas Island</option>
                      <option value='CC'>Cocos (Keeling) Islands</option>
                      <option value='CO'>Colombia</option>
                      <option value='KM'>Comoros</option>
                      <option value='CG'>Congo</option>
                      <option value='CD'>Congo, the Democratic Republic of the</option>
                      <option value='CK'>Cook Islands</option>
                      <option value='CR'>Costa Rica</option>
                      <option value='CI'>Côte d'Ivoire</option>
                      <option value='HR'>Croatia</option>
                      <option value='CU'>Cuba</option>
                      <option value='CW'>Curaçao</option>
                      <option value='CY'>Cyprus</option>
                      <option value='CZ'>Czech Republic</option>
                      <option value='DK'>Denmark</option>
                      <option value='DJ'>Djibouti</option>
                      <option value='DM'>Dominica</option>
                      <option value='DO'>Dominican Republic</option>
                      <option value='EC'>Ecuador</option>
                      <option value='EG'>Egypt</option>
                      <option value='SV'>El Salvador</option>
                      <option value='GQ'>Equatorial Guinea</option>
                      <option value='ER'>Eritrea</option>
                      <option value='EE'>Estonia</option>
                      <option value='ET'>Ethiopia</option>
                      <option value='FK'>Falkland Islands (Malvinas)</option>
                      <option value='FO'>Faroe Islands</option>
                      <option value='FJ'>Fiji</option>
                      <option value='FI'>Finland</option>
                      <option value='FR'>France</option>
                      <option value='GF'>French Guiana</option>
                      <option value='PF'>French Polynesia</option>
                      <option value='TF'>French Southern Territories</option>
                      <option value='GA'>Gabon</option>
                      <option value='GM'>Gambia</option>
                      <option value='GE'>Georgia</option>
                      <option value='DE'>Germany</option>
                      <option value='GH'>Ghana</option>
                      <option value='GI'>Gibraltar</option>
                      <option value='GR'>Greece</option>
                      <option value='GL'>Greenland</option>
                      <option value='GD'>Grenada</option>
                      <option value='GP'>Guadeloupe</option>
                      <option value='GU'>Guam</option>
                      <option value='GT'>Guatemala</option>
                      <option value='GG'>Guernsey</option>
                      <option value='GN'>Guinea</option>
                      <option value='GW'>Guinea-Bissau</option>
                      <option value='GY'>Guyana</option>
                      <option value='HT'>Haiti</option>
                      <option value='HM'>Heard Island and McDonald Islands</option>
                      <option value='VA'>Holy See (Vatican City State)</option>
                      <option value='HN'>Honduras</option>
                      <option value='HK'>Hong Kong</option>
                      <option value='HU'>Hungary</option>
                      <option value='IS'>Iceland</option>
                      <option value='IN'>India</option>
                      <option value='ID'>Indonesia</option>
                      <option value='IR'>Iran, Islamic Republic of</option>
                      <option value='IQ'>Iraq</option>
                      <option value='IE'>Ireland</option>
                      <option value='IM'>Isle of Man</option>
                      <option value='IL'>Israel</option>
                      <option value='IT'>Italy</option>
                      <option value='JM'>Jamaica</option>
                      <option value='JP'>Japan</option>
                      <option value='JE'>Jersey</option>
                      <option value='JO'>Jordan</option>
                      <option value='KZ'>Kazakhstan</option>
                      <option value='KE'>Kenya</option>
                      <option value='KI'>Kiribati</option>
                      <option value='KP'>Korea, Democratic People's Republic of</option>
                      <option value='KW'>Kuwait</option>
                      <option value='KG'>Kyrgyzstan</option>
                      <option value='LA'>Lao People's Democratic Republic</option>
                      <option value='LV'>Latvia</option>
                      <option value='LB'>Lebanon</option>
                      <option value='LS'>Lesotho</option>
                      <option value='LR'>Liberia</option>
                      <option value='LY'>Libya</option>
                      <option value='LI'>Liechtenstein</option>
                      <option value='LT'>Lithuania</option>
                      <option value='LU'>Luxembourg</option>
                      <option value='MO'>Macao</option>
                      <option value='MK'>Macedonia, the former Yugoslav Republic of</option>
                      <option value='MG'>Madagascar</option>
                      <option value='MW'>Malawi</option>
                      <option value='MY'>Malaysia</option>
                      <option value='MV'>Maldives</option>
                      <option value='ML'>Mali</option>
                      <option value='MT'>Malta</option>
                      <option value='MH'>Marshall Islands</option>
                      <option value='MQ'>Martinique</option>
                      <option value='MR'>Mauritania</option>
                      <option value='MU'>Mauritius</option>
                      <option value='YT'>Mayotte</option>
                      <option value='MX'>Mexico</option>
                      <option value='FM'>Micronesia, Federated States of</option>
                      <option value='MD'>Moldova, Republic of</option>
                      <option value='MC'>Monaco</option>
                      <option value='MN'>Mongolia</option>
                      <option value='ME'>Montenegro</option>
                      <option value='MS'>Montserrat</option>
                      <option value='MA'>Morocco</option>
                      <option value='MZ'>Mozambique</option>
                      <option value='MM'>Myanmar</option>
                      <option value='NA'>Namibia</option>
                      <option value='NR'>Nauru</option>
                      <option value='NP'>Nepal</option>
                      <option value='NL'>Netherlands</option>
                      <option value='NC'>New Caledonia</option>
                      <option value='NZ'>New Zealand</option>
                      <option value='NI'>Nicaragua</option>
                      <option value='NE'>Niger</option>
                      <option value='NG'>Nigeria</option>
                      <option value='NU'>Niue</option>
                      <option value='NF'>Norfolk Island</option>
                      <option value='MP'>Northern Mariana Islands</option>
                      <option value='NO'>Norway</option>
                      <option value='OM'>Oman</option>
                      <option value='PK'>Pakistan</option>
                      <option value='PW'>Palau</option>
                      <option value='PS'>Palestinian Territory, Occupied</option>
                      <option value='PA'>Panama</option>
                      <option value='PG'>Papua New Guinea</option>
                      <option value='PY'>Paraguay</option>
                      <option value='PE'>Peru</option>
                      <option value='PH'>Philippines</option>
                      <option value='PN'>Pitcairn</option>
                      <option value='PL'>Poland</option>
                      <option value='PT'>Portugal</option>
                      <option value='PR'>Puerto Rico</option>
                      <option value='QA'>Qatar</option>
                      <option value='RE'>Réunion</option>
                      <option value='RO'>Romania</option>
                      <option value='RU'>Russian Federation</option>
                      <option value='RW'>Rwanda</option>
                      <option value='BL'>Saint Barthélemy</option>
                      <option value='SH'>Saint Helena, Ascension and Tristan da Cunha</option>
                      <option value='KN'>Saint Kitts and Nevis</option>
                      <option value='LC'>Saint Lucia</option>
                      <option value='MF'>Saint Martin (French part)</option>
                      <option value='PM'>Saint Pierre and Miquelon</option>
                      <option value='VC'>Saint Vincent and the Grenadines</option>
                      <option value='WS'>Samoa</option>
                      <option value='SM'>San Marino</option>
                      <option value='ST'>Sao Tome and Principe</option>
                      <option value='SA'>Saudi Arabia</option>
                      <option value='SN'>Senegal</option>
                      <option value='RS'>Serbia</option>
                      <option value='SC'>Seychelles</option>
                      <option value='SL'>Sierra Leone</option>
                      <option value='SG'>Singapore</option>
                      <option value='SX'>Sint Maarten (Dutch part)</option>
                      <option value='SK'>Slovakia</option>
                      <option value='SI'>Slovenia</option>
                      <option value='SB'>Solomon Islands</option>
                      <option value='SO'>Somalia</option>
                      <option value='ZA'>South Africa</option>
                      <option value='GS'>South Georgia and the South Sandwich Islands</option>
                      <option value='KR'>South Korea</option>
                      <option value='SS'>South Sudan</option>
                      <option value='ES'>Spain</option>
                      <option value='LK'>Sri Lanka</option>
                      <option value='SD'>Sudan</option>
                      <option value='SR'>Suriname</option>
                      <option value='SJ'>Svalbard and Jan Mayen</option>
                      <option value='SZ'>Swaziland</option>
                      <option value='SE'>Sweden</option>
                      <option value='CH'>Switzerland</option>
                      <option value='SY'>Syrian Arab Republic</option>
                      <option value='TW'>Taiwan, Province of China</option>
                      <option value='TJ'>Tajikistan</option>
                      <option value='TZ'>Tanzania, United Republic of</option>
                      <option value='TH'>Thailand</option>
                      <option value='TL'>Timor-Leste</option>
                      <option value='TG'>Togo</option>
                      <option value='TK'>Tokelau</option>
                      <option value='TO'>Tonga</option>
                      <option value='TT'>Trinidad and Tobago</option>
                      <option value='TN'>Tunisia</option>
                      <option value='TR'>Turkey</option>
                      <option value='TM'>Turkmenistan</option>
                      <option value='TC'>Turks and Caicos Islands</option>
                      <option value='TV'>Tuvalu</option>
                      <option value='UG'>Uganda</option>
                      <option value='UA'>Ukraine</option>
                      <option value='AE'>United Arab Emirates</option>
                      <option value='GB'>United Kingdom</option>
                      <option value='US'>United States</option>
                      <option value='UY'>Uruguay</option>
                      <option value='UZ'>Uzbekistan</option>
                      <option value='VU'>Vanuatu</option>
                      <option value='VE'>Venezuela, Bolivarian Republic of</option>
                      <option value='VN'>Vietnam</option>
                      <option value='VI'>Virgin Islands</option>
                      <option value='WF'>Wallis and Futuna</option>
                      <option value='EH'>Western Sahara</option>
                      <option value='YE'>Yemen</option>
                      <option value='ZM'>Zambia</option>
                      <option value='ZW'>Zimbabwe</option>
                    </select>
                    {formik.touched.country && formik.errors.country && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.country}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Language</label>
                  <div className='col-lg-5 fv-row'>
                    <select
                      className='form-select form-select-solid form-select-lg'
                      {...formik.getFieldProps('language')}
                    >
                      <option value=''>Select a Language...</option>
                      <option value='id'>Bahasa Indonesia - Indonesian</option>
                      <option value='msa'>Bahasa Melayu - Malay</option>
                      <option value='ca'>Català - Catalan</option>
                      <option value='cs'>Čeština - Czech</option>
                      <option value='da'>Dansk - Danish</option>
                      <option value='de'>Deutsch - German</option>
                      <option value='en'>English</option>
                      <option value='en-gb'>English UK - British English</option>
                      <option value='es'>Español - Spanish</option>
                      <option value='fil'>Filipino</option>
                      <option value='fr'>Français - French</option>
                      <option value='ga'>Gaeilge - Irish (beta)</option>
                      <option value='gl'>Galego - Galician (beta)</option>
                      <option value='hr'>Hrvatski - Croatian</option>
                      <option value='it'>Italiano - Italian</option>
                      <option value='hu'>Magyar - Hungarian</option>
                      <option value='nl'>Nederlands - Dutch</option>
                      <option value='no'>Norsk - Norwegian</option>
                      <option value='pl'>Polski - Polish</option>
                      <option value='pt'>Português - Portuguese</option>
                      <option value='ro'>Română - Romanian</option>
                      <option value='sk'>Slovenčina - Slovak</option>
                      <option value='fi'>Suomi - Finnish</option>
                      <option value='sv'>Svenska - Swedish</option>
                      <option value='vi'>Tiếng Việt - Vietnamese</option>
                      <option value='tr'>Türkçe - Turkish</option>
                      <option value='el'>Ελληνικά - Greek</option>
                      <option value='bg'>Български език - Bulgarian</option>
                      <option value='ru'>Русский - Russian</option>
                      <option value='sr'>Српски - Serbian</option>
                      <option value='uk'>Українська мова - Ukrainian</option>
                      <option value='he'>עִבְרִית - Hebrew</option>
                      <option value='ur'>اردو - Urdu (beta)</option>
                      <option value='ar'>العربية - Arabic</option>
                      <option value='fa'>فارسی - Persian</option>
                      <option value='mr'>मराठी - Marathi</option>
                      <option value='hi'>हिन्दी - Hindi</option>
                      <option value='bn'>বাংলা - Bangla</option>
                      <option value='gu'>ગુજરાતી - Gujarati</option>
                      <option value='ta'>தமிழ் - Tamil</option>
                      <option value='kn'>ಕನ್ನಡ - Kannada</option>
                      <option value='th'>ภาษาไทย - Thai</option>
                      <option value='ko'>한국어 - Korean</option>
                      <option value='ja'>日本語 - Japanese</option>
                      <option value='zh-cn'>简体中文 - Simplified Chinese</option>
                      <option value='zh-tw'>繁體中文 - Traditional Chinese</option>
                    </select>
                    {formik.touched.language && formik.errors.language && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.language}</div>
                      </div>
                    )}

                    <div className='form-text'>
                      Please select a preferred language.
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={isLoading || loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div >
      <Modal show={showModal} onHide={() => setShowModal(false)} dialogClassName="modal-dialog-centered">
        <Modal.Header closeButton />
        <Modal.Body className="d-flex justify-content-center fs-2">
          {modalMessage === 'Organization Settings updated successfully!' ? (
            <div>
              <i className="bi bi-check-circle-fill text-success fs-1 me-3"></i>
              {modalMessage}
            </div>
          ) : (
            <div>
              <i className="bi bi-x-circle text-danger fs-1 me-3"></i>
              {modalMessage}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div >
  )
}

export { ProfileDetails }