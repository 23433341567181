import React from 'react';

const DocumentFilter = ({
    filters,
    setFilters,
    onApplyFilters,
    onResetFilters,
    filterConfig,
}) => {
    // Handle filter changes dynamically
    const handleFilterChange = (key, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [key]: value,
        }));
    };

    // Render a form element based on the field type
    const renderFilterField = (field) => {
        switch (field.type) {
            case 'checkbox':
                return (
                    <div className="mt-1 mb-5 form-check form-check-custom form-check-solid">
                        <label className="form-label fw-bold" htmlFor={field.id}>
                            {field.label}
                        </label>
                        <input
                            className="form-check-input ms-2"
                            type="checkbox"
                            id={field.id}
                            checked={filters[field.id]}
                            onChange={(e) =>
                                handleFilterChange(field.id, e.target.checked)
                            }
                        />
                    </div>
                );
            case 'text':
                return (
                    <div className="mb-4">
                        <label className="form-label fw-bold">{field.label}</label>
                        <input
                            type="text"
                            className="form-control form-control-solid"
                            value={filters[field.id]}
                            placeholder={field.placeholder}
                            onChange={(e) =>
                                handleFilterChange(field.id, e.target.value)
                            }
                        />
                    </div>
                );
            case 'date':
                return (
                    <div>
                        {field.label !== '' &&
                            <label className="form-label fw-bold" htmlFor={field.id}>
                                {field.label}
                            </label>
                        }
                        <input
                            type="date"
                            className="form-control form-control-solid mb-3"
                            value={filters[field.id]}
                            onChange={(e) =>
                                handleFilterChange(field.id, e.target.value)
                            }
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true">
            <div className="px-7 py-5">
                <div className="fs-5 text-dark fw-bolder">Filter Options</div>
            </div>
            <div className="separator border-gray-200"></div>
            <div className="px-7 py-5" style={{ maxHeight: '300px', overflow: 'auto' }}>
                {filterConfig.map((field, index) => (
                    <div key={field.id || index}>
                        {renderFilterField(field)}
                    </div>
                ))}
            </div>
            <div className="px-7 pb-7">
                <div className="d-flex justify-content-end">
                    <button
                        type="reset"
                        className="btn btn-sm btn-light btn-active-light-primary me-2"
                        onClick={onResetFilters}
                    >
                        Reset
                    </button>
                    <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={onApplyFilters}
                    >
                        Apply
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DocumentFilter;
