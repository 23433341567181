import { useState } from 'react';
import clsx from 'clsx';
import RegulatoryTable from './DataOperations/RegulatoryTable';
import FDATable from './DataOperations/FDATable';
import ElasticSearchTable from './DataOperations/ElasticSearchTable';
import GenAITable from './DataOperations/GenAITable';
import SentimentTable from './DataOperations/SentimentTable';
import UpdateAnalytics from './DataOperations/UpdateAnalytics';
import { useParams, useNavigate } from 'react-router-dom';

const DataOperations = () => {
    const { tab: pathTab } = useParams();
    const navigate = useNavigate();
    const initialTab = pathTab || 'regulatory';
    const [tab, setTab] = useState(initialTab);

    const handleTabClick = (newTab) => {
        setTab(newTab);
        navigate(`/super-admin/data-operations/${newTab}`);
    };

    // useEffect(() => {
    //     // console.log('tab', tab);
    //     setIsLoading(true);
    // }, [tab]);

    return (
        <div>
            <div className="mb-n6" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', maxWidth: '100%' }}>
            </div>
            <div className='card'>
                <div className='card-header card-header-stretch overflow-auto'>
                    <ul
                        className='nav nav-stretch nav-line-tabs
                        nav-line-tabs-2x
                        border-transparent
                        flex-nowrap'
                        role='tablist'
                    >
                        <li className='nav-item fs-4 '>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'regulatory' })}
                                onClick={() => handleTabClick('regulatory')}
                                role='tab'
                            >
                                Regulatory
                            </h5>
                        </li>
                        <li className='nav-item fs-4 '>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'elastic-search' })}
                                onClick={() => handleTabClick('elastic-search')}
                                role='tab'
                            >
                                Elastic Search
                            </h5>
                        </li>
                        <li className='nav-item fs-4 '>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'gen-ai' })}
                                onClick={() => handleTabClick('gen-ai')}
                                role='tab'
                            >
                                Gen AI
                            </h5>
                        </li>
                        <li className='nav-item fs-4 '>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'sentiment-analysis' })}
                                onClick={() => handleTabClick('sentiment-analysis')}
                                role='tab'
                            >
                                Sentiment Analysis
                            </h5>
                        </li>
                        <li className='nav-item fs-4 '>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'FDA' })}
                                onClick={() => handleTabClick('FDA')}
                                role='tab'
                            >
                                FDA Devices
                            </h5>
                        </li>
                        <li className='nav-item fs-4 '>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'update-analytics' })}
                                onClick={() => handleTabClick('update-analytics')}
                                role='tab'
                            >
                                Update Analytics
                            </h5>
                        </li>
                    </ul>
                </div>
                {/* {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center" >
                        <div className="spinner-border fs-1 mt-10 mb-10" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : ( */}
                <div className='card-body'>
                    <div className='tab-content'>
                        {tab === 'regulatory' && (
                            <div className='tab-pane active'>
                                <RegulatoryTable />
                            </div>
                        )}
                        {tab === 'elastic-search' && (
                            <div className='tab-pane active'>
                                <ElasticSearchTable />
                            </div>
                        )}
                        {tab === 'gen-ai' && (
                            <div className='tab-pane active'>
                                <GenAITable />
                            </div>
                        )}
                        {tab === 'sentiment-analysis' && (
                            <div className='tab-pane active'>
                                <SentimentTable />
                            </div>
                        )}
                        {tab === 'FDA' && (
                            <div className='tab-pane active'>
                                <FDATable />
                            </div>
                        )}
                        {tab === 'update-analytics' && (
                            <div className='tab-pane active'>
                                <UpdateAnalytics />
                            </div>
                        )}
                    </div>
                </div>
                {/* )} */}
            </div>
        </div>
    );
}
export default DataOperations;