import React from 'react';
import { KTIcon } from '../../../../../_metronic/helpers';
import AnnouncementFilter from './AnnouncementFilter';
import SearchBar from '../../../../components/SearchBar';
import AnnouncementsPage from './AnnoucementsPage';


const AnnouncementToolbar = ({
    searchInput,
    setSearchInput,
    filters,
    fetchAnnouncements,
    handleFilterChange,
    handleApplyFilters,
    handleResetFilters
}) => {
    return (
        <div className="d-flex flex-wrap gap-4 flex-row-fluid px-5 mb-7 align-items-center">
            <div className="col-7">
                <SearchBar
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    placeholder={"Search Announcements"}
                />
            </div>
            <div className="d-flex flex-column-auto">
                <button
                    type='button'
                    className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                >
                    <KTIcon iconName='filter' className='fs-2' /> Filter
                </button>
                <AnnouncementFilter
                    filters={filters}
                    onFilterChange={handleFilterChange}
                    onApplyFilters={handleApplyFilters}
                    onResetFilters={handleResetFilters}
                />
            </div>
            {/* {isLoading ?
                <div className="spinner-border spinner-border-lg text-primary ms-1" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                : null
            } */}
            <div className="ms-auto align-self-center">
                <AnnouncementsPage fetchAnnouncements={fetchAnnouncements} />
            </div>
        </div>
    );
};

export default AnnouncementToolbar;