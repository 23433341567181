import React, { useEffect, useRef } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';

const FolderSuccessModal = ({ showToast, message, onClose }) => {
    const toastIdRef = useRef<string | null>(null); // Keeps track of the current toast ID
    const timerRef = useRef<NodeJS.Timeout | null>(null); // Timer reference to auto dismiss after 5 seconds
    const hasToastBeenShownRef = useRef<boolean>(false); // Flag to track if a toast has been shown already

    useEffect(() => {
        if (showToast && !hasToastBeenShownRef.current) {
            // Mark that a toast has been shown
            hasToastBeenShownRef.current = true;

            const toastId = uuidv4();
            toastIdRef.current = toastId;

            const isSuccess = message.includes('successfully!');

            // Dismiss previous toast if it exists
            if (toastIdRef.current) {
                toast.dismiss(toastIdRef.current);
            }

            const closeToast = () => {
                toast.dismiss(toastId); // Dismiss this toast explicitly when close button is clicked
                onClose(); // Call the onClose function from the parent
                hasToastBeenShownRef.current = false; // Reset the flag once the toast is closed
            };

            // Show the new toast
            toast(
                <div>
                    <div className="d-flex align-items-center text-center p-5">
                        {isSuccess ? (
                            <i className="bi bi-check-circle-fill text-success fs-1 me-5"></i>
                        ) : (
                            <i className="bi bi-x-circle text-danger fs-1 me-5"></i>
                        )}
                        <div className="text-start">
                            {message.split('\n').map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                    <button onClick={closeToast} className="btn-close position-absolute top-0 end-0 m-2 me-5 mt-5"></button>
                </div>,
                {
                    position: "bottom-right",
                    autoClose: false,
                    closeOnClick: false,
                    draggable: false,
                    closeButton: false,
                    pauseOnHover: false,
                    style: {
                        width: '350px',
                        height: isSuccess || message.includes("empty") ? '100px' : '125px',
                        zIndex: 9999
                    },
                    className: "custom-toast-container",
                    toastId: toastId, // Ensure unique toast ID
                }
            );

            // Automatically dismiss the toast after 5 seconds
            timerRef.current = setTimeout(() => {
                toast.dismiss(toastId); // Dismiss the toast after 5 seconds
                onClose(); // Call the onClose function from the parent
                hasToastBeenShownRef.current = false; // Reset the flag once the toast is auto dismissed
            }, 5000);

            // Cleanup function to clear the timeout
            return () => {
                if (timerRef.current) {
                    clearTimeout(timerRef.current);
                    // Ensure the toast is dismissed in case of early cleanup
                    if (toastIdRef.current) {
                        toast.dismiss(toastIdRef.current);
                    }
                }
            };
        }
    }, [showToast, message, onClose]); // Only trigger effect when showToast or message changes

    return <ToastContainer />;
};

export default FolderSuccessModal;
