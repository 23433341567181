import React, { useCallback } from "react";
import moment from "moment-timezone"; // Move moment import outside the component

export default function DocumentCard({
  docTitle,
  docTopics,
  docPostedDate,
  docKeywords,
  docType,
  showType = true,
  showTopics = true,
  showKeywords = true,
  showDates = true,
  height = "115px"
}) {
  // Format timestamp to 'YYYY-MM-DD' in UTC
  const formatDate = useCallback((timestamp) => {
    return moment.utc(timestamp).format('YYYY-MM-DD'); // Format in UTC
  }, []);

  return (
    <div className="card mt-3 mb-4">
      <div className="card-body d-flex align-items-center">
        <div className="position-relative flex-wrap w-100" style={{ height: height }}>
          <h4 className="text-gray-800 fw-bold mb-3">
            {docTitle || "No Title Available"}
          </h4>

          {showType && (
            <div className="d-flex align-items-center mb-1">
              <h5 className="card-title text-gray-600">
                Type:{" "}
                {docType || "No Keywords Available"}
              </h5>
            </div>
          )}
          {/* Conditionally render topics */}
          {showTopics && (
            <div className="d-flex align-items-center mb-1">
              <h5 className="card-title text-gray-600">
                Topics: {" "}

                {docTopics || "No Topics Available"}

              </h5>
            </div>
          )}

          {/* Conditionally render keywords */}
          {showKeywords && (
            <div className="d-flex align-items-center mb-1">
              <h5 className="card-title text-gray-600">
                Keywords:{" "}
                {docKeywords || "No Keywords Available"}
              </h5>
            </div>
          )}

          {/* Conditionally render dates */}
          {showDates && (
            <div className="d-flex align-items-center mb-1">
              <h5 className="card-title text-gray-600">
                Posted Date:{" "}
                {docPostedDate
                  ? formatDate(docPostedDate)
                  : "No Date Available"}
              </h5>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
