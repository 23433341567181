
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import SearchBar from '../../components/SearchBar';
import MemoizedDropdownWithAPI from '../../components/DropDownWithAPI';
import * as pdfjs from 'pdfjs-dist';

function UploadDocument({
  additionalInformation,
  handleFileUpload,
  filename,
  setShowModal,
  dropdownSelected,
  setDropdownSelected,
  dropdownType,
  setDropdownType,
  setFileNames,
  handleInternalDoc,
  dropdownTitle,
  setDropdownTitle,
  buttonName,
  fileNames,
  docTypes
}) {

  pdfjs.GlobalWorkerOptions.workerSrc = "/files/pdf.worker.min.mjs";

  const [file, setFile] = useState(null);
  const [error, setError] = useState(''); // State to store validation errors
  const [isInternalUpload, setIsInternalUpload] = useState(false); // To toggle upload mode
  const [searchQuery, setSearchQuery] = useState(''); // State for search input


  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    const maxSizeInMB = 20;

    if (selectedFile) {
      const allowedTypes = ['application/pdf', 'text/plain'];
      if (!allowedTypes.includes(selectedFile.type)) {
        setError('Only .txt and .pdf files are allowed.');
        setFile(null);
        return;
      }

      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
      if (selectedFile.size > maxSizeInBytes) {
        setError(`File size should not exceed ${maxSizeInMB}MB.`);
        setFile(null);
        return;
      }

      const fileContent = await selectedFile.arrayBuffer();
      const pdf = await pdfjs.getDocument({ data: fileContent }).promise;

      let isScanned = true;

      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const textContent = await page.getTextContent();

        if (textContent.items.length > 0) {
          isScanned = false;
          break;
        }
      }

      if (isScanned) {
        setError('The document is a scanned PDF and cannot be processed. Please upload a text-based PDF.');
        setFile(null);
        return;
      }

      setError('');
      setFile(selectedFile);
    }
  };

  useEffect(() => {
    if (!additionalInformation.current['document'][filename]) setFile(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalInformation])


  const handleSubmit = () => {
    //console.log('file', file)

    if (file) {
      additionalInformation.current['doc_id'][0] = '';
      additionalInformation.current['doc_id'][1] = '';
      additionalInformation.current['doc_id'][2] = '';
      handleFileUpload(file, filename)
    }
    else if (dropdownSelected) {
      additionalInformation.current['document'][0] = '';
      additionalInformation.current['document'][1] = '';
      additionalInformation.current['document'][2] = '';
      handleInternalDoc(dropdownSelected, filename, dropdownType);

    }

    setDropdownTitle('');
    setShowModal(false);
  };

  const fileInputRef = React.createRef();

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const onSelectDropDown = (option) => {
    setDropdownSelected(option.id);
    setDropdownTitle(option.title)
    setDropdownType(option.documentType);
    setFile(null);
    //setSearchQuery('');
  };

  return (
    <div className="modal-content" style={{ overflow: 'hidden' }}>
      {/* Modal Header */}
      {/* <div className="modal-header">
    <h5 className="modal-title">Upload Document</h5>
    <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
  </div> */}

      {/* Modal Body */}
      <div
        className="modal-body"
        style={{ height: 'calc(57vh)' }}
      >
        {/* Toggle Tabs */}
        <div className="nav mb-6" role="tablist" style={{ display: 'flex', justifyContent: 'start' }}>
          <button
            className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ms-7 me-5 ${!isInternalUpload ? 'active' : ''}`}
            style={{
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
              // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
            }}
            onClick={() => setIsInternalUpload(false)}
          >
            Upload from your PC
          </button>

          <button
            className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${isInternalUpload ? 'active' : ''}`}
            style={{
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
              // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
            }}
            onClick={() => setIsInternalUpload(true)}
          >
            Find in Regintel
          </button>

        </div>

        {buttonName === 'Compare Documents' && fileNames['1'].trim() !== '' && filename === '2' && (
          <div className="justify-content-center ps-7">
            <div>
              <span className="fw-bold">Selected Document 1: </span>
              {fileNames['1'].trim() !== '' ? fileNames['1'] : <span className="text-muted">No document selected</span>}
            </div>
            <div>
              <span className="fw-bold">Document Type: </span>
              {docTypes['1'] !== '' ? docTypes['1'] : <span className="text-muted">No type specified</span>}
            </div>
          </div>
        )}
        {buttonName === 'Compare Documents' && fileNames['2'].trim() !== '' && filename === '1' && (
          <div className="justify-content-center ps-7">

            <div>
              <span className="fw-bold">Selected Document 2: </span>
              {fileNames['2'].trim() !== '' ? fileNames['2'] : <span className="text-muted">No document selected</span>}
            </div>

            <div>
              <span className="fw-bold">Document Type: </span>
              {docTypes['2'] !== '' ? docTypes['2'] : <span className="text-muted">No type specified</span>}
            </div>
          </div>
        )}

        {/* Tab Content */}
        <div
          style={{
            padding: '15px',
            backgroundColor: '#fff',
          }}
        >
          {/* Internal Upload Section */}
          {isInternalUpload && (
            <div>
              <div className="mb-3">
                <SearchBar
                  searchInput={searchQuery}
                  setSearchInput={setSearchQuery}
                  placeholder={'Search Regintel Internal Documents'}
                  dropDownAdded={true}
                />
              </div>
              {/* Make the MemoizedDropdownWithAPI scrollable */}
              <div style={{ height: buttonName === 'Compare Documents' && (fileNames['1'].trim() !== '' || fileNames['2'].trim() !== '') ? 'calc(33.5vh)' : 'calc(40vh)', overflowY: 'auto' }}>
                <MemoizedDropdownWithAPI
                  apiEndpoint="/reg/v1/search/doc_search"
                  queryParams={{ "search_string": searchQuery }}
                  onSelect={onSelectDropDown}
                  dropdownSelected={true}
                  selectedDocId={dropdownSelected}
                  setDropdownSelected={setDropdownSelected}
                  isInternalUpload={isInternalUpload}
                  firstDoc={additionalInformation.current['doc_id'][1] !== '' ? additionalInformation.current['doc_id'][1] : additionalInformation.current['doc_id'][2] !== '' ? additionalInformation.current['doc_id'][2] : ''}
                />
              </div>
            </div>
          )}

          {/* External Upload Section */}
          {!isInternalUpload && (
            <div>
              <div style={{ height: 'calc(15vh)' }}></div>
              <div className="row justify-content-center">
                <label htmlFor="document" className="col-4 text-end">
                  <h4 className="required mt-4">Browse:</h4>
                  <span>File size &le; 20 MB </span>
                </label>
                <input
                  type="file"
                  onChange={handleFileChange}
                  className="form-control mt-3 col-7"
                  accept=".pdf,.txt"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                />
                <div className="col-5">
                  <button
                    className="btn btn-primary mt-3 col-2"
                    style={{
                      height: '40px',
                      width: '175px',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                    }}
                    onClick={triggerFileInput}
                  >
                    Browse
                  </button>
                  {file && (
                    <p
                      style={{
                        margin: 0,
                        fontSize: '14px',
                        color: '#495057',
                        fontWeight: '500',
                      }}
                      className="mt-3"
                    >
                      {file.name}
                    </p>
                  )}
                </div>
              </div>
              {error && (
                <div className="row justify-content-center ms-10">
                  <div
                    className="text-danger mt-5 col-5"
                    role="alert"
                    style={{ fontSize: '14px' }}
                  >
                    {error}
                  </div>
                </div>
              )}
            </div>

          )}
        </div>
      </div>

      {/* Modal Footer */}
      <div className="modal-footer">
        <button className="btn btn-secondary col-2" onClick={() => setShowModal(false)}>
          Discard
        </button>
        <button className="btn btn-success mx-10 col-2" onClick={handleSubmit}>
          Upload
        </button>
      </div>
    </div>





  );
}

export default UploadDocument;

