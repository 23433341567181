import React, { FC } from 'react'
import Document from './Document'

interface DocumentWrapperProps {
    docType: string;
}

const DocumentsWrapper: FC<DocumentWrapperProps> = ({ docType = '' }) => {
    return (
        <>

            <Document docType={docType} />
        </>
    )
}

export default DocumentsWrapper
