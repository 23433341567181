import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

type Document = {
    agencyId: string;
    iddocument: string;
    id: string;
    document_title: string;
    modifyDate: string;
    program: string;
    documentType: string;
}

interface TableRowDocumentsProps {
    items: Document[];
    trackedDocuments: any[];
    handleTrackDocument: (id: string) => void;
}

const TableRowDocuments: React.FC<TableRowDocumentsProps> = React.memo(({ items, trackedDocuments, handleTrackDocument }) => {
    const navigate = useNavigate();

    return (
        <>
            {items?.length === 0 ? (
                <tr>
                    <td colSpan={7} className='text-gray-600 fw-bold mb-1 fs-5' style={{ textAlign: 'center' }}>No records found</td>
                </tr>
            ) : (items?.map((item: Document, index) => (
                <tr className='bg-hover-light-primary' key={index} onClick={() => { navigate(`/document/${item.id}`, { state: { prevPage: 'home' } }) }}>
                    <td className='text-gray-800 fw-bold text-hover-dark fs-6 mt-2'>
                        {item.agencyId}
                    </td>

                    <td className='text-gray-800 fw-bold text-hover-dark mb-1 fs-6 mt-2'>
                        {item.program}
                    </td>
                    <td className='text-gray-800 fw-bold text-hover-primary mb-1 fs-6 mt-2'>
                        <Link to={`/document/${item.id}`} className='text-gray-800 fw-bold text-hover-primary mb-1 fs-6'>{item.document_title}</Link>
                    </td>
                    <td className='text-end text-gray-800 fw-bold text-hover-dark mb-1 fs-6 mt-2'>
                        {new Date(item.modifyDate).toLocaleDateString('en-US', { timeZone: 'UTC' })}
                    </td>
                    {item.documentType !== "Notice" ? (
                        <td className='text-gray-800 fw-bold text-hover-dark mb-1 fs-6 mt-2'>
                            <div>{trackedDocuments.some(trackedDocument => trackedDocument.id === item.id && trackedDocument.track === 1) ? (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip-2">Remove from My Items</Tooltip>}
                                >
                                    {({ ref, ...triggerHandler }) => (
                                        <button
                                            {...triggerHandler}
                                            className="btn h-25px w-5px btn-sm d-flex align-items-center justify-content-center"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                handleTrackDocument(item.iddocument);
                                            }}
                                        >
                                            <i ref={ref} className="bi bi-bookmark-fill fs-1 ms-6" style={{ color: '#007bff' }}></i>
                                        </button>
                                    )}
                                </OverlayTrigger>
                            ) : (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="button-tooltip-2">Add to My Items</Tooltip>}
                                >
                                    {({ ref, ...triggerHandler }) => (
                                        <button
                                            {...triggerHandler}
                                            className="btn h-25px w-5px btn-sm d-flex align-items-center justify-content-center"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                handleTrackDocument(item.iddocument);
                                            }}
                                        >
                                            <i ref={ref} className="bi bi-bookmark fs-1 ms-6" style={{ color: '#008000' }}></i>
                                        </button>
                                    )}
                                </OverlayTrigger>
                            )}</div>
                        </td>
                    ) : (<td></td>
                    )}


                </tr>
            )))}
        </>
    );
});

export default TableRowDocuments;