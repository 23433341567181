import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { KTIcon } from '../../../_metronic/helpers';
import clsx from 'clsx';
import HourGlass from '../Loading/HourGlassSpinner/HourGlass';

const RecallsDetails = () => {
    const { id } = useParams();
    const [device, setDevice] = useState([]);
    const [tab, setTab] = useState('Details');
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const searchTerm = location.state ? location.state.searchTerm : '';
    const currentPage = location.state ? location.state.currentPage : 1;

    useEffect(() => {
        const fetchDevice = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`/reg/v1/fda/recall/${id}`);
                setDevice(response.data[0]);
                // console.log(response.data);
                setIsLoading(false);
            } catch (error) {
                console.log('Error fetching document:', error);
                setIsLoading(false);
            }
        };
        fetchDevice();
    }, [id]);

    return (
        <div>
            <div className="pb-2" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', maxWidth: '100%' }}>
                <div style={{ marginRight: '20px', width: '250px' }}>
                    <Link
                        to={"/device-intelligence/recalls"}
                        state={{ searchTerm: searchTerm, currentPage: currentPage }}
                        style={{
                            color: '#0d6efd',
                            textDecoration: 'none',
                            fontSize: '1.3em',
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        onMouseEnter={(e) => e.target.style.color = '#0d6efd'}
                        onMouseLeave={(e) => e.target.style.color = '#0d6efd'}
                    >
                        <KTIcon iconName='arrow-left' className='fs-2 me-2' style={{ marginRight: '10px' }} />
                        Recalls List
                    </Link>
                </div>
            </div>

            <div className='card mt-4 mb-4' style={{}}>
                <div className='card-body d-flex align-items-center '>
                    <div className='position-relative flex-wrap'>
                        <div>
                            {!isLoading && <h4 className='text-gray-700 fw-bold'>Recall Number: {id}</h4>}
                            {isLoading && (
                                <div className="d-flex align-items-center">
                                    <div>Recall Loading...</div>
                                    <div className="spinner-border spinner-border-sm ms-3 mb-1" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className='card card-custom'>
                <div className='card-header card-header-stretch overflow-auto'>
                    <ul
                        className='nav nav-stretch nav-line-tabs
                        nav-line-tabs-2x
                        border-transparent
                        flex-nowrap'
                        role='tablist'
                    >
                        <li className='nav-item fs-4 '>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'Details' })}
                                onClick={() => setTab('Details')}
                                role='tab'
                            >
                                Details
                            </h5>
                        </li>
                        {/* <li className='nav-item fs-4'>
                            <h5
                                className={clsx(`nav-link cursor-pointer fw-semibold`, { 'active text-dark fw-bold': tab === 'PDF' })}
                                onClick={() => setTab('PDF')}
                                role='tab'
                            >
                                PDF
                            </h5>
                        </li> */}
                    </ul>
                </div>
                {isLoading ? (
                    <div className='card-body'>
                        <div className='tab-content'>
                            <HourGlass height="60vh" />
                        </div>
                    </div>
                ) : (
                    <div className='card-body'>
                        <div className='tab-content'>
                            <div className={clsx('tab-pane', { active: tab === 'Details' })}>
                                <div className="card-body card-scroll">
                                    {device && device.openfda_device_name && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Device Name</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.openfda_device_name}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device && device.product_description && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Product Description</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.product_description}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device && device.recalling_firm && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Recalling Firm</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.recalling_firm}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device && device.product_code && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Product Code</span>
                                            </div>
                                            <div className="col-10">
                                                <Link to={`/device-intelligence/classification/${device.product_code}`} target="_blank" className="fw-bold fs-5" style={{ color: 'primary' }}>{device.product_code}</Link>
                                            </div>
                                        </div>
                                    )}
                                    {device && device.openfda_device_class && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Device Class</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.openfda_device_class}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device && device.openfda_regulation_number && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Regulation Number</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.openfda_regulation_number}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device && device.openfda_medical_specialty_description && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Specialty</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.openfda_medical_specialty_description}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device && device.res_event_number && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Event Number</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.res_event_number}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device && device.event_date_initiated && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5" style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Event Date Initiated</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5">{new Date(device.event_date_initiated).toLocaleDateString('en-US', { timeZone: 'UTC' })}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device && device.fulldate && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5" style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Posted Date</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5">{new Date(device.fulldate).toLocaleDateString('en-US', { timeZone: 'UTC' })}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device && device.event_date_terminated && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5" style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Event Date Terminated</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5">{new Date(device.event_date_terminated).toLocaleDateString('en-US', { timeZone: 'UTC' })}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device && device.recall_status && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Recall Status</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.recall_status}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device && device.root_cause_description && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Root Cause</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.root_cause_description}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device && device.distribution_pattern && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Distribution Pattern</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.distribution_pattern}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device && device.reason_for_recall && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Reason for Recall</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.reason_for_recall}</span>
                                            </div>
                                        </div>
                                    )}
                                    {device && device.action && (
                                        <div className="mb-3 row">
                                            <div className="col-2">
                                                <span className="card-title fw-bold fs-5 " style={{ color: 'rgba(0, 68, 102, 0.8)' }}>Action</span>
                                            </div>
                                            <div className="col-10">
                                                <span className="card-title fw-bold text-gray-600 fs-5 ">{device.action}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
export default RecallsDetails;
