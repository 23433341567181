import { SET_SELECTED_DOCKET, DOCKET_SUBSCRIPTION_UPDATED, ADD_DOCUMENT, REMOVE_DOCUMENT, UPDATE_DOCUMENT } from './types'; // Import the new action type

const initialState = {
    selectedDocket: {
        id: '', // Initially empty
        title: '', // Initially empty
        iddocket: null,
    },
    docketSubscriptionUpdated: false, // Add this line
    documents:{},
};

const docketsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_DOCKET:
            return { ...state, selectedDocket: action.payload };
        case DOCKET_SUBSCRIPTION_UPDATED: // Add this case
            return { ...state, docketSubscriptionUpdated: !state.docketSubscriptionUpdated };
        case ADD_DOCUMENT:
            if (Object.keys(state.documents).length < 2) {
                return {
                    ...state,
                    documents: {
                        ...state.documents,
                        [action.idDocument]: { documentTitle: action.documentTitle }
                    }
                };
            } else {
                // Optionally, you can return an error or take another action if the condition is not met
                alert('Documents length is not 2, update operation aborted.');
                return state;
            }

        case REMOVE_DOCUMENT:
            const updatedDocuments = { ...state.documents };
            delete updatedDocuments[action.idDocument];
            return { ...state, documents: updatedDocuments };

        case UPDATE_DOCUMENT:
            // Only allow update if there are exactly 2 documents
            if (Object.keys(state.documents).length === 2) {
                return {
                    ...state,
                    documents: {
                        ...state.documents,
                        [action.idDocument]: { documentTitle: action.documentTitle }
                    }
                };
            } else {
                // Optionally, you can return an error or take another action if the condition is not met
                alert('Documents length is not 2, update operation aborted.');
                return state;
            }

        default:
            return state;
    }
};

export default docketsReducer;
