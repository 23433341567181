import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useAuth } from "../../../modules/auth";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { KTIcon } from "../../../../_metronic/helpers";
import OverlayModal from "../../../components/OverlayModal";
import CompareDocuments from "../../../../_metronic/layout/components/doc_compare/CompareDocuments";

const DocumentsListToolbar = ({
    document,
    docType = '',
    trackedDocuments,
    setTrackedDocuments,
    setAlert,
    isInternational = false,
    link = document.fileUrl1,
    id = isInternational ? document['id'] : document.iddocument,
}) => {
    const [showModal, setShowModal] = useState(false);
    const [doc1_to_compare, setDoc1_to_compare] = useState({});

    const { currentUser, logout } = useAuth();
    const navigate = useNavigate();

    const handleViewButtonPDFClick = (documentId) => {
        const url = `/viewpdf?id=${documentId}`
        if (isInternational) {
            sessionStorage.setItem('isInternational', true);
        }
        else {
            sessionStorage.setItem('isInternational', false);
        }
        window.open(url, '_blank')

    };

    // const handleViewButtonDOCClick = (documentId) => {
    //     const url = `/viewtxt?id=${documentId}`;
    //     window.open(url, '_blank');
    // };

    // const handleAttachmentClick = (id, filetype, type) => {

    //     const url = `/viewattach?id=${id}&filetype=${filetype}&type=${type}`;
    //     window.open(url, '_blank');
    // };

    // const handleAttachmentClick2 = (url) => {
    //     window.open(url, '_blank');
    // };

    const handleTrackDocument = (id) => {

        axios.post(`/reg/v1/document/track/${currentUser?.id}/${id}`)
            .then((response) => {

                setAlert({ message: response.data.message, type: 'success' });

                setTrackedDocuments(prevState => {

                    const newState = prevState.map(doc => {
                        if (doc.iddocument === id) {

                            return { ...doc, track: doc.track === 1 ? 0 : 1 };
                        } else {
                            return doc;
                        }
                    });
                    return newState;
                });


            })
            .catch((error) => {
                if (error.response.status === 401) {
                    logout();
                    navigate('/auth/login');
                } else {
                    //setError(error);
                    console.error('Error tracking document:', error);
                }

            });

    }

    const handleTrackInDocument = (id) => {
        // setLoading(true);
        axios.post(`/reg/v1/document/international/track/${currentUser?.id}/${id}`)
            .then((response) => {
                //setAlert({ message: response.data.message, type: 'success' });
                // fetchTrackedDocuments(); // Fetch tracked documents again after tracking a new document
                setTrackedDocuments(prevState => {

                    const newState = prevState.map(doc => {
                        if (doc['id'] === id) {
                            return { ...doc, track: doc.track === 1 ? 0 : 1 };
                        } else {
                            return doc;
                        }
                    });
                    return newState;
                });

                //fetchInTrackedDocuments();

                // setLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    logout();
                    navigate('/auth/login');
                } else {
                    console.error('Error tracking document:', error);
                }
                // setLoading(false);
            });
    }

    const handleShareDocument = (document) => {
        const subject = encodeURIComponent(`Document ${isInternational ? document['doc_id'] : document['id']}`);
        const miniSummarySection = document['mini_summary']
            ? `Summary:\n${document['mini_summary']}\n`
            : '';

        const body = encodeURIComponent(
            `Document Title: ${isInternational ? document['title'] : document['document_title']}\n` +
            `Type: ${document.subtype === 'Guidance' ? document.subtype : document.documentType}\n\n` +
            miniSummarySection +
            `Link for More Information: ${window.location.origin}/${isInternational ? `international/${document['doc_id']}` : `document/${document['id']}`}\n\n` +
            `Sincerely,\n    ` +
            `${currentUser?.first_name} ${currentUser?.last_name}`
        );

        const mailtoLink = `mailto:?subject=${subject}&body=${body}`;
        window.open(mailtoLink, '_blank');
    };

    const handleCopyShareDocument = (document) => {
        const miniSummarySection = document['mini_summary']
            ? `Summary:\n${document['mini_summary']}\n`
            : '';

        const body = `Document Title: ${isInternational ? document['title'] : document['document_title']}\n` +
            `Type: ${document.subtype === 'Guidance' ? document.subtype : document.documentType}\n\n` +
            miniSummarySection +
            `Link for More Information: ${window.location.origin}/${isInternational ? `international/${document['doc_id']}` : `document/${document['id']}`}\n\n` +
            `Sincerely,\n    ` +
            `${currentUser?.first_name} ${currentUser?.last_name}`;

        navigator.clipboard.writeText(body);
        if (setAlert) {
            setAlert({ message: 'Email format of document successfully copied to clipboard', type: 'success' });
        }
    };

    const openModal = (document_data) => {
        setShowModal(true);
        setDoc1_to_compare(document_data)
    };

    const closeModal = () => {
        setShowModal(false);
        setDoc1_to_compare({});
    };

    const handleDownloadButtonPDFClick = async (documentId) => {
        try {
            const response = await axios.get(`/reg/v1/document/international/viewpdfa/${documentId}`);
            const presignedUrl = response.data.url;
            return presignedUrl;
        } catch (error) {
            console.error('Error downloading PDF:', error);
            return null;
        }
    };

    return (
        <div className="card-toolbar col-2 d-flex justify-content-end align-items-center">
            {/* Download button */}
            <div className="dropdown">
                <button
                    className="btn btn-light-primary btn-active-primary btn-sm dropdown-toggle"
                    type="button"
                    id={`dropdownMenuButton-${document.iddocument}`}
                    data-bs-toggle="dropdown"
                    aria-expanded={false}
                >
                    Actions
                </button>

                <ul className="dropdown-menu dropdown-menu-end text-start" aria-labelledby={`dropdownMenuButton-${document.iddocument}`}>
                    {document.iddocument && docType !== 'debarment' && (
                        <li>
                            <button
                                className="dropdown-item fw-bold d-flex align-items-center me-3"
                                onClick={() => openModal(document)}
                            >
                                <i className="fas fa-columns fs-5 text-primary ms-3 me-3"></i>
                                Compare Document
                            </button>
                        </li>
                    )}
                    <li>
                        {(link) && (link !== '') ? (
                            <a href={link} className="dropdown-item fw-bold d-flex align-items-center me-3">
                                <i className="fas fa-download fs-5 text-primary ms-3 me-3"></i>
                                Download
                            </a>
                        ) : (
                            <button
                                onClick={async (e) => {
                                    e.preventDefault(); // Prevent the default link behavior
                                    const url = await handleDownloadButtonPDFClick(id);
                                    if (url) {
                                        window.location.href = url; // Navigate to the pre-signed URL
                                    }
                                }}
                                className="dropdown-item fw-bold d-flex align-items-center me-3"
                            >
                                <i className="fas fa-download fs-5 text-primary ms-3 me-3"></i>
                                Download
                            </button>
                        )}
                    </li>
                    <li>
                        <button
                            className="dropdown-item fw-bold d-flex align-items-center me-3"
                            disabled={isInternational ? !document.s3_link : !document.s3_pdf_link}
                            onClick={() => handleViewButtonPDFClick(id)}
                        >
                            <i className="fas fa-file-pdf fs-5 text-primary ms-4 me-3"></i>
                            View PDF
                        </button>
                    </li>
                    <li>
                        <button
                            className="dropdown-item fw-bold d-flex align-items-center me-3"
                            disabled={isInternational ? !document.s3_link : !document.s3_pdf_link}
                            onClick={() => { handleShareDocument(document); }}
                        >
                            <i className="fas fa-share-alt fs-5 text-primary ms-4 me-3"></i>
                            Share Document
                        </button>
                    </li>
                    <li>
                        <button
                            className="dropdown-item fw-bold d-flex align-items-center me-3"
                            disabled={isInternational ? !document.s3_link : !document.s3_pdf_link}
                            onClick={() => handleCopyShareDocument(document)}
                        >
                            <KTIcon iconName="copy" iconType="duotone" className="fs-3 text-primary ms-3 me-3" />
                            Copy Email Format
                        </button>
                    </li>
                </ul>
            </div>
            {document.documentType !== 'Notice' && docType !== 'debarment' && (
                <div className='ms-1'>{trackedDocuments.some(trackedDocument => trackedDocument.id === document.id && trackedDocument.track === 1) ? (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="button-tooltip-2">Remove from My Items</Tooltip>}
                    >
                        {({ ref, ...triggerHandler }) => (
                            <button
                                {...triggerHandler}
                                className="btn h-25px w-5px btn-sm d-flex align-items-center justify-content-center"
                                onClick={() => isInternational === true ? handleTrackInDocument(id) : handleTrackDocument(id)}
                            >
                                <i ref={ref} className="bi bi-bookmark-fill fs-1 ms-6" style={{ color: '#007bff' }}></i>
                            </button>
                        )}
                    </OverlayTrigger>
                ) : (
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="button-tooltip-2">Add to My Items</Tooltip>}
                    >
                        {({ ref, ...triggerHandler }) => (
                            <button
                                {...triggerHandler}
                                className="btn h-25px w-5px btn-sm d-flex align-items-center justify-content-center"
                                onClick={() => isInternational === true ? handleTrackInDocument(id) : handleTrackDocument(id)}
                            >
                                <i ref={ref} className="bi bi-bookmark fs-1 ms-6" style={{ color: '#008000' }}></i>
                            </button>
                        )}
                    </OverlayTrigger>
                )}</div>
            )}

            <OverlayModal
                show={showModal}
                onClose={closeModal}
                heading='Lexim Document Comparison'

            >
                <CompareDocuments
                    document={doc1_to_compare}
                />
            </OverlayModal>

        </div>

    );
};
export default DocumentsListToolbar;