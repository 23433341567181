import React from 'react'

function QaQuestion({ qaPair }) {
  return (
    <div className='d-flex justify-content-end w-100 mt-2 mb-2'>
      <div className='p-4 bg-light-primary text-gray-900 fw-medium fs-6 rounded' style={{ maxWidth: '80%', wordWrap: 'break-word' }}>
        {qaPair.question}
      </div>
    </div>
  )
}

export default QaQuestion;