import React, { useState, useRef, useEffect } from 'react';
import OverlayModal from '../../components/OverlayModal';
import UploadDocument from './UploadDocument';
import { KTIcon } from '../../../_metronic/helpers';

function MyResearchButtons({
  selectedButton,
  setSelectedButton,
  selectedOption,
  setSelectedOption,
  additionalInformation,
  askQuestion,
  clearQuestions
}) {

  const [inputValue, setInputValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');
  const [documentUploaded, setDocumentUploaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const debounceTimeout = useRef(null);
  const [fileLoc, setFileLoc] = useState('0');
  const [dropdownSelected, setDropdownSelected] = useState('');
  const [dropdownTitle, setDropdownTitle] = useState('');
  const [dropdownType, setDropdownType] = useState('');
  const [fileNames, setFileNames] = useState({ '0': '', '1': '', '2': '' });
  const [docTypes, setDocTypes] = useState({ '0': '', '1': '', '2': '' });

  const buttonInformation = {
    Summarize: [0, 1],
    Translate: [0, 1],
    Impact: [0, 1],
    Action: [0, 1],
    FAQ: [0, 1],
    'Compare Documents': [],
  };

  const subButtons = ['Text', 'Document', 'Audio', 'Video', 'Link'];

  const handleClearResponses = () => {
    clearQuestions(); // Call the provided clearQuestions function
    setFileNames({ '0': '', '1': '', '2': '' }); // Reset file names
    setDocTypes({ '0': '', '1': '', '2': '' }); // Reset document types
    setDocumentUploaded(false); // Reset document uploaded status
    additionalInformation.current = {
      'text': '',
      'document': {
        '0': '',
        "1": '',
        "2": ''
      },
      'doc_id': {
        '0': '',
        "1": '',
        "2": ''
      },
      'url': ''
    };
  };

  const filteredSubButtons = buttonInformation[selectedButton].map(
    (index) => subButtons[index]
  );

  const handleButtonClick = (btVal) => {
    // If switching to "Compare Documents"
    if (btVal === "Compare Documents" && selectedButton !== "Compare Documents") {
      // Move document from position 0 to 1 and clear position 0
      const movedFile = additionalInformation.current.document['0'];
      const movedDocId = additionalInformation.current.doc_id['0'];

      additionalInformation.current.document['1'] = movedFile;
      additionalInformation.current.doc_id['1'] = movedDocId;

      additionalInformation.current.document['0'] = '';
      additionalInformation.current.doc_id['0'] = '';

      setDocTypes((prevTypes) => ({
        ...prevTypes,
        '1': prevTypes['0'], // Move document type from 0 to 1
        '0': '', // Clear document type for position 0
      }));

      setFileNames((prevNames) => ({
        ...prevNames,
        '1': prevNames['0'], // Move filename from 0 to 1
        '0': '', // Clear filename for position 0
      }));
    }
    // If switching away from "Compare Documents"
    else if (selectedButton === "Compare Documents" && btVal !== "Compare Documents") {
      // Move document from position 1 back to 0 and clear position 1
      const movedFile = additionalInformation.current.document['1'];
      const movedDocId = additionalInformation.current.doc_id['1'];

      additionalInformation.current.document['0'] = movedFile;
      additionalInformation.current.doc_id['0'] = movedDocId;

      additionalInformation.current.document['1'] = '';
      additionalInformation.current.doc_id['1'] = '';

      additionalInformation.current.document['2'] = '';
      additionalInformation.current.doc_id['2'] = '';

      setDocTypes((prevTypes) => ({
        ...prevTypes,
        '0': prevTypes['1'], // Move document type from 1 to 0
        '1': '', // Clear document type for position 1
      }));

      setFileNames((prevNames) => ({
        ...prevNames,
        '0': prevNames['1'], // Move filename from 1 to 0
        '1': '', // Clear filename for position 1
      }));
    }

    // Update the selected button
    setSelectedButton(btVal);
  };

  const handleOptionChange = (newOption) => {
    setSelectedOption(newOption);

    // Clear additional information and filenames when option changes
    additionalInformation.current = {
      text: '',
      document: ['', '', ''],
      'doc_id': ['', '', ''],

      url: '',
    };
    setDocTypes({ '0': '', '1': '', '2': '' });
    setFileNames({ '0': '', '1': '', '2': '' });
    setInputValue('');
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
  };

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, 500);

    return () => {
      clearTimeout(debounceTimeout.current);
    };
  }, [inputValue]);

  useEffect(() => {
    if (selectedOption && debouncedValue !== '') {
      if (['Audio', 'Video', 'Link'].includes(selectedOption)) {
        additionalInformation.current['url'] = debouncedValue;
      } else if (selectedOption === 'Text') {
        additionalInformation.current['text'] = debouncedValue;
      }
    }
  }, [debouncedValue, selectedOption, additionalInformation]);

  const handleFileUpload = (file, filename) => {
    if (file) {
      additionalInformation.current['document'][filename] = file;

      setFileNames((prevNames) => ({
        ...prevNames,
        [filename]: file.name, // Store the file name
      }));
      setDocTypes((prevTypes) => ({
        ...prevTypes,
        [filename]: '',
      }));
      setDocumentUploaded(true);
    } else {
      setDocumentUploaded(false);
    }
  };

  const handleInternalDoc = (dropdown, filename, doctype) => {
    additionalInformation.current['doc_id'][filename] = dropdown;

    setFileNames((prevNames) => ({
      ...prevNames,
      [filename]: dropdownTitle, // Store the file name
    }));
    setDocTypes((prevTypes) => ({
      ...prevTypes,
      [filename]: doctype,
    }));
  }

  return (
    <div>
      <div className="d-flex justify-content-start flex-wrap mb-5 ms-0">
        {Object.keys(buttonInformation).map((btVal) => (
          <div key={btVal} className="me-2">
            <button
              className={`btn btn-sm mt-2 ${selectedButton === btVal ? 'btn-primary' : 'btn-outline-primary'
                }`}
              style={{
                border: selectedButton === btVal ? '2px solid #0d6efd' : '2px dashed #ced4da',
                color: selectedButton === btVal ? '#fff' : '#0d6efd',
                backgroundColor: selectedButton === btVal ? '#0d6efd' : 'transparent',
              }}
              onClick={() => handleButtonClick(btVal)}
            >
              {btVal}
            </button>
          </div>
        ))}
        <div className="ms-auto">  {/* This will push the "Clear" button to the far right */}
          <button
            className="btn btn-sm mt-2 btn-secondary"
            onClick={handleClearResponses}
          >
            Clear Responses
          </button>
        </div>
      </div>

      {filteredSubButtons.length > 0 && (
        <div className="d-flex justify-content-start flex-wrap radio-button-list align-items-center mb-5 ms-0">
          {filteredSubButtons.map((option, index) => (
            <label key={index} style={{ marginRight: '20px', display: 'flex', alignItems: 'center' }}>
              <input
                type="radio"
                value={option}
                checked={selectedOption === option}
                onChange={() => handleOptionChange(option)}
                style={{ marginRight: '5px' }}
              />
              {option}
            </label>
          ))}
        </div>
      )}

      <div className="mb-5">
        {selectedOption === 'Document' && selectedButton !== 'Compare Documents' && (
          <div className="d-flex align-items-center mt-2">
            <button
              className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
              onClick={() => {
                setShowModal(true);
                setFileLoc('0');
              }}
              style={{ width: '200px', height: '40px' }} // Set fixed width for the button

            >
              <KTIcon iconName='add-files' iconType="solid" className='fs-2' /> Select Document

            </button>
            {fileNames['0'] && (
              <p className="ms-3 mt-0 mb-0 text-center">{fileNames['0']}</p>
            )}
          </div>
        )}
        {selectedOption === 'Text' && selectedButton !== 'Compare Documents' && (
          <div className="d-flex align-items-center mt-2">
            <textarea
              value={inputValue}
              placeholder="Enter text here"
              onChange={handleInputChange}
              className="form-control mt-2"
              style={{ paddingRight: '50px', resize: 'none' }}
              maxLength={5000}
              rows={5}
            />
          </div>

        )}
        {selectedButton === 'Compare Documents' && (
          <div className="d-flex flex-column align-items-start mt-0">
            <div className="d-flex align-items-center mt-2">
              <button
                className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
                onClick={() => {
                  setShowModal(true);
                  setFileLoc('1');
                }}
                style={{ width: '200px', maxWidth: '200px', height: '40px' }} // Set fixed width for the button
              >
                <KTIcon iconName='add-files' iconType="solid" className='fs-2' /> Select Document 1

              </button>
              {fileNames['1'] && (
                <p className="ms-3 mt-0 mb-0" style={{ maxWidth: '70%' }}>{fileNames['1']}</p>
              )}
            </div>

            <div className="d-flex align-items-center mt-2">
              <button
                className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
                onClick={() => {
                  setShowModal(true);
                  setFileLoc('2');
                }}
                style={{ width: '200px', maxWidth: '200px', height: '40px' }} // Set fixed width for the button
              >
                <KTIcon iconName='add-files' iconType="solid" className='fs-2' /> Select Document 2

              </button>
              {fileNames['2'] && (
                <p className="ms-3 mt-0 mb-0" style={{ maxWidth: '70%' }}>{fileNames['2']}</p>
              )}
            </div>
          </div>
        )}

        {['Audio', 'Video', 'Link'].includes(selectedOption) && (
          <>
            <div className="mb-2">
              <label>Provide {selectedOption}:</label>
            </div>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <input
                  type="text"
                  value={inputValue}
                  placeholder={`Enter ${selectedOption} URL`}
                  onChange={handleInputChange}
                  className="form-control mt-2"
                />
              </div>
              {(inputValue.trim() !== '' || documentUploaded) && (
                <button
                  className="btn btn-primary mt-3 ms-2 align-items-center"
                  onClick={askQuestion}
                >
                  Submit
                </button>
              )}
            </div>
          </>
        )}
      </div>

      <OverlayModal show={showModal} onClose={() => setShowModal(false)} width={"60%"} height={"75%"} fileLoc={fileLoc}>
        <UploadDocument
          dropdownTitle={dropdownTitle}
          setDropdownTitle={setDropdownTitle}
          setDropdownSelected={setDropdownSelected}
          dropdownSelected={dropdownSelected}
          dropdownType={dropdownType}
          setDropdownType={setDropdownType}
          additionalInformation={additionalInformation}
          handleFileUpload={handleFileUpload}
          filename={fileLoc}
          setShowModal={setShowModal}
          setFileNames={setFileNames}
          handleInternalDoc={handleInternalDoc}
          buttonName={selectedButton}
          fileNames={fileNames}
          docTypes={docTypes}
        />
      </OverlayModal>
    </div>
  );
}

export default MyResearchButtons;
