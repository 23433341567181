/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useCallback } from 'react'
import { KTIcon } from '../../../_metronic/helpers'
import axios from 'axios';
import HourGlass from '../Loading/HourGlassSpinner/HourGlass';
import DocumentListWrapper from '../../components/DocumentListWrapper';
import ItemsPerPageWrapper from '../../components/ItemsPerPageWrapper';
import PaginationWrapper from '../../components/PaginationWrapper';
import DocumentToolbar from '../Document/Components/DocumentToolbar';
import { useLocation, useParams } from 'react-router-dom';
import { useAuth } from '../../modules/auth';

function Country({ country, region_code }) {
    const { documentType } = useParams();
    const [searchInput, setSearchInput] = useState('');
    const [events, setEvents] = useState([]);
    const [showEvents, setShowEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [alert, setAlert] = useState({ message: '', type: '' });
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: 'publish_date', direction: 'desc' });
    const [sortDirection, setSortDirection] = useState('desc');
    const [trackedDocuments, setTrackedDocuments] = useState([]);
    const { currentUser } = useAuth();
    // const navigate = useNavigate();
    const location = useLocation();

    const formatCountry = (name) => {
        if (name === 'eu') return 'EU';
        if (!name) return '';
        return name
            .split('-')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const givenCountry = region_code
        ? formatCountry(region_code)
        : location.state && location.state.givenCountry
            ? location.state.givenCountry
            : '';

    const fetchData = async () => {
        setIsLoading(true);
        setError(null)
        try {
            const response = await axios.get(`/reg/v1/document/international_doc/${givenCountry || 'all'}`, {
                params: {
                    'search_input': searchInput
                }
            });
            const data = await response.data
            let sortedEvents = [...data];
            sortedEvents.sort((a, b) => a[sortConfig.key] < b[sortConfig.key] ? 1 : -1);
            setEvents(sortedEvents);

            setTotalPages(Math.ceil(data.length / itemsPerPage));
        }
        catch (error) {
            setError(error);
        }
        finally {
            setIsLoading(false);
        }
    };

    const changeShowData = () => {
        const initialIndex = Math.max((currentPage - 1) * itemsPerPage, 0);
        const lastIndex = initialIndex + itemsPerPage;
        setShowEvents(events.slice(initialIndex, lastIndex));
    }



    const onApplySort = () => {
        if (!events || events.length === 0 || isLoading) {
            return;
        }

        let sortedEvents = [...events];
        if (sortConfig.direction === 'asc') {
            sortedEvents.sort((a, b) => a[sortConfig.key] > b[sortConfig.key] ? 1 : -1);
        } else if (sortConfig.direction === 'desc') {
            sortedEvents.sort((a, b) => a[sortConfig.key] < b[sortConfig.key] ? 1 : -1);
        }
        setEvents(sortedEvents);
        changeShowData();
    };

    useEffect(() => {
        onApplySort();
    }, [sortConfig]);

    const onResetSort = () => {
        if (!events || events.length === 0 || isLoading) {
            return;
        }
        let sortedEvents = [...events];
        sortedEvents.sort((a, b) => a.postedDate < b.postedDate ? 1 : -1);
        setSortConfig({ key: 'publish_date', direction: 'desc' });
        setEvents(sortedEvents);
        changeShowData();
    };


    const formatDate = (timestamp) => {
        if (!timestamp) return null;
        return new Date(timestamp).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            timeZone: 'UTC'
        });
    }

    // const getUrl = (s3_link) => {
    //     const domainParts = new URL(s3_link).hostname.split('.');
    //     return domainParts[domainParts.length - 3].toUpperCase();
    // }

    useEffect(() => {
        setTotalPages(Math.ceil(events.length / itemsPerPage))
        changeShowData();
    }, [currentPage, itemsPerPage, events])

    useEffect(() => {
        currentPage !== 1 && setCurrentPage(1);
        const updateSearchAndFetch = async () => {
            if (searchInput !== '') {
                await setSearchInput('');
            }
            fetchData();
        };
        updateSearchAndFetch();
    }, [searchInput, country]);

    const fetchInTrackedDocuments = useCallback(async () => {

        try {
            const response = await axios.get(`/reg/v1/document/user/international/${currentUser?.id}`);
            setTrackedDocuments(response.data);
            return response.data;

        } catch (error) {
            console.log('Failed to fetch tracked documents', error);
            return [];
        }
    }, [currentUser?.id]);

    useEffect(() => {
        fetchInTrackedDocuments();
    }, [fetchInTrackedDocuments]);

    const topRow = 'title';
    const secondaryRow = [['Document Type', 'documentType']];
    const thirdRow = !givenCountry ? [['Country', 'country'], ['Agency', 'long_name']] : [['Agency', 'long_name']];
    const bottomRow = [['Publish Date', 'publish_date', formatDate]];
    const summaryRow = 'mini_summary';
    const links = ['', 'id'];

    return (
        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            <div className='d-flex flex-column flex-column-fluid'>
                <div className="d-flex flex-row-fluid px-1">
                    <div className="d-flex flex-column-auto align-items-center justify-content-center ">
                        <h1 className="d-md-block text-wrap ms-1" style={{ color: "#4d4d4d" }}>
                            <div className="d-flex align-items-center ms-4">
                                <KTIcon iconName='folder' iconType="duotone" className='fw-bold fs-1 mx-3 text-primary ' />
                                <span>{formatCountry(country) || 'My International Documents'}</span>
                            </div>
                        </h1>
                    </div>
                </div>
                <DocumentToolbar
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    docType={'international'}
                    placeholder={`Search ${formatCountry(country) || 'My International'} Documents`}
                    sortConfig={sortConfig}
                    setSortConfig={setSortConfig}
                    onApplySort={onApplySort}
                    onResetSort={onResetSort}
                    sortDirection={sortDirection}
                    setSortDirection={setSortDirection}
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                />
                {error && <div className="error-message">{error.message}</div>}
                {alert.message && (
                    <div className={`alert alert-${alert.type} alert-dismissible fade show mt-3`} role="alert">
                        {alert.message}
                        <button type="button" className="btn-close" onClick={() => setAlert({ message: '', type: '' })} aria-label="Close"></button>
                    </div>
                )}
                {isLoading ? <HourGlass /> :
                    (<DocumentListWrapper
                        filteredDocuments={showEvents}
                        topRow={topRow}
                        secondaryRow={secondaryRow}
                        thirdRow={thirdRow}
                        bottomRow={bottomRow}
                        summaryRow={summaryRow}
                        linkTo={'doc_id'}
                        links={links}
                        isInternational={true}
                        trackedDocuments={trackedDocuments}
                        setTrackedDocuments={setTrackedDocuments}
                        setAlert={setAlert}
                        state={{ currentPage: currentPage, searchInput: searchInput, givenCountry: givenCountry, myInternational: documentType === 'international' }}
                    />
                    )}
                {!isLoading && totalPages >= 1 ? (
                    <div className="d-flex justify-content-center align-items-center">
                        <ItemsPerPageWrapper itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} bottomSpace={7} />
                        <PaginationWrapper totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                    </div>
                ) : (
                    <div className="mb-4"></div>
                )
                }
            </div>
        </div>
    )
}

export default Country