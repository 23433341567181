import React, { useState, useEffect } from 'react'
import axios from 'axios';
import TableRowComponentWarning from './TableRowComponentWarning';
import HourGlass from '../Loading/HourGlassSpinner/HourGlass';
import PaginationWrapper from '../../components/PaginationWrapper';


type Document = {
    company_name: string;
    issuing_office: string;
    letter_issue_date: string;
    posted_date: string;
    id: string;
    program_id: string;
    subject: string;
    warning_letter_url: string;

}
type Props = {
    days: number;

}
const TablesWidgetWarning: React.FC<Props> = ({ days }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [details, setDetails] = useState<Document[]>([]);
    const [itemsPerPage] = useState(10);


    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [sortorder, setSortorder] = useState('desc');
    const [sortcol, setSortcol] = useState('posted_date')

    const [count, setCount] = useState(0)

    useEffect(() => {
        const fetchData = async () => {
            // setLoading(true);
            try {
                const filtersJson = JSON.stringify({});
                const response = await axios.post(`/reg/v1/fda/preference/warning_letter/pagination?page_num=${currentPage}&page_size=${itemsPerPage}&sort_col=${sortcol}&sort_order=${sortorder}&days=${days}`,
                    { filter: filtersJson },
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                );
                const totalCount = response.data?.total_count;
                setCount(totalCount);
                const warningLetters = response.data.data;
                setDetails(warningLetters);
                setTotalPages(Math.ceil(totalCount / itemsPerPage));
                setLoading(false);
            } catch (error) {
                console.error("Failed to fetch data:", error);
                setLoading(false);
            }
        };

        fetchData();
    }, [currentPage, itemsPerPage, days, sortcol, sortorder]);

    const currentItems = details;

    useEffect(() => {
        setCurrentPage(1);
        setLoading(true);

    }, [days])

    const handleSort = (columnName) => {
        if (sortcol === columnName) {
            setSortorder(sortorder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortcol(columnName);
            setSortorder('asc'); // Default to ascending when changing columns
        }
    };


    return (
        <div className="container-fluid p-0 m-0">
            <div className="row no-gutters">
                <div className="col">
                    <div className={`card `}>

                        <div className='card-header border-0 pt-5'>
                            <div className="d-flex flex-column" >
                                <h3 className='card-title align-items-start flex-column' style={{ display: 'flex', alignItems: 'center' }}>
                                    <span className='card-label fw-bold fs-3 mb-1'>
                                        Warning Letters
                                        {/* {loading && <span className='spinner-border text-gray-600 align-middle ms-2'></span>} */}


                                    </span>
                                </h3>
                                {!loading && count > 0 && (
                                    <span className="fs-6 text-gray-700"> Showing {((currentPage - 1) * itemsPerPage) + 1} to {Math.min(currentPage * itemsPerPage, count)} of {count} results</span>
                                )}
                                {!loading && count === 0 && (
                                    <span className="fs-6 text-gray-700"> 0 results </span>
                                )}
                            </div>
                        </div>

                        <div className='card-body px-8 '>

                            <div className='table-responsive '>
                                {loading ? (
                                    <HourGlass height="25vh" />
                                ) : (
                                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-2 gy-3 ' style={{ width: '100% !important', cursor: 'pointer' }}>

                                        <thead>
                                            <tr className='fw-bolder text-gray-600 fs-5'>
                                                <th style={{ minWidth: '25%', width: '25%' }} onClick={() => handleSort('company_name')}>
                                                    Company Name
                                                    <span className={`sorting-icon ${sortcol === 'company_name' ? sortorder : ''}`}>
                                                        {' '}<i className={`bi ${sortcol === 'company_name' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                    </span>
                                                </th>
                                                <th style={{ minWidth: '25%', width: '25%' }} onClick={() => handleSort('subject')}>
                                                    Subject
                                                    <span className={`sorting-icon ${sortcol === 'subject' ? sortorder : ''}`}>
                                                        {' '}<i className={`bi ${sortcol === 'subject' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                    </span>
                                                </th>
                                                <th style={{ minWidth: '25%', width: '25%' }} onClick={() => handleSort('issuing_office')}>
                                                    Issuing Office
                                                    <span className={`sorting-icon ${sortcol === 'issuing_office' ? sortorder : ''}`}>
                                                        {' '}<i className={`bi ${sortcol === 'issuing_office' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                    </span>
                                                </th>
                                                <th style={{ minWidth: '25%', width: '10%' }} onClick={() => handleSort('letter_issue_date')}>
                                                    Issued
                                                    <span className={`sorting-icon ${sortcol === 'letter_issue_date' ? sortorder : ''}`}>
                                                        {' '}<i className={`bi ${sortcol === 'letter_issue_date' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                    </span>
                                                </th>
                                                <th style={{ minWidth: '25%', width: '10%' }} onClick={() => handleSort('posted_date')}>
                                                    Posted
                                                    <span className={`sorting-icon ${sortcol === 'posted_date' ? sortorder : ''}`}>
                                                        {' '}<i className={`bi ${sortcol === 'posted_date' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                    </span>
                                                </th>
                                                <th style={{ minWidth: '25%', width: '5%' }} className='text-end'>
                                                    Details
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ? (
                                                <tr>
                                                    <td>
                                                        <span className='indicator-progress' style={{ display: 'block', textAlign: 'center' }}>

                                                        </span>
                                                    </td>
                                                </tr>
                                            ) : (
                                                <TableRowComponentWarning items={currentItems} />
                                            )}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {totalPages > 1 && (
                    <div className="mt-5 mb-n5">
                        <PaginationWrapper
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}
export { TablesWidgetWarning }