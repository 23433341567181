import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useAuth } from '../../../modules/auth';
import { useDispatch } from 'react-redux';
import { UPDATE_ADDONS } from '../../../../_metronic/redux/account/types';
import HourGlass from '../../Loading/HourGlassSpinner/HourGlass';

const AddOns: React.FC = () => {

  const [data, setData] = useState({
    features: {
      translation: false,
      videoTraining: false,
    },
  })
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { currentUser } = useAuth();
  const dispatch = useDispatch();

  const updateData = (newData: any) => {
    setData({ ...data, ...newData })
  }

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(`/reg/v1/user/profile/${currentUser?.id}`);
        setData({
          features: {
            translation: !!Number(response.data[0].has_translation),
            videoTraining: !!Number(response.data[0].has_videotraining),
          },
        });
      } catch (error) {
        console.error("Error fetching data", error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [currentUser]);

  const saveChanges = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      await axios.patch(`/reg/v1/admin/org/addons/${currentUser?.organization}`, {
        columns: {
          has_translation: Number(data.features.translation),
          has_videotraining: Number(data.features.videoTraining),
        },
      });
      dispatch({ type: UPDATE_ADDONS, addons: data.features });
      alert("Features updated successfully")
    } catch (error) {
      console.error("Error saving changes", error);
      alert("Error saving changes")
    }
    setLoading(false);
  };

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className="row">
        <div
          className='col-sm card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0 align-items-center'>
            <h3 className='fw-bolder m-0'>Features</h3>
            {/* {loading && <div className="spinner-border text-primary ms-3" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>} */}
          </div>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form className='form' onSubmit={saveChanges}>
          <div className='card-body border-top px-9 pt-0 pb-0'>
            {isLoading ? (
              <HourGlass height="115px" />
            ) : (
              <div className='table-responsive'>
                <table className='table table-row-dashed border-gray-300 align-middle gy-5'>
                  <tbody >
                    <tr>
                      <td className='col-6 fs-4 fw-bold'>Translation</td>
                      <td className='w-125px'>
                        <div className='form-check form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value=''
                            id='kt_settings_feature_translation'
                            checked={data.features.translation}
                            onChange={() =>
                              updateData({
                                features: {
                                  videoTraining: data.features.videoTraining,
                                  translation: !data.features.translation,
                                },
                              })
                            }
                            style={{ height: '25px', width: '25px' }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className='col-6 fs-4 fw-bold pb-2 pt-5'>Video Training</td>
                      <td className='w-125px pb-2 pt-5'>
                        <div className='form-check form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value=''
                            id='kt_settings_feature_videoTraining'
                            checked={data.features.videoTraining}
                            onChange={() =>
                              updateData({
                                features: {
                                  videoTraining: !data.features.videoTraining,
                                  translation: data.features.translation,
                                },
                              })
                            }
                            style={{ height: '25px', width: '25px' }}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading || isLoading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export { AddOns }