import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios';
import PaginationWrapper from '../../components/PaginationWrapper';
import { useAuth } from '../../modules/auth';
import { useNavigate } from 'react-router-dom';
import HourGlass from '../Loading/HourGlassSpinner/HourGlass';


import TableRowDocuments from './TableRowDocuments';

type Document = {
    agencyId: string;
    iddocument: string;
    id: string;
    document_title: string;
    modifyDate: string;
    program: string;
    documentType: string;
}
type Props = {

    doctype: string
    days: number;
}
const TablesWidget: React.FC<Props> = ({ doctype, days }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [details, setDetails] = useState<Document[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [sortorder, setSortorder] = useState('desc');
    const [sortcol, setSortcol] = useState('modifyDate')
    const [count, setCount] = useState(0)
    const [trackedDocuments, setTrackedDocuments] = useState<any[]>([]);
    const { currentUser, logout } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {

            try {
                const response = await axios.get(`/reg/v1/document/preference/document/days/pagination/${doctype}?page_num=${currentPage}&page_size=${itemsPerPage}&sort_col=${sortcol}&sort_order=${sortorder}&days=${days}`);
                const totalCount = response.data.length > 0 ? response.data[0].document_count : 0;
                setCount(totalCount);
                const documents = response.data;
                setDetails(documents);
                setTotalPages(Math.ceil(totalCount / itemsPerPage));
                setLoading(false);
            } catch (error) {
                console.error("Failed to fetch data:", error);
                setLoading(false);
            }
        };

        fetchData();
    }, [currentPage, itemsPerPage, days, sortcol, sortorder, doctype]);
    const currentItems = details;

    useEffect(() => {
        setCurrentPage(1);
        setLoading(true);
    }, [days, doctype])

    // useEffect(() => {
    //     window.scrollTo(0, document.body.scrollHeight);
    // }, [currentPage]);


    const handleSort = (columnName) => {
        if (sortcol === columnName) {
            setSortorder(sortorder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortcol(columnName);
            setSortorder('asc'); // Default to ascending when changing columns
        }
    };

    const fetchTrackedDocuments = useCallback(async () => {
        try {
            // Always call all three APIs
            const [proposedRulesResponse, finalRulesResponse, guidanceResponse] = await Promise.all([
                axios.get(`/reg/v1/document/user/proposedrules/${currentUser?.id}`),
                axios.get(`/reg/v1/document/user/finalrules/${currentUser?.id}`),
                axios.get(`/reg/v1/document/user/guidance/${currentUser?.id}`)
            ]);

            // Combine the results from all three API responses
            const combinedResults = [
                ...proposedRulesResponse.data,
                ...finalRulesResponse.data,
                ...guidanceResponse.data
            ];

            // Set the tracked documents state
            setTrackedDocuments(combinedResults);
            return combinedResults;

        } catch (error) {
            console.log('Failed to fetch tracked documents', error);
            return [];
        }
    }, [currentUser?.id]);

    const handleTrackDocument = (id) => {
        // setLoading(true);
        axios.post(`/reg/v1/document/track/${currentUser?.id}/${id}`)
            .then((response) => {
                console.log({ message: response.data, type: 'success' });
                // fetchTrackedDocuments(); // Fetch tracked documents again after tracking a new document
                setTrackedDocuments(prevState => {
                    const newState = prevState.map(doc => {
                        if (doc.iddocument === id) {
                            // console.log('Toggling track for document:', doc);
                            return { ...doc, track: doc.track === 1 ? 0 : 1 };
                        } else {
                            //console.log('Not toggling track for document:', doc);
                            return doc;
                        }
                    });
                    return newState;
                });

                // console.log('Tracked documents:', trackedDocuments.some(trackedDocument => trackedDocument.id === document.id && trackedDocument.track === 1));

                // setLoading(false);

            })
            .catch((error) => {
                if (error.response.status === 401) {
                    logout();
                    navigate('/auth/login');
                } else {
                    console.error('Failed to track document:', error);
                }
                // setLoading(false);
            });
        //fetchTrackedDocuments();
    }

    useEffect(() => {
        if (currentUser) {
            fetchTrackedDocuments();
        }
    }, [currentUser, fetchTrackedDocuments]);

    return (
        <div className="container-fluid p-0 m-0">
            <div className="row no-gutters">
                <div className="col">
                    <div className={`card `}>

                        <div className='card-header border-0 pt-5'>
                            <div className="d-flex flex-column" >

                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bold fs-3 mb-1'>
                                        {doctype === 'Proposed Rule' ? 'Proposed Rules' : doctype === 'Rule' ? 'Final Rules' : doctype === 'Notice' ? 'Notices' : doctype}
                                        {/* {loading && <span className='spinner-border text-gray-600 align-middle ms-2'></span>} */}

                                    </span>
                                </h3>
                                {!loading && count > 0 && (
                                    <span className="fs-6 text-gray-700"> Showing {((currentPage - 1) * itemsPerPage) + 1} to {Math.min(currentPage * itemsPerPage, count)} of {count} results</span>
                                )}
                                {!loading && count === 0 && (
                                    <span className="fs-6 text-gray-700"> 0 results </span>
                                )}
                            </div>
                        </div>

                        <div className='card-body px-8 '>

                            <div className='table-responsive '>
                                {loading ? (
                                    <HourGlass height="25vh" />
                                ) : (
                                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-1 gy-3 ' style={{ width: '100% !important', cursor: 'pointer' }}>

                                        <thead>
                                            <tr className='fw-bolder text-gray-600 fs-5'>
                                                <th style={{ minWidth: '10%', width: '10%' }} onClick={() => handleSort('agencyId')}>
                                                    Agency
                                                    <span className={`sorting-icon ${sortcol === 'agencyId' ? sortorder : ''}`}>
                                                        {' '}<i className={`bi ${sortcol === 'agencyId' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                    </span>
                                                </th>

                                                <th style={{ minWidth: '10%', width: '10%' }} onClick={() => handleSort('program')}>
                                                    Program
                                                    <span className={`sorting-icon ${sortcol === 'program' ? sortorder : ''}`}>
                                                        {' '}<i className={`bi ${sortcol === 'program' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                    </span>
                                                </th>
                                                <th style={{ minWidth: '74%', width: '74%' }} onClick={() => handleSort('document_title')}>
                                                    Title
                                                    <span className={`sorting-icon ${sortcol === 'document_title' ? sortorder : ''}`}>
                                                        {' '}<i className={`bi ${sortcol === 'document_title' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                    </span>
                                                </th>
                                                <th className="text-end" style={{ minWidth: '8%', width: '8%' }} onClick={() => handleSort('modifyDate')}>
                                                    Date
                                                    <span className={`sorting-icon ${sortcol === 'modifyDate' ? sortorder : ''}`}>
                                                        {' '}<i className={`bi ${sortcol === 'modifyDate' ? (sortorder === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                                    </span>
                                                </th>
                                                {doctype !== "Notice" && (
                                                    <th style={{ minWidth: '2%', width: '2%' }} className="text-start"></th>
                                                )}

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ? (

                                                <tr>
                                                    <td>
                                                        <span className='indicator-progress' style={{ display: 'block', textAlign: 'center' }}>

                                                        </span>
                                                    </td>
                                                </tr>

                                            ) : (

                                                <TableRowDocuments
                                                    items={currentItems}
                                                    trackedDocuments={trackedDocuments}
                                                    handleTrackDocument={handleTrackDocument}
                                                />

                                            )}
                                        </tbody>
                                    </table>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
                {totalPages > 1 && (
                    <div className="mt-5 mb-n5">
                        <PaginationWrapper
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}
export { TablesWidget }