/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react'
import { ILayout, useLayout } from '../../core'
import { useState } from 'react'
import { SupportModal } from './SupportModal'
// import { useFormik } from 'formik';
// import * as Yup from 'yup';

const Footer = () => {
  const { config } = useLayout()
  const [displayModal, setDisplayModal] = useState(false);
  const [isSupportHovered, setIsSupportHovered] = useState(false);
  const [isFeedbackHovered, setIsFeedbackHovered] = useState(false);
  const [activeTab, setActiveTab] = useState<string | null>(null);

  useEffect(() => {
    updateDOM(config)
  }, [config])

  const toggleModal = (tab: string) => {
    setActiveTab(tab);
    setDisplayModal(prevDisplayModal => !prevDisplayModal);
  };

  return (
    <>
      {/* <div className='text-dark order-2 order-md-1'> */}
      {/* <span className='text-muted fw-semibold me-1'>
          {new Date().getFullYear().toString()}&copy;
        </span> */}
      <ul className='menu menu-gray-100 menu-hover-primary fw-bold order-1'>
        <li className='menu-item'>
          <a
            href='https://www.lexim.ai/'
            target='_blank'
            className='menu-link px-4'
          >
            LEXIM.AI
          </a>
        </li>
        <li className='menu-item'>
          <a
            href='https://www.lexim.ai/research'
            target='_blank'
            className='menu-link px-4'
          >
            Blogs
          </a>
        </li>

        <li className='menu-item'>
          <a
            href='https://www.lexim.ai/contact-us'
            target='_blank'
            className='menu-link px-4'
          >
            Contact Us
          </a>
        </li>
        <li className='menu-item'>
          <button
            onMouseEnter={() => setIsSupportHovered(true)}
            onMouseLeave={() => setIsSupportHovered(false)}
            onClick={() => toggleModal('Support')}
            className='menu-link px-4'
            style={{
              background: 'none',
              border: 'none',
              padding: '0',
              color: isSupportHovered ? '#3e97ff' : '#eff2f5', // Change color on hover
              marginTop: '8px',
              font: 'inherit',
              cursor: 'pointer',
              textDecoration: 'none',
              textAlign: 'center',
              display: 'flex', // make the button a flex container
              alignItems: 'center', // center the text vertically
              justifyContent: 'center' // center the text horizontally
            }}
          >
            Support
          </button>
        </li>
        <li className='menu-item'>
          <button
            onMouseEnter={() => setIsFeedbackHovered(true)}
            onMouseLeave={() => setIsFeedbackHovered(false)}
            onClick={() => toggleModal('Feedback')}
            className='menu-link px-4'
            style={{
              background: 'none',
              border: 'none',
              padding: '0',
              color: isFeedbackHovered ? '#3e97ff' : '#eff2f5', // Change color on hover
              marginTop: '8px',
              font: 'inherit',
              cursor: 'pointer',
              textDecoration: 'none',
              textAlign: 'center',
              display: 'flex', // make the button a flex container
              alignItems: 'center', // center the text vertically
              justifyContent: 'center' // center the text horizontally
            }}
          >
            Feedback
          </button>
        </li>
      </ul>
      {/* </div> */}

      <ul className='menu menu-gray-100 menu-hover-primary fw-bold order-1'>
        <li className='menu-item'>
          <a href='https://www.lexim.ai/terms' target='_blank' rel="noreferrer" className='menu-link px-4'>
            Terms of Service
          </a>
        </li>
        <li className='menu-item'>
          <a href='https://www.lexim.ai/privacy' target='_blank' rel="noreferrer" className='menu-link px-4'>
            Privacy
          </a>
        </li>
        {/* <li className='menu-item'>
          <a href='https://lexim.ai/' target='_blank' className='menu-link px-4'>
            Cookies
          </a>
        </li> */}
        <li className='menu-item'>
          <a href='https://www.lexim.ai' target='_blank' className='menu-link px-4'>
            Copyright © 2024. Powered by LEXIM REGINTEL™.
          </a>
        </li>

      </ul>
      <SupportModal activeTab={activeTab} displayModal={displayModal} onClose={() => setDisplayModal(false)} />
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export { Footer }