import React, { useState, useEffect } from "react";
import SearchBar from "../../components/SearchBar";
import { KTIcon } from "../../../_metronic/helpers";
import XmlFileViewer from "./XmlFileViewer";
import axios from 'axios';

const Title21 = () => {
    const [searchInput, setSearchInput] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [amendDate, setAmendDate] = useState('');

    // Fetch amendment date from API
    useEffect(() => {
        const fetchAmendDate = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get('/reg/v1/fda/cfr_pipeline/latest_issue_date');
                setAmendDate(response.data.latest_issue_date); // Update state with the fetched date
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching amendment date:', error);
            }
        };

        fetchAmendDate();
    }, []);

    return (
        <div>
            <div className="d-flex flex-column px-5 py-0">
                <div className="d-flex flex-column-auto mb-2 mt-2">
                    <h1 className="d-md-block text-wrap" style={{ color: "#4d4d4d" }}>
                        <div className="d-flex align-items-center">
                            <KTIcon iconName="book-open" iconType="duotone"
                                className="fw-bold fs-1 mx-3 text-primary" />
                            <span>Title 21: Food and Drugs</span>
                        </div>
                    </h1>
                </div>
            </div>
            <div className="row mt-0 justify-space-between ps-5 pe-10">
                <div className="col-7">
                    <SearchBar
                        searchInput={searchInput}
                        setSearchInput={setSearchInput}
                        placeholder={"Search CFR Title 21"}
                    />
                </div>
                <div className="col-5 d-flex justify-content-end px-0 p-5">
                    <div className="align-items-center ms-2 mt-1">
                        {!isLoading && amendDate !== '' &&
                            <h4 style={{ color: "#4d4d4d" }}>Amendment Date: {amendDate}</h4>
                        }
                    </div>
                </div>
            </div>
            <div className="card p-2 mx-auto text-wrap"
                style={{
                    borderRadius: '2px',
                    boxShadow: 'none',
                    height: '100%',
                    border: 'none',
                }}>
                <XmlFileViewer
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    setSearchInput={setSearchInput}
                    // expandAll={expandAll}
                    // collapseAll={collapseAll}
                    // setExpandCollapse={setExpandCollapse}
                    searchInput={searchInput}
                />
            </div>
        </div>
    );
};

export default Title21;
