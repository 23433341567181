import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { useAuth } from '../../../modules/auth';

const buildFolderPath = (folder, folders) => {
    if (!folder.parent_id) {
        return folder.name;
    }
    const parentFolder = folders.find(f => f.id === folder.parent_id);
    if (parentFolder) {
        return `${buildFolderPath(parentFolder, folders)}/${folder.name}`;
    }
    return folder.name;
};

const fetchFolders = async (currentUser) => {
    try {
        const response = await axios.get(`/reg/v1/document/folder/${currentUser?.organization}/${currentUser?.id}`);
        const folders = response.data.map((folder) => ({
            id: folder.folder_id,
            name: folder.folder_name,
            type: 'folder',
            folder: folder.parent_id ? response.data.filter((f: any) => f.folder_id === folder.parent_id)[0]?.folder_name : "All Documents",
            parent_id: folder.folder_id !== folder.parent_id ? folder.parent_id : null,
            s3_link: folder.s3_link || '',
            create_date: new Date(folder.create_date || Date.now()),
            doc_name: '',
            files: [],
        }));

        const folderDictionary = folders.reduce((acc: { [key: string]: {} }, folder) => {
            if (folder.name !== 'Recently Deleted') {
                const path = buildFolderPath(folder, folders);
                acc[path] = {
                    id: folder.id,
                    s3_link: folder.s3_link
                }
            }
            return acc;
        }, {});

        const sortedFolderDictionary = Object.keys(folderDictionary)
            .sort()
            .reduce((acc: { [key: string]: {} }, key) => {
                acc[key] = folderDictionary[key];
                return acc;
            }, {});

        return sortedFolderDictionary;
    } catch (error) {
        console.error('Error fetching folders:', error);
        throw error;
    }
};

const MoveFolderModal = ({
    showModal,
    setShowModal,
    moveFile,
    handleMoveFolder,
    isLoading,
    fromWorkbench = false
}) => {
    const [moveFolderID, setMoveFolderID] = useState(moveFile && moveFile.parent_id ? moveFile.parent_id : 0);
    const [listFolders, setListFolders] = useState({});
    const [error, setError] = useState<Error | null>(null);
    const [fetchingFolders, setFetchingFolders] = useState(true);
    const { currentUser } = useAuth();
    moveFile = moveFile || { parent_id: 0, name: '', type: '' };

    useEffect(() => {
        const fetchData = async () => {
            setFetchingFolders(true);
            try {
                const fetchedFolders = await fetchFolders(currentUser);
                setListFolders(fetchedFolders);
            } catch (err) {
                setError(err as Error);
            } finally {
                setFetchingFolders(false);
            }
        };

        fetchData();
    }, [currentUser]);

    useEffect(() => {
        setMoveFolderID(moveFile && moveFile.parent_id ? moveFile.parent_id : 0);
    }, [moveFile]);

    const closeModal = () => {
        setShowModal(false);
        setMoveFolderID(moveFile && moveFile.parent_id ? moveFile.parent_id : 0);
    };


    return (
        <Modal show={showModal} onHide={closeModal} dialogClassName="modal-dialog-centered">
            <Modal.Header closeButton>
                <Modal.Title>
                    {fromWorkbench ? `Save` : `Move`}  <span className="fw-semibold">{moveFile && moveFile.name}</span> to {fromWorkbench ? `` : `another`} Folder
                </Modal.Title>
            </Modal.Header>

            {error ? (
                <div className="alert alert-danger" role="alert">
                    Error fetching folders: {error.message}
                </div>
            ) : fetchingFolders ? (
                <div className="text-center py-5">
                    <span className="indicator-progress">
                        Please wait...{' '}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </div>
            ) : Object.keys(listFolders).length > 0 ? (
                <div className="form-group px-10 py-7">
                    <select
                        className="form-select"
                        id="folder"
                        value={moveFolderID}
                        onChange={(e) => setMoveFolderID(Number(e.target.value))}
                    >
                        <option value={0}>Root Folder</option>
                        {Object.entries(listFolders).map(([folderName, folderData]: [string, any]) => (
                            folderName !== moveFile.name && (
                                <option key={folderData.id} value={folderData.id}>
                                    {folderName}
                                </option>
                            )
                        ))}
                    </select>
                </div>
            ) : (
                <div className="text-center py-5">No folders available.</div>
            )}

            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={closeModal}
                >
                    Close
                </button>

                <button
                    type="button"
                    className="btn btn-primary"
                    disabled={isLoading || (fromWorkbench ? false : (moveFile && moveFile.parent_id !== null ? moveFolderID === moveFile.parent_id : moveFolderID === 0)) || fetchingFolders}
                    onClick={() => {
                        if (fromWorkbench) {
                            handleMoveFolder(moveFolderID, moveFile, listFolders[moveFolderID]?.s3_link);
                        } else {
                            handleMoveFolder(moveFolderID, moveFile);
                        }
                        closeModal();
                    }}
                >
                    {!isLoading ? `${fromWorkbench ? `Save` : `Move`} ${moveFile && moveFile.type === "folder" ? 'Folder' : 'File'}` : (
                        <span className="indicator-progress" style={{ display: 'block' }}>
                            Please wait...{' '}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    )}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default MoveFolderModal;