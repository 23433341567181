import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import axios from 'axios';
import { useState, useEffect } from 'react';

type Props = {
    className: string;
    chartHeight: string;
    data?: DataItem[];
    title: string;
    activity: string;
    columns: { [key: string]: any; };
    scale: string;
    days: number;
    doctype: string;
};

interface DataItem {
    company_name: string;
    issuing_office: string;
    letter_issue_date: string;
    posted_date: string;
    id: string;
    program_id: string;
    subject: string;
    warning_letter_url: string;
    count: number;
}

const MixedWidgetWarning: React.FC<Props> = ({ className, chartHeight, title, days, doctype }) => {
    // Step 1: Group by Program

    const [data, setData] = useState<DataItem[]>([]);

    useEffect(() => {
        // Fetch data from the API when the component mounts
        axios.get(`/reg/v1/fda/preference/warning_letter/graph?days=${days}`)
            .then(response => {
                setData(response.data.sort((a, b) => a.issuing_office.localeCompare(b.issuing_office))); // Assuming response.data is an array of DataItem objects
            })
            .catch(error => console.error('Error fetching data:', error));
    }, [days, doctype]);

    // const groupedByProgram = data.reduce<Record<string, DataItem[]>>((acc, item) => {
    //     const issuing_office = item.issuing_office || 'Unknown'; // Handle items without a program
    //     if (!acc[issuing_office]) {
    //         acc[issuing_office] = [];
    //     }
    //     acc[issuing_office].push(item);
    //     return acc;
    // }, {});

    // Step 2: Count Documents per Program
    const series: Highcharts.SeriesOptionsType[] = data.map(item => ({
        name: item.issuing_office,
        data: [{ y: item.count }],
        type: 'column',
    }));


    const options: Highcharts.Options = {
        chart: {
            type: 'column',
            height: chartHeight,
            marginRight: 10,

        },
        xAxis: {
            type: 'category',
            // Set categories to program names
            title: {
                text: 'Programs',
            },
            labels: {
                enabled: false // This will hide all labels on the xAxis
            },
            width: '95%',


        },
        title: {
            text: ''
        },
        yAxis: {

            type: 'logarithmic',
            title: {
                text: 'Documents',
            },
            // Adds minor ticks to make the scale clearer

        },
        legend: {
            enabled: true,
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
            floating: false,
            width: '85%', // Increase width to accommodate more text
            maxHeight: 70,
            margin: 1,
            itemMarginBottom: 1,
            itemStyle: {
                fontSize: '10px', // Reduce font size to fit more text
                textOverflow: 'ellipsis' // Add ellipsis for overflowing text
            },
            navigation: {
                enabled: true, // Enable navigation for overflow
                arrowSize: 12
            },
            // Optionally, set a fixed itemWidth if items are consistently overflowing
            itemWidth: 150 // Adjust based on your needs
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    formatter: function (this: Highcharts.Point) {
                        return Highcharts.numberFormat(this.y !== undefined ? this.y : 0, 0, '.', ','); // Use 0 as default if undefined
                    },
                },
                pointPlacement: -0.1,
                // gapSize: 4,
            },
            // column: { // Assuming you're working with a column chart
            //     pointWidth: 15, // Move pointWidth here if column series supports it
            //     pointPadding: 0.1,

            // },
        },
        credits: {
            enabled: false
        },
        tooltip: {
            useHTML: true, // Enable HTML in the tooltip
            formatter: function () {
                // Assuming 'this' refers to the point or series with access to its name and y (value)
                // Customize this string to match your desired tooltip format
                return `<b>${this.series.name}</b>: ${Highcharts.numberFormat(this.y !== undefined ? this.y : 0, 0, '.', ',')}`;
            },
            style: {
                width: '100px' as any, // Set a fixed width for the tooltip
                whiteSpace: 'normal' // Allow text to wrap within the tooltip
            }
        },

        series: series, // Use the new series array
    };

    return (
        <div className={`card ${className} `} >

            <div className='card-body  d-flex flex-column '>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
            </div>
        </div>
    );
};

export { MixedWidgetWarning };