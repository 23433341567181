/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Spinner, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import { debounce } from 'lodash';
import DocumentCard from '../../../../app/components/DocumentTitleCard';
import SearchBar from '../../../../app/components/SearchBar';
import { KTIcon } from '../../../helpers';
import ReactMarkdown from 'react-markdown';
import MoveFolderModal from '../../../../app/pages/MyDocuments/Modals/MoveFolderModal';
import FolderSuccessModal from '../../../../app/pages/MyDocuments/Modals/FolderSuccessModal';
import jsPDF from 'jspdf';
import { useAuth } from '../../../../app/modules/auth';
import { marked } from "marked";
import moment from 'moment';
import HourGlass from '../../../../app/pages/Loading/HourGlassSpinner/HourGlass';


const API_URL = '/reg/v1/document/suggested_documents/';
const API_URL_search = '/reg/v1/search/doc_search';

const CompareDocuments = ({ document, documentType = ['Proposed Rule', 'Rule'] }) => {

  const questionPrompt = `
  Compare the following two regulatory documents and identify similarities and differences between the two chapters, considering they may be different versions of a related regulation. Only use content under the Department of Health and Human Services and the Food and Drug Administration sections.

  Scope and Purpose: What specific areas do they regulate? What are their stated goals? How has the scope or purpose changed between versions (if applicable)?

  Definitions: Are there key terms defined differently or uniquely in each chapter? Note any new definitions or changes to existing definitions.

  Requirements and Standards: What specific actions or conditions do they mandate? How do these compare in stringency and detail? Identify any new requirements, removed requirements, or modifications to existing requirements. Explain the impact of these changes.

  Enforcement and Penalties: How is compliance monitored and enforced? What are the consequences of non-compliance? Have enforcement mechanisms or penalties changed?

  Target Audience/Affected Parties: Who are the primary entities regulated by each chapter? Have the affected parties changed?

  New Content/Amendments: Specifically identify and describe any new content added or amendments made in the later document (if applicable). Explain the rationale behind these changes, if possible.

  Differences: Itemize and explain any discrepancies between the two documents. Analyze the significance of these differences.

  Relationship Between Documents: If it can be determined, describe the relationship between the two documents (e.g., updated version, related but distinct regulations).
`;
  const [allDocs, setAllDocs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [selectedDocId, setSelectedDocId] = useState(null);
  const [doc2Selected, setDoc2Selected] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState('');
  const [response, setResponse] = useState('');
  const [searchUsingDocType, setSearchUsingDocType] = useState(false);
  const [selectedDocType, setSelectedDocType] = useState('')
  const [selectedPostedDate, setSelectedPostedDate] = useState('')
  const [boxHeight, setBoxHeight] = useState(`${(Math.ceil(document.dk_title.length / 100) - 1) * 15 + 85}px`);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [message, setMessage] = useState('');
  const { currentUser } = useAuth();
  const contentRef = useRef(null);

  const formatDate = useCallback((timestamp) => {
    return moment.utc(timestamp).format('YYYY-MM-DD'); // Format in UTC
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).catch(() => {
      alert('Failed to copy.');
    });
  };

  const downloadAnswer = async (QAanswer, download = true) => {
    // Convert markdown content to HTML
    const markdownContent = QAanswer || "No Comparison Data";
    const htmlContent = marked(markdownContent);

    // Initialize jsPDF
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "letter", // US Letter size (8.5" x 11")
    });

    // Define margins and page dimensions
    const margin = 30; // 20pt margin on all sides
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const contentWidth = pageWidth - 2 * margin; // Usable content width
    const lineHeight = 14; // Line height in points
    let yOffset = 30; // Start position for text

    // Add the title at the center top
    const title = "Lexim Document Comparison";
    pdf.setFont("Helvetica", "bold");
    pdf.setFontSize(16);
    const titleWidth = pdf.getTextWidth(title);
    pdf.text(title, (pageWidth - titleWidth) / 2, yOffset);
    yOffset += lineHeight * 2;

    // Parse the HTML content for rendering
    const parser = new DOMParser();
    const parsedHTML = parser.parseFromString(htmlContent, "text/html");

    const renderElement = (element) => {
      if (yOffset + lineHeight > pageHeight - margin) {
        // Add a new page if content exceeds the current page
        pdf.addPage();
        yOffset = margin;
      }

      switch (element.nodeName.toLowerCase()) {
        case "h1":
          renderHeader(element, 14, lineHeight);
          break;
        case "h2":
          renderHeader(element, 12, lineHeight);
          break;
        case "p":
          renderParagraph(element, lineHeight);
          break;
        case "ul":
          renderList(element);
          break;
        default:
          // Render as normal text block
          renderTextBlock(element, lineHeight);
          break;
      }
    };

    const renderList = (element) => {
      pdf.setFont("Helvetica", "normal");
      pdf.setFontSize(10);
      element.querySelectorAll("li").forEach((li) => {
        const liText = li.textContent.trim();
        const liParts = liText.split(": "); // Split the text before and after the colon

        if (liParts.length > 1) {
          // First part (before the colon) should be bolded
          const boldText = liParts[0] + ": "; // The part before the colon
          let regularText = liParts[1]; // The part after the colon

          if (!regularText.startsWith(" ")) {
            regularText = " " + regularText;
          }
          // Add the bullet point before the bolded text
          const bulletText = `• ${boldText}`;
          const fullText = bulletText + " " + regularText; // Full text with bullet and space after colon

          // Split the full text to respect width and avoid overflow
          const fullTextLines = pdf.splitTextToSize(fullText, contentWidth - 10);

          // Render each line of the full text
          fullTextLines.forEach((line, index) => {
            if (yOffset + lineHeight > pageHeight - margin) {
              pdf.addPage();
              yOffset = margin;
            }

            // If it's the first line, render the bold part and regular part
            if (index === 0) {
              // Render the bold part (before the colon)
              pdf.setFont("Helvetica", "bold");
              const splitIndex = line.indexOf(": "); // Find where the colon is to separate bold and regular text
              const boldPart = line.substring(0, splitIndex + 2); // Include the colon in bold
              pdf.text(boldPart, margin + 10, yOffset);

              // Then, render the regular part (after the colon)
              pdf.setFont("Helvetica", "normal");
              const regularPart = line.substring(splitIndex + 2); // Everything after the colon
              pdf.text(regularPart, margin + 10 + pdf.getTextWidth(boldPart), yOffset); // Ensure the regular part follows the bold part
            } else {
              // For wrapped lines, just add the regular part
              pdf.setFont("Helvetica", "normal");
              pdf.text(line, margin + 10, yOffset);
            }

            yOffset += lineHeight;
          });
        }
        else {
          // Regular list item without a colon
          const bulletText = "• " + liText;
          const lines = pdf.splitTextToSize(bulletText, contentWidth);
          lines.forEach((line) => {
            if (yOffset + lineHeight > pageHeight - margin) {
              pdf.addPage();
              yOffset = margin;
            }
            pdf.text(line, margin + 10, yOffset);
            yOffset += lineHeight;
          });
        }
      });
      //yOffset += lineHeight * 0.5; // Extra space after list
    };

    const renderParagraph = (element, lineHeight) => {
      pdf.setFontSize(11);
      let xOffset = margin; // Left margin for text

      // Iterate through the child nodes of the <p> element
      element.childNodes.forEach((child) => {
        if (child.nodeType === Node.TEXT_NODE) {
          // Normal text node: directly append it as regular text
          const text = child.textContent.trim();
          if (text) {
            pdf.setFont("Helvetica", "normal");
            const lines = pdf.splitTextToSize(text, contentWidth);
            lines.forEach((line) => {
              if (yOffset + lineHeight > pageHeight - margin) {
                pdf.addPage();
                yOffset = margin;
              }
              pdf.text(line, xOffset, yOffset);
              yOffset += lineHeight;
            });
          }
        } else if (child.nodeType === Node.ELEMENT_NODE) {
          if (child.nodeName.toLowerCase() === "strong") {
            // Bold text: handle it separately inside <strong> tags
            const boldText = child.textContent.trim();
            if (boldText) {
              pdf.setFont("Helvetica", "bold"); // Apply bold font
              const lines = pdf.splitTextToSize(boldText, contentWidth);
              lines.forEach((line) => {
                if (yOffset + lineHeight > pageHeight - margin) {
                  pdf.addPage();
                  yOffset = margin;
                }
                pdf.text(line, xOffset, yOffset);
                yOffset += lineHeight;
              });
            }
          }
        }
      });

      //yOffset += lineHeight * 0.5; // Extra space after the paragraph
    };

    const renderTextBlock = (element, lineHeight) => {
      pdf.setFont("Helvetica", "normal");
      pdf.setFontSize(10);

      let xOffset = margin; // Start at the left margin
      let currentLine = ""; // Variable to store the current line content

      // Handle inline bolding and normal text within the text block
      element.childNodes.forEach((child) => {
        if (child.nodeType === Node.TEXT_NODE) {
          // Normal text node: append to current line
          const text = child.textContent.trim();
          currentLine += text + " "; // Add normal text with a space
        } else if (child.nodeType === Node.ELEMENT_NODE) {
          if (child.nodeName.toLowerCase() === "strong" || child.nodeName.toLowerCase() === "b") {
            // Bold text: apply bold font
            const boldText = child.textContent.trim();
            pdf.setFont("Helvetica", "bold");
            currentLine += boldText + " "; // Add bold text with a space
            pdf.setFont("Helvetica", "normal"); // Reset font to normal after the bold text
          } else {
            // Regular text: append as normal text
            const regularText = child.textContent.trim();
            currentLine += regularText + " ";
          }
        }
      });

      // Now that we have the full line, we split it into lines that fit the content width
      const splitLines = pdf.splitTextToSize(currentLine, contentWidth);

      // Render the lines
      splitLines.forEach((line) => {
        if (yOffset + lineHeight > pageHeight - margin) {
          pdf.addPage();
          yOffset = margin;
        }
        pdf.text(line, xOffset, yOffset);
        yOffset += lineHeight;
      });
    };

    // Handling a header that is bold
    const renderHeader = (element, fontSize, extraSpace) => {
      pdf.setFont("Helvetica", "bold");
      pdf.setFontSize(fontSize);
      const lines = pdf.splitTextToSize(element.textContent, contentWidth);
      lines.forEach((line) => {
        if (yOffset + lineHeight > pageHeight - margin) {
          pdf.addPage();
          yOffset = margin;
        }
        pdf.text(line, margin, yOffset);
        yOffset += lineHeight;
      });
      yOffset += extraSpace; // Add extra space after header
    };

    // Render each child element of the parsed HTML
    parsedHTML.body.childNodes.forEach((child) => {
      renderElement(child);
    });

    // Save the PDF
    const date = new Date().toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).replace(/\//g, '-').replace(/:/g, '-'); // Replace underscores with dashes

    const pdfTitle = `Lexim_Document_Comparison ${date}.pdf`;

    if (download) {
      pdf.save(pdfTitle); // Download the PDF
    } else {
      // Generate the Blob and check its type
      const pdfBlob = pdf.output('blob', { filename: pdfTitle });

      return [pdfBlob, pdfTitle];  // Return the Blob and filename for further use
    }
  };

  const handleSaveFolder = async (moveFile) => {
    const [pdfBlob, filename] = await downloadAnswer(moveFile.QAanswer, false);

    // Ensure pdfBlob is a valid Blob
    if (!(pdfBlob instanceof Blob)) {
      console.error('Failed to generate a valid Blob object.');
      return;
    }

    // Check PDF size before upload
    const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20 MB
    if (pdfBlob.size > MAX_FILE_SIZE) {
      alert('File size is greater than 20 MB');
      return;
    }

    const formData = new FormData();
    formData.append('file', pdfBlob, filename);
    // const uploadFormData = new FormData();
    // uploadFormData.append('files', pdfBlob, filename);
    formData.append('title', filename);
    formData.append('description', '');
    formData.append('genai_requested', 'False');
    formData.append('translation_requested', 'False');
    formData.append('video_training_requested', 'False');
    formData.append('translate_from', '');
    formData.append('translate_to', '');
    //formData.append('folder_id', `None`);
    //formData.append('folder_s3_link', folder_s3_link)

    // uploadFormData.append('user_id', String(currentUser?.id));
    // uploadFormData.append('vault_s3_link', folder_s3_link);

    // const response = await fetch(`${process.env.REACT_APP_DEEP_API_URL}/upload-file`, {
    //   method: 'POST',
    //   body: uploadFormData
    // })

    // const response = await fetch(`https://deepapi.lexim.ai/upload-file`, {
    //   method: 'POST',
    //   body: uploadFormData
    // })

    // if (response.ok) {

    //   const data = await response.json();

    //   const uploadedResults = JSON.parse(data[0].body);
    //   if (uploadedResults) {
    //     uploadedResults.forEach(result => {
    //       formData.append('mv_document_id_external', result.mv_document_id_external);
    //       formData.append('s3_link', result.file_path);
    //     });
    //   } else {
    //     console.error('Uploaded results are missing or undefined');
    //   }

    axios.post(`/reg/v1/document/upload/${currentUser?.organization}/${currentUser?.id}`, formData)
      .then(response => {
        setIsLoading(false);
        setMessage('Document uploaded successfully!')
        setShowSuccessModal(true);
        // axios.patch(`/reg/v1/user/myresearch`, {
        //   question: ,
        //   saved_to_cabinet: true,
        // })
        //   .catch(error => {
        //     console.error('There was an error!', error);
        //   }
        // )
      })
      .catch(error => {
        setIsLoading(false);
        if (error.response && error.response.status === 413) {
          setMessage(`Failed to upload document due to size limit: File size is greater than 20 MB`);
          setShowSuccessModal(true);
        } else {
          setMessage(`Failed to upload documents: \n ${error.response?.data?.message || 'Unknown error'}`)
          const failedMessages = error.response?.data?.filter((item) => item.status === "failed").map((item) => item.message).join('\n');
          setMessage(`Failed to upload documents: \n ${failedMessages || 'Unknown error'}`);
          setShowSuccessModal(true);
        }
        console.error('There was an error!', error);

      });
    // }
    // else {
    //   console.error('Failed to upload documents:', response);
    //   setMessage('Failed to upload documents: \n' + response.statusText);
    //   setShowSuccessModal(true);
    // }
  }

  const fetchDocBySearch = useCallback(
    debounce(async () => {
      if (!searchInput.trim()) return; // Exit if search input is empty
      setIsLoading(true);
      try {
        const response = await axios.get(API_URL_search, {
          params: { search_string: searchInput }, // Pass query params
        });
        const data = Array.isArray(response.data) ? response.data : Object.values(response.data);
        setAllDocs(data);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
        //setSelectedDocId(null);
        setSearchUsingDocType(true);
      }
    }, 300),
    [searchInput, documentType]
  );

  useEffect(() => {
    fetchDocBySearch()
  }, [searchInput]);

  // Fetch documents with debounce
  const fetchDocs = useCallback(
    debounce(async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${API_URL}${document.docketId}`, {
          params: { documentId: document.iddocument, searchInput },
        });
        const data = response.data;
        setAllDocs(data);
      } catch (err) {
        setError('Failed to load documents.');
      } finally {
        setIsLoading(false);
        setSelectedDocId(null);
      }
    }, 300),
    [document] // Add searchInput to the dependency array
  );

  const onCompareClick = () => {
    setDoc2Selected(true);

    const longerTitle = document.dk_title.length > selectedTitle.length ? document.dk_title : selectedTitle;

    const lineCount = Math.ceil(longerTitle.length / 50);

    const newHeight = 80 + (lineCount - 1) * 15;

    setBoxHeight(`${newHeight}px`);

    askQuestion();
  };

  const handleDocumentSelection = (docId, docTitle, docType, postedDate) => {
    setSelectedDocId(docId);
    setSelectedTitle(docTitle);
    setSelectedDocType(docType);
    setSelectedPostedDate(postedDate);
  };

  const askQuestion = async () => {

    setResponse('Thinking...');
    try {

      // const API_URL_deep = `http://127.0.0.1:8000/process-query`;

      const API_URL_deep = `${process.env.REACT_APP_DEEP_API_URL}/process-query`;

      // const API_URL_deep = `https://deepapi.lexim.ai/process-query`;

      let response1 = await axios.get(`/reg/v1/document/all_documents/viewpdf/${document.id}`);
      let url1 = response1.data.url;

      let response2 = await axios.get(`/reg/v1/document/all_documents/viewpdf/${selectedDocId}`);
      let url2 = response2.data.url;

      const payLoad = new FormData();
      payLoad.append('question', questionPrompt);
      payLoad.append('document_ID2', url1);
      payLoad.append('document_ID3', url2);

      const response = await fetch(API_URL_deep, {
        method: 'POST',
        body: payLoad,
      });

      if (!response.body) {
        throw new Error('No response body available for streaming');
      }
      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let receivedText = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        receivedText += decoder.decode(value, { stream: true });

        setResponse(receivedText);
      }
    } catch (error) {
      setResponse('Documents cannot be parsed');
    }
  };

  // Fetch documents when the component mounts or searchInput changes
  useEffect(() => {
    fetchDocs();
  }, [fetchDocs]);

  const removeDocumentSelected = () => {
    setDoc2Selected(false);
    setSelectedDocId('');
    setSelectedTitle('');
    setSelectedDocType('');
    setSelectedPostedDate('');
    setBoxHeight(`${(Math.ceil(document.dk_title.length / 100) - 1) * 15 + 85}px`);

  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {/* Document Card at the top (fixed) */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          position: 'sticky',
          top: '0',
          backgroundColor: 'white',
          zIndex: 2,
          padding: '5px',
          width: '100%',
          gap: '10px', // Adds spacing between cards
          alignItems: 'stretch', // Ensures both cards stretch to the same height
        }}
      >
        {/* First Document Card */}
        <div
          className=''
          style={{
            flex: '1', // Adjusts the width dynamically
            display: 'flex',
            flexDirection: 'column', // Ensures content inside stretches to full height
            height: 'auto', // Allows it to stretch to match sibling height
          }}
        >
          <DocumentCard
            docTitle={document.dk_title}
            docTopics={document.topics}
            docType={document.documentType}
            docPostedDate={document.postedDate}
            showTopics={false}
            showKeywords={false}
            showDates={true}
            height={boxHeight}
            style={{
              height: '100%',
              boxSizing: 'border-box',
            }}
          />
        </div>

        {/* Second Document Card (only if doc2Selected is true) */}
        {doc2Selected && (
          <div
            className=''

            style={{
              flex: '1',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              height: 'auto', // Matches sibling height
              position: 'relative', // For the close button
            }}
          >
            <DocumentCard
              docTitle={selectedTitle}
              docType={selectedDocType}
              docPostedDate={selectedPostedDate}
              showType={true}
              showTopics={false}
              showKeywords={false}
              showDates={true}
              height={boxHeight}
              style={{
                height: '100%', // Matches container height
                boxSizing: 'border-box', // Ensures padding is accounted for
              }}
            />
            {/* Close Button (KTIcon) positioned inside the card */}
            <div
              className="btn btn-icon btn-sm btn-active-icon-primary"
              onClick={removeDocumentSelected}
              style={{
                position: 'absolute',
                top: '15px',
                right: '10px', // Positions the button at the top-right corner of the card
                cursor: 'pointer',
              }}
            >
              <KTIcon
                iconName="cross"
                className="fs-3"
                style={{ width: '30px', height: '30px' }}
              />
            </div>
          </div>
        )}
      </div>

      {!doc2Selected && (
        <div style={{ position: 'sticky', top: '80px', backgroundColor: 'white', zIndex: 1, padding: '5px' }}>
          <SearchBar searchInput={searchInput} setSearchInput={setSearchInput} placeholder={'Search Document to Compare With '} />
        </div>
      )}


      {doc2Selected ? (
        <div style={{ flex: 1, overflowY: 'auto', justifyContent: 'space-between', padding: '15px', paddingBottom: '70px', cursor: 'pointer' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h3 width='80%'>Lexim Response:</h3>
            {response !== 'Thinking...' && (
              <div style={{ display: 'flex', top: '10px', right: '10px' }}>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="button-tooltip">Copy message</Tooltip>}
                >
                  <div
                    onClick={() => copyToClipboard(response)}
                    className='me-5'
                    title="Copy to clipboard"
                  >
                    <KTIcon iconName="copy" iconType="duotone" className="fw-bold text-primary fs-1" />
                  </div>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip">Save to Cabinet</Tooltip>}>
                  <i
                    className="bi bi-floppy text-primary text-bold fs-1 me-5"
                    onClick={() => handleSaveFolder({
                      parent_id: 0,
                      name: `Lexim_Document_Comparison ${new Date().toLocaleDateString('en-US', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                      }).replace(/\//g, '-').replace(/:/g, '-')
                        }.pdf`,
                      QAanswer: response,
                      type: 'file'
                    }

                    )}
                  ></i>
                </OverlayTrigger>
                {
                  showSaveModal && (
                    <MoveFolderModal
                      showModal={showSaveModal}
                      setShowModal={setShowSaveModal}
                      moveFile={{
                        parent_id: 0,
                        name: `Lexim_Document_Comparison ${new Date().toLocaleDateString('en-US', {
                          month: '2-digit',
                          day: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                        }).replace(/\//g, '-').replace(/:/g, '-')
                          }.pdf`,
                        QAanswer: response,
                        type: 'file'
                      }}
                      handleMoveFolder={handleSaveFolder}
                      isLoading={isLoading}
                      fromWorkbench={true}

                    />
                  )
                }
                <FolderSuccessModal
                  showToast={showSuccessModal}
                  message={message}
                  onClose={() => setShowSuccessModal(false)}
                />

                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="button-tooltip">Download message</Tooltip>}
                >
                  <i
                    className="bi bi-download text-primary text-bold fs-1 me-1"
                    onClick={() => downloadAnswer(response)}
                  ></i>
                </OverlayTrigger>
              </div>
            )}
          </div>
          <div className='col-10'>
            <ReactMarkdown>{response}</ReactMarkdown>
          </div>
        </div>
      ) : (
        <div style={{ flex: 1, overflowY: 'auto', padding: '5px', paddingBottom: '70px' }}>
          {/* Show loading spinner if data is being fetched */}
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <HourGlass height={"calc(35vh)"} />
            </div>
          ) : error ? (
            <div className="alert alert-danger">{error}</div>
          ) : (
            <>
              {/* Render the documents with a scrollable list */}
              <div className="document-list">
                {allDocs.length > 0 ? (
                  allDocs
                    .filter((doc) => documentType.includes(doc.documentType))
                    .map((doc) => (
                      doc.id !== document.id && (
                        <div key={doc.id} style={{ overflow: 'hidden', marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
                          {/* Radio Button for selection */}
                          <input
                            type="radio"
                            id={`radio-${doc.id}`}
                            name="documentSelect"
                            value={doc.id}
                            checked={selectedDocId === doc.id}
                            onChange={() => handleDocumentSelection(doc.id, doc.title, doc.documentType, doc.postedDate)}
                            style={{ marginRight: '10px' }}
                            aria-label={`Select ${doc.title}`}
                          />
                          {/* DocumentCard */}
                          <div style={{ width: '100%' }}>
                            <DocumentCard
                              docTitle={doc.title}
                              docTopics={doc?.topics}
                              docPostedDate={doc?.postedDate}
                              doc_s3_link={doc?.doc_s3_link}
                              docType={doc.documentType}
                              showTopics={false}
                              showKeywords={false}
                              showDates={true} />
                          </div>
                        </div>
                      )
                    ))
                ) : (
                  <div>No documents available</div>
                )}
              </div>
            </>
          )}
        </div>
      )}

      {selectedDocId && !doc2Selected && (
        <div style={{ position: 'sticky', bottom: '0', backgroundColor: 'white', zIndex: 1, padding: '5px', height: '50px' }}>
          <div className="d-flex justify-content-center align-items-center">
            <Button onClick={onCompareClick} variant="primary" style={{ width: '100%' }}>
              Compare
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CompareDocuments;
