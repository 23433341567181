import React, { useState, useEffect, CSSProperties } from "react";
import axios from 'axios';
import MetricsToolbar from "./MetricsToolbar";
import PaginationWrapper from "../../../../components/PaginationWrapper";
import HourGlass from "../../../Loading/HourGlassSpinner/HourGlass";

interface History {
    id: number;
    first_name: string;
    last_name: string;
    name: string;
    question: string;
    documents: any;
    saved_to_cabinet: boolean;
    create_date: string;
}

const WorkbenchTable = () => {
    const [histories, setHistory] = useState<History[]>([]);
    const [currentHistory, setCurrentHistory] = useState<History[]>([]);
    const [filteredHistory, setFilteredHistory] = useState<History[]>([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [totalPages, setTotalPages] = useState(0);
    const [sortConfig, setSortConfig] = useState({ key: 'create_date', direction: 'desc' });
    const [searchInput, setSearchInput] = useState('');
    const date = new Date();
    date.setDate(date.getDate() - 14);
    const twoWeeksAgo = date.toISOString().split('T')[0];
    const [filters, setFilters] = useState({
        startDate: twoWeeksAgo,
        endDate: '',
        organization: '',
        numDoc: '',
        SavedCabinet: '',
    });

    const tableStyle: CSSProperties = {
        '--bs-table-hover-bg': 'rgba(204, 229, 255, 1)',
        '--bs-table-striped-bg': 'rgba(230, 242, 255, 1)',
        'tableLayout': 'fixed'
    } as CSSProperties;

    const parseDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', { timeZone: 'UTC' });
    };

    // useEffect(() => {
    //     setTimeout(() => {
    //         window.scrollTo(0, document.body.scrollHeight);
    //     }, 0);
    // }, [currentPage]);

    const handleFilterChange = (filter: string, value: string) => {
        setFilters({ ...filters, [filter]: value });
    };

    const handleApplyFilters = () => {
        setLoading(true);
        if (
            filters.startDate === twoWeeksAgo &&
            filters.endDate === '' &&
            filters.organization === '' &&
            filters.numDoc === '' &&
            filters.SavedCabinet === '' &&
            searchInput === ''
        ) {
            // If no filters are applied, reset to original data
            setFilteredHistory(histories);
        } else {
            let filteredData = histories;
            console.log('history:', histories);

            if (filters.startDate !== "") {
                filteredData = filteredData.filter(history =>
                    new Date(history.create_date) >= new Date(filters.startDate)
                );
            }

            if (filters.endDate !== "") {
                filteredData = filteredData.filter(history =>
                    new Date(history.create_date) <= new Date(filters.endDate)
                );
            }

            if (filters.organization !== "") {
                filteredData = filteredData.filter(history =>
                    history.name && history.name.toLowerCase().includes(filters.organization.toLowerCase())
                );
            }

            if (filters.numDoc !== "") {
                if (parseInt(filters.numDoc) === 0) {
                    filteredData = filteredData.filter(history =>
                        !history.documents || history.documents.length === 0
                    );
                }
                else {
                    filteredData = filteredData.filter(history =>
                        history.documents && history.documents.length === parseInt(filters.numDoc)
                    );
                }
            }

            if (filters.SavedCabinet !== "") {
                filteredData = filteredData.filter(history =>
                    (filters.SavedCabinet === history.saved_to_cabinet.toString())
                );
            }

            setFilteredHistory(filteredData);
            setCurrentPage(1);
        }
        setLoading(false);
    };

    const handleResetFilters = () => {
        setFilters({
            startDate: twoWeeksAgo,
            endDate: '',
            organization: '',
            numDoc: '',
            SavedCabinet: '',
        });
        setFilteredHistory(histories);
        setCurrentPage(1);
    };

    const fetchHistory = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`/reg/v1/superadmin/myresearch`);

            let sortedHistory = response.data.sort((a: History, b: History) => {
                const startDateComparison = new Date(b.create_date).getTime() - new Date(a.create_date).getTime();
                if (startDateComparison !== 0) {
                    return startDateComparison;
                } else {
                    return new Date(b.create_date).getTime() - new Date(a.create_date).getTime();
                }
            });
            response.data = response.data.map((history: History) => {
                history.documents = JSON.parse(history.documents);
                return history;
            });
            setHistory(sortedHistory);

            if (filters.startDate !== "") {
                sortedHistory = sortedHistory.filter(history =>
                    new Date(history.create_date) >= new Date(filters.startDate)
                );
            }
            if (filters.endDate !== "") {
                sortedHistory = sortedHistory.filter(history =>
                    new Date(history.create_date) <= new Date(filters.endDate)
                );
            }
            setFilteredHistory(sortedHistory);
        } catch (error) {
            console.error('Error fetching histories:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchHistory();
        // eslint-disable-next-line
    }, [filters.startDate, filters.endDate]);



    useEffect(() => {
        setTotalPages(Math.ceil(filteredHistory.length / itemsPerPage));
    }, [filteredHistory, itemsPerPage]);

    useEffect(() => {
        setCurrentHistory(filteredHistory.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage));
    }, [filteredHistory, currentPage, itemsPerPage]);

    const handleHeaderClick = (key: string) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    useEffect(() => {
        const sortedHistory = [...filteredHistory].sort((a, b) => {
            const getSortValue = (item, key) => {
                const value = item[key];
                if (value === null || value === undefined) return Infinity;  // Return empty string for null/undefined values
                return value;
            };

            if (sortConfig.key === 'first_name') {
                // Sorting by combination of first_name and last_name
                const nameA = `${getSortValue(a, 'first_name')} ${getSortValue(a, 'last_name')}`;
                const nameB = `${getSortValue(b, 'first_name')} ${getSortValue(b, 'last_name')}`;

                if (nameA < nameB) return sortConfig.direction === 'asc' ? -1 : 1;
                if (nameA > nameB) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            }

            if (sortConfig.key === 'documents') {
                // Sorting by documents[0] in the array
                const docA = a.documents && a.documents[0] ? a.documents[0] : null;  // Default to null if no documents
                const docB = b.documents && b.documents[0] ? b.documents[0] : null;  // Default to null if no documents

                // Treat null as the largest value
                if (docA === null && docB === null) return 0;
                if (docA === null) return 1; // If A is null, B comes first
                if (docB === null) return -1; // If B is null, A comes first

                if (docA < docB) return sortConfig.direction === 'asc' ? -1 : 1;
                if (docA > docB) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            }

            // Normal sorting for other keys
            const valueA = getSortValue(a, sortConfig.key);
            const valueB = getSortValue(b, sortConfig.key);

            if (valueA < valueB) return sortConfig.direction === 'asc' ? -1 : 1;
            if (valueA > valueB) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });

        setFilteredHistory(sortedHistory);
        setCurrentPage(1);
        // eslint-disable-next-line 
    }, [sortConfig]);




    useEffect(() => {
        if (
            filters.startDate === twoWeeksAgo &&
            filters.endDate === '' &&
            filters.organization === '' &&
            filters.numDoc === '' &&
            filters.SavedCabinet === '' &&
            searchInput === ''
        ) {
            // If no filters are applied, reset to original data
            setFilteredHistory(histories);
        } else {
            let filteredData = histories;
            console.log('history:', histories);
            if (searchInput !== "") {
                filteredData = filteredData.filter(history =>
                    (history.first_name && history.first_name.toLowerCase().includes(searchInput.toLowerCase())) ||
                    (history.last_name && history.last_name.toString().includes(searchInput)) ||
                    (history.name && history.name.toLowerCase().includes(searchInput.toLowerCase())) ||
                    (history.question && history.question.toLowerCase().includes(searchInput.toLowerCase())) ||
                    (history.documents && history.documents.some(doc => doc && doc.toLowerCase().includes(searchInput.toLowerCase()))) ||
                    (history.create_date && parseDate(history.create_date).toLowerCase().includes(searchInput.toLowerCase()))
                );
            }

            if (filters.organization !== "") {
                filteredData = filteredData.filter(history =>
                    history.name && history.name.toLowerCase().includes(filters.organization.toLowerCase())
                );
            }
            if (filters.numDoc !== "") {
                if (parseInt(filters.numDoc) === 0) {
                    filteredData = filteredData.filter(history => {
                        if (!history.documents || history.documents.length === 0) {
                            console.log('history.documents:', history.documents);
                            console.log('history.documents.length:', (history.documents && history.documents.length));
                            console.log(!history.documents || (history.documents && history.documents.length === 0));
                        }
                        return (!history.documents || history.documents.length === 0);
                    }
                    );
                }
                else {
                    filteredData = filteredData.filter(history =>
                        history.documents && history.documents.length === parseInt(filters.numDoc)
                    );
                }
            }

            if (filters.SavedCabinet !== "") {
                filteredData = filteredData.filter(history =>
                    (filters.SavedCabinet === history.saved_to_cabinet.toString())
                );
            }
            if (filters.startDate !== "") {
                filteredData = filteredData.filter(history =>
                    new Date(history.create_date) >= new Date(filters.startDate)
                );
            }
            if (filters.endDate !== "") {
                filteredData = filteredData.filter(history =>
                    new Date(history.create_date) <= new Date(filters.endDate)
                );
            }

            setFilteredHistory(filteredData);
            setCurrentPage(1);
        }
        // eslint-disable-next-line 
    }, [searchInput, filters]);

    const formatDate = (dateString: string) => {
        const [year, month, day] = dateString.split('-');
        const date = new Date(Date.UTC(Number(year), Number(month) - 1, Number(day))); // Use Date.UTC to create a date in UTC
        const formattedDay = String(date.getUTCDate()).padStart(2, '0');
        const formattedMonth = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
        const formattedYear = date.getUTCFullYear();
        return `${formattedMonth}/${formattedDay}/${formattedYear}`;
    };

    return (
        <div>
            <div className="d-flex flex-column px-10 align-items-start">
                {(Object.values(filters).some(value => value !== '')) && (
                    <div className="mt-n2 mb-1">
                        <h5>Active Filters:</h5>
                        <ul>
                            {filters.organization !== '' && (
                                <li><h6 className="d-md-block text-wrap">Organization: {filters.organization}</h6></li>
                            )}
                            {(filters.numDoc !== '') && (
                                <li><h6 className="d-md-block text-wrap">Number of Documents: {filters.numDoc}</h6></li>
                            )}
                            {(filters.SavedCabinet !== '') && (
                                <li><h6 className="d-md-block text-wrap">Saved to Cabinet: {filters.SavedCabinet === '1' ? 'True' : 'False'}</h6></li>
                            )}
                            {filters.startDate !== '' && filters.endDate !== '' && (
                                <li><h6 className="d-md-block text-wrap">Create Date Range: {formatDate(filters.startDate)} to {formatDate(filters.endDate)}</h6></li>
                            )}
                            {filters.startDate !== '' && filters.endDate === '' && (
                                <li><h6 className="d-md-block text-wrap">Start Create Date: {formatDate(filters.startDate)}</h6></li>
                            )}
                            {filters.startDate === '' && filters.endDate !== '' && (
                                <li><h6 className="d-md-block text-wrap">End Create Date: {formatDate(filters.endDate)}</h6></li>
                            )}
                        </ul>
                    </div>
                )}
            </div>
            <MetricsToolbar
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                filters={filters}
                activeTab="workbench"
                handleFilterChange={handleFilterChange}
                handleApplyFilters={handleApplyFilters}
                handleResetFilters={handleResetFilters}
                objType={0}
            />
            {loading ? (
                <HourGlass height="25vh" />
            ) : (
                <div>
                    <div className='table-responsive mb-5'>
                        <table className="table table-striped table-hover table-rounded border gy-5 gs-7 align-middle dataTable no-footer"
                            style={tableStyle}>
                            <thead className="text-start text-muted fw-bolder fs-7 gs-0">
                                <tr className='fw-bold fs-5 text-gray-800 border-bottom-3 border-gray-200'>
                                    <th className="ps-10" style={{ whiteSpace: 'nowrap', width: '15%', cursor: 'pointer' }} onClick={() => handleHeaderClick('first_name')}>
                                        User
                                        <span className={`sorting-icon ${sortConfig.key === 'first_name' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'first_name' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                    <th style={{ whiteSpace: 'nowrap', width: '12%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('name')}>
                                        Organization
                                        <span className={`sorting-icon ${sortConfig.key === 'name' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'name' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                    <th style={{ whiteSpace: 'nowrap', width: '30%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('question')}>
                                        Question
                                        <span className={`sorting-icon ${sortConfig.key === 'question' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'question' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                    <th style={{ whiteSpace: 'nowrap', width: '24%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('documents')}>
                                        Documents
                                        <span className={`sorting-icon ${sortConfig.key === 'documents' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'documents' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                    <th className="text-center" style={{ whiteSpace: 'nowrap', width: '15%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('saved_to_cabinet')}>
                                        Saved to Cabinet?
                                        <span className={`sorting-icon ${sortConfig.key === 'saved_to_cabinet' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'saved_to_cabinet' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                    <th className="text-end" style={{ whiteSpace: 'nowrap', width: '12%', paddingRight: '30px', cursor: 'pointer' }} onClick={() => handleHeaderClick('create_date')}>
                                        Create Date
                                        <span className={`sorting-icon ${sortConfig.key === 'create_date' ? sortConfig.direction : ''}`}>
                                            {' '}<i className={`bi ${sortConfig.key === 'create_date' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            {loading ? (
                                <tbody className='text-gray-600 fw-bold'>
                                    <tr>
                                        <td colSpan={6}>
                                            <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                Loading...
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody className='text-gray-600 fw-bold'>
                                    {currentHistory.length === 0 ? (
                                        <tr style={{ backgroundColor: 'rgba(230, 242, 255, 1)' }}>
                                            <td colSpan={6} className='text-dark fw-semibold fs-5' style={{ textAlign: 'center' }}>No records found with selected filters. Please try again with other filters.</td>
                                        </tr>
                                    ) : (
                                        currentHistory.map((history) => (
                                            <tr key={history.id} className='align-items-center bg-hover-light-primary'>
                                                <td className="py-5 ps-10" style={{ whiteSpace: 'normal', textAlign: 'left' }}>
                                                    {history.first_name} {history.last_name}
                                                </td>
                                                <td style={{ whiteSpace: 'nowrap', textAlign: 'left', paddingRight: '20px' }}>{history.name}</td>
                                                <td style={{ whiteSpace: 'normal', textAlign: 'left' }}>{history.question}</td>
                                                <td style={{ whiteSpace: 'normal', textAlign: 'left', paddingRight: '20px' }}>
                                                    {history.documents && history.documents.filter(doc => doc != null).join(', ').split(', ').map((doc, index) => (
                                                        <span key={index}>
                                                            {doc}
                                                            <br />
                                                        </span>
                                                    ))}
                                                </td>
                                                <td style={{ whiteSpace: 'normal', textAlign: 'center', paddingRight: '20px' }}>
                                                    <span
                                                        className={`badge ${history.saved_to_cabinet === true ? 'badge-light-success' : 'badge-light-info'}`}
                                                    >
                                                        {history.saved_to_cabinet === true ? 'True' : 'False'}
                                                    </span>

                                                </td>
                                                <td className="pe-10" style={{ whiteSpace: 'normal', textAlign: 'right' }}>{parseDate(history.create_date)}</td>
                                            </tr>
                                        )))}
                                </tbody>
                            )}
                        </table>
                    </div>
                    {totalPages > 1 &&
                        <div className='card-footer mb-n15'>
                            <div className='row mt-2 '>
                                <PaginationWrapper totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                            </div>
                        </div>
                    }
                </div>
            )}
        </div>
    );
};

export default WorkbenchTable;
