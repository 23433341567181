import React from 'react';
import { KTIcon } from '../../../_metronic/helpers';
import ReactMarkdown from 'react-markdown';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const QaAnswer = ({ qaPair, copyToClipboard, downloadAnswer }) => {
    const isThinking = qaPair.answer === 'Thinking ...';

    return (
        <div className="d-flex justify-content-start w-100 mt-2 mb-2">
            <div
                className={`p-4 fs-6 text-gray-900 fw-medium rounded ${qaPair.error ? 'bg-danger text-dark' : 'bg-light-info'}`}
                style={{ maxWidth: '80%', wordWrap: 'break-word', position: 'relative', paddingTop: '2.5rem', paddingRight: '2.5rem' }} // Adjusted padding for icon space
            >
                {isThinking ? (
                    <>
                        <div
                            className="spinner-grow spinner-grow-sm text-primary ms-2"
                            role="status"
                            aria-label="Loading answer"
                        >
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <div
                            className="spinner-grow spinner-grow-sm text-primary ms-2"
                            role="status"
                            aria-label="Loading answer"
                        >
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <div
                            className="spinner-grow spinner-grow-sm text-primary ms-2"
                            role="status"
                            aria-label="Loading answer"
                        >
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <div
                            className="spinner-grow spinner-grow-sm text-primary ms-2"
                            role="status"
                            aria-label="Loading answer"
                        >
                            <span className="visually-hidden">Loading...</span>
                        </div>

                    </>
                ) : (
                    <div>
                        <div className='col-10'>
                            {/* Render sanitized Markdown */}
                            <ReactMarkdown>{qaPair.answer}</ReactMarkdown>
                        </div>
                        <div style={{ position: 'absolute', top: '10px', right: '10px', display: 'flex', gap: '8px' }}>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="button-tooltip">Copy message</Tooltip>}
                            >
                                <div
                                    onClick={() => copyToClipboard(qaPair.answer)}
                                    style={{ cursor: 'pointer' }}
                                    title="Copy to clipboard"
                                >
                                    <KTIcon iconName="copy" iconType="duotone" className="fw-bold fs-1" />
                                </div>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="button-tooltip">Download message</Tooltip>}
                            >
                                <i
                                    className="bi bi-download text-primary text-bold fs-1 me-1"
                                    onClick={() => downloadAnswer(qaPair.answer)}
                                ></i>
                            </OverlayTrigger>
                        </div>

                    </div>
                )}
            </div>
        </div>
    );
}
export default QaAnswer;